import axios from "axios";
// import authHeader from './auth-header';
// import {saveState} from "../components/board-admin.component";
// import {saveState} from "../components/mypoi.component";
//import {saveStateHome} from "../components/home.component";
import * as Constants from '../services/constants';

import AuthService from "../services/auth.service";



export function datetimeToSQL(date){
  let jsdate = new Date(date);
  let dd = jsdate.getDate();
  let mm = jsdate.getMonth() + 1; // month start at 0, we have to add 1.
  let yyyy = jsdate.getUTCFullYear();
  let hh = jsdate.getHours();
  let mi = jsdate.getMinutes();

  let sqlString = `${yyyy}/${mm}/${dd} ${hh}:${mi}`;
  return sqlString;
}


// const API_URL = "https://hablarjuntos.com:5088/users/";
// const fetchOptions = { headers: authHeader() };


  function returnTableName(myArray,value) {
    let valDate;
    console.log(myArray);
    if (myArray) {

    myArray.forEach((item,index) => {
            if (item.TableName === value) {
            valDate= new Date(item.updatedAt);}
          
          }) 
    }
    return valDate;
  }

  export function getSnap() {
    let snapStored = localStorage.getItem('snapData') ? JSON.parse(localStorage.getItem('snapData')): true; //if not set return default true
   return snapStored; 
  }

  export function getNotif() {
    let notifStored = localStorage.getItem('notifData') ? JSON.parse(localStorage.getItem('notifData')): true; //if not set return default true
   return notifStored; 
  }

  export function getSynced() {
  let storedSynced = JSON.parse(localStorage.getItem('synced'));
  console.log(storedSynced);
  return storedSynced;
  }

  export function getStored() {
    let stored = localStorage.getItem('StoredMarkers');
    if (stored !== 'undefined') {
    try {
      return JSON.parse(stored);
        }catch (err) {
        if (err) {console.error(err);
          return []}
    }
    } else {return []} 
  }
// export function getSyncedDate() {
//     // return null;
//     try {
//       return Date.parse(localStorage.getItem('syncedDate'));
//         }catch (err) {
//         if (err) {console.error(err);
//           return null}
//     } 
//     // return JSON.parse(localStorage.getItem('syncedDate'));
//   }


export function getStaticData() {
    // console.log("getServerData");
    var loadStatic = localStorage.getItem('staticContent');
    var loadStaticDate= localStorage.getItem('staticContentDate');

    var lastUpdate= JSON.parse(localStorage.getItem("lastUpdate"));

    var symdis;
    if (!lastUpdate) {
      // console.log("tmp set lastupdate");console.log(lastUpdate);
      symdis =Date.now();
    // localStorage.setItem("lastUpdate", JSON.stringify(symdis))
  } else {
    symdis=returnTableName(lastUpdate, 'symdis');
    symdis=symdis.getTime();   
    }
  if (loadStaticDate) {loadStaticDate=new Date(loadStaticDate).getTime()}

  // console.info(symdis + " :server <-> local: "+loadStaticDate);


    if (loadStatic) {
    // console.log("update skipped!");
    loadStatic=JSON.parse(loadStatic);
    // console.log(loadStatic);
    // console.log(loadStatic.diseases);
    // console.log(loadStatic.symptoms);
  
    if (Object.keys(loadStatic).length < 2) {
        // console.log(loadStaticDate);
        return [{diseases:{},symptoms:{}},true]
      }

      if (loadStaticDate && symdis < loadStaticDate) {
      console.log("get from localstorage (no download)");
      console.log(loadStatic);
      return [loadStatic,false]
      } else {
        return [loadStatic,true]
      } //else
      
      } else {
        console.log("error no loadstatic force update");
        return [{diseases:{},symptoms:{}},true]
            } //else


    } 


export function setSubscription(subscription,isMobile, fn){

  const currentUser =  AuthService.getCurrentUser();
    //get person_id
  // alert("------------------setSubscription:");
  console.log("------------------setSubscription:");

     // console.log(currentUser);
     // console.log(Constants.fetchOptions);
    let personId=currentUser.id;
    let query = 'setSubscription';
    let data = {person_id:personId,subscription_id:subscription, mobile: isMobile};
    // let data = {person_id:personId,subscription_id:JSON.stringify(subscription)}
    // console.log(data);
    // console.log(Constants.API_URL+ query);

 // .post(Constants.API_URL + "getMyMarkers", {
 //        username,
 //        password
 //      },Constants.fetchOptions)



     // return 
       axios.post(Constants.API_URL + query, data, Constants.fetchOptions)
       // axios.post(Constants.API_URL + query, data, Constants.fetchOptions)
      .then(response => {
        // if (response) response=response.json();
        // console.log(response);
        if (response && response.status === 200) {
          // alert(JSON.parse(response));
          // console.log(response);
          // console.warn(response);
          return fn(null,response);
        } else {
          return fn( new Error('Something went wrong ...'));
          // alert(subscription);
          // console.log(response);
          // throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        // console.warn(data.data.device,data.data.subscription,data.data.person_has_subscription_id);
// .subscription, device: data.data.device, id: data.data.person_has_subscription_id}
        // console.log(data);
       // localStorage.setItem("subscription", JSON.stringify(data))
      }
        )
      .catch(error => {console.error(error)}
        );
     
  }





class DataService {


  getMyMarkers(username, password) {

      const currentUser =  AuthService.getCurrentUser();
      if (currentUser) {


        let datenow =   new Date( Date.now());
        let outdated = datenow - 1000 * (60 * 5)
        let timer = localStorage.getItem('TimerMyMarkers')||outdated;
        // console.log(timer);        console.log(outdated);
        if (timer && timer > outdated) {

        // console.log(test);
    return axios
      .post(Constants.API_URL + "getMyMarkers", {
        username,
        password
      },Constants.fetchOptions)
      .then(response => {
        // console.log(response);
          if (response.data) {
            // console.log("dataservice getMyMarkers");
            // console.log(response.data);
            localStorage.setItem('TimerMyMarkers',datenow);
            localStorage.setItem("MyMarkers", JSON.stringify(response.data));
             // localStorage.setItem("MyMarkers", JSON.stringify(response.data));
             return response.data;
          // localStorage.setItem("user", JSON.stringify(response.data));
        }

        // return response.data;
      });
         }   else {
          // console.log("previous retreived markers");
          // console.log("mymarkers lastupdate:"+timer);
          return JSON.parse(localStorage.getItem('MyMarkers'));
        }



    } else {alert("not logged in");return}


         




  }


    getRepeatMarkers(username, password) {
      const currentUser =  AuthService.getCurrentUser();
      if (currentUser) {
        // if (currentUser.token) {
        //   console.log("setting token:"+ currentUser.token);
        //   axios.defaults.headers.common['Authorization']=currentUser.token;
        // }

        // console.warn("getRepeatMarkers ");
        // return JSON.parse(localStorage.getItem('MyMarkers'));

        // let local = JSON.parse(localStorage.getItem('MyRepeatMarkers'));
        // console.log(local);

        let datenow =   new Date( Date.now());
        let outdated = datenow - 1000 * (60 * 5)
        let timer = localStorage.getItem('TimerRepeatMarkers')||outdated;
        // console.log(timer);        console.log(outdated);
        if (timer && timer > outdated) {
          // console.warn("-----update getMyMarkers")



    return axios
      .post(Constants.API_URL + "getRepeatMarkers", {
        username,
        password
      },Constants.fetchOptions)
      .then(response => {
        // console.log(response);
          if (response.data) {
            // console.log("dataservice getRepeatMarkers");
            // console.log(response.data);
            localStorage.setItem('TimerRepeatMarkers',datenow);
            localStorage.setItem("RepeatMarkers", JSON.stringify(response.data));

             return response.data;
          // localStorage.setItem("user", JSON.stringify(response.data));
        }

        // return response.data;
      });

               }   else {
          // console.log("previous retreived RepeatMarkers");
          // console.log("RepeatMarkers lastupdate:"+timer);
          return JSON.parse(localStorage.getItem('RepeatMarkers'));
        }
    } else {alert("not logged in");return}


    
  }


  remove() {
    localStorage.removeItem("user");
  }

  add(username, email, password) {
    console.log("------------------add signup");
    return axios.post(Constants.API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getUserData() {
    // console.log(JSON.parse(localStorage.getItem('user')));
    return JSON.parse(localStorage.getItem('userData'));
  }
  getTestData() {
    return "test";
  }
  
  getSnap() {
    let snapStored = localStorage.getItem('snapData') ? JSON.parse(localStorage.getItem('snapData')): true; //if not set return default true
   return snapStored; 
  }

  updateSnap(value) {
    let snapStored = localStorage.getItem('snapData') ? JSON.parse(localStorage.getItem('snapData')): true; //if not set return default true
    if (value !== snapStored) {localStorage.setItem("snapData", JSON.stringify(value));}
    // console.log(value);
    // let snapStored = localStorage.getItem('snapData') ? JSON.parse(localStorage.getItem('snapData')): true; //if not set return default true
    // localStorage.setItem("snapData", JSON.stringify(newvalue));
   // return value; 
  }

  getNotif() {
      let notifStored = localStorage.getItem('notifData') ? JSON.parse(localStorage.getItem('notifData')): true; //if not set return default true
   return notifStored; 
  }

  updateNotif(value) {
    let notifStored = localStorage.getItem('notifData') ? JSON.parse(localStorage.getItem('notifData')): true; //if not set return default true
    if (value !== notifStored) {localStorage.setItem("notifData", JSON.stringify(value));}
  }


  // getMarkers() {
  //   return JSON.parse(localStorage.getItem('markers'));
  // }

  // getSyncedDate = () => {
  //   // return null;
  //   try {
  //     return Date.parse(localStorage.getItem('syncedDate'));
  //       }catch (err) {
  //       if (err) {console.error(err);
  //         return null}
  //   } 

  //   // return JSON.parse(localStorage.getItem('syncedDate'));
  // }


  getNewData = () => {
    try {
      return JSON.parse(localStorage.getItem('newData'));
        }catch (err) {
        if (err) {console.error(err);
          return {
        point: [],
        symptoms: [],
        diseases:[]
      } //return
    } //error
    } //catch 

  } // new data

postUpdateTodate = (query, API_URL,id, toDate,fetchOptions,cb) => {
    axios.post(API_URL + query, {id: id, todate: datetimeToSQL(toDate)}, Constants.fetchOptions)
      .then(response => {
        if (response && response.status === 200) {
          let data = response.data;
          console.log(data);
          console.log("setstate: data- Update todate button");
    return cb(data)
        } else {console.log(response);
          throw new Error('Something went wrong ...');
        }
      })
      .catch(error => {console.warn("catch: "+ error)
      });
  }

  getMarkers = () => {
    // console.log("getMarkers 153");
    let storedMarkers = JSON.parse(localStorage.getItem('markers'))||{};
    // console.log(storedMarkers);
    return storedMarkers;
  }

  getStored = () => {
    let stored = localStorage.getItem('StoredMarkers');
    if (stored !== 'undefined') {

    try {
      return JSON.parse(stored);
        }catch (err) {
        if (err) {console.error(err);
          return []}
    }
    } else {return []} 
  }

  getSynced = () => {
  // let storedSynced = 
  return JSON.parse(localStorage.getItem('synced'));
  // console.log(storedSynced);
  // return storedSynced;
  }

  getTest = () => {
    return true
  }

  syncSynced = () => {
    console.log("getsynced");
    // let localSynced;

    //1 check local syncdate
    let localDate = this.getSyncedDate();
    console.log(localDate);
    return this.getSyncedFromServer(localDate);

  }


getSyncedFromServer = (date) => {
 const currentUser =  AuthService.getCurrentUser();
  if (currentUser) {
  console.warn("getSyncedFromServer");
  // alert("getSyncedFromServer");
     let query = 'getSyncedUpdate';
     let data = {
      date: date
     };
     let storedSynced = localStorage.getItem('synced');
      if (storedSynced) {storedSynced = JSON.parse(storedSynced)}

       axios.post(Constants.API_URL + query, data, Constants.fetchOptions)     
      .then(response => {
        // if (response) response=response.json();
        // console.log(response.status);
        console.log(response);
        if (response && response.status === 200) {
          // let dateChecked=new Date();
          console.log("synced");
          console.log(response.data.data);
          if (Object.keys(response.data.data).length < 1)
          {
            console.warn("sync length to short..:");
            console.warn(Object.keys(response.data.data).length);
            // return localStorage.getItem('synced');
            return localStorage.setItem('synced', JSON.stringify(storedSynced))
            // return saveState({synced: storedSynced})

          } else {

            if (response.data.data.synced) {
              console.log("already synced returning:");
              // console.log(storedSynced);
              // return storedSynced 
              return localStorage.setItem('synced', JSON.stringify(storedSynced));
              // return saveState({synced: storedSynced});

              } else {
                console.log("updating new sync");
                        // localStorage.setItem('staticContentDate', new Date());
          // console.log(response.data);
          // console.error(JSON.stringify(response.data));-

        //   synced: {                   // object that we want to update
        // ...prevState.synced,    // keep all other key-value pairs
        // symptoms: {
        //   ...prevState.synced.symptoms,
        //     [symId] : {
        //       ...prevState.synced.symptoms[symId],
        //       todate: symptomDate}
        //     }
        //   }
         var serverSyncedsymptoms= {};
         var serverSynceddiseases= {};

              //add each object to synced
         Object.keys(response.data.data.symptoms).each((key, index)  => {           
                    var item = response.data.data.symptoms[key];
                    if (item && item !== undefined) {
                        serverSyncedsymptoms = {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.id},...serverSyncedsymptoms};
                     } //else {return ""}
                  });
         Object.keys(response.data.data.diseases).each((key, index)  => {           
                    var item = response.data.data.diseases[key];
                    if (item && item !== undefined) {
                        serverSynceddiseases = {[item.disease_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.id},...serverSynceddiseases};
                     } //else {return ""}
                  });

    // 
         // this.setState({synced: {}});
          let updatedSync = storedSynced;
          updatedSync = {
            symptoms: {...serverSyncedsymptoms},
            diseases: {...serverSynceddiseases},
          }


          //   this.setState(prevState => ({
          // synced: {                   // object that we want to update
          //     ...prevState.synced,    // keep all other key-value pairs
          //     symptoms: {...serverSyncedsymptoms,...prevState.synced.symptoms},
          //     diseases: {...serverSynceddiseases,...prevState.synced.diseases},      // update the value of specific key
          //       }
          //   }))

          // let updatedSync = storedSynced;
          // updatedSync = {   diseases: {...response.data.data.diseases},
          //                    symptoms: {...response.data.data.symptoms},
          //                   };



          console.warn("updatedSync:");
          console.log(updatedSync);


          // localStorage.setItem('synced', JSON.stringify(response.data.sync));
          console.log(response.data.data.sync);
          localStorage.setItem('syncedDate', new Date(response.data.data.sync));
          localStorage.setItem('synced', JSON.stringify(updatedSync));
  

          //update setstate on mypoi
          // saveState({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});  
            // localStorage.setItem('synced', JSON.stringify(updatedSync));
//          return saveState({synced: updatedSync});


          // localStorage.setItem('syncedDate', JSON.stringify(response.data.syncDate));
          // console.log("returning for synced:");
          // console.log(response.data.data);

          // return response.data.data;

          // console.log(response.data.diseases);
          // console.log(response.data.symptoms);
          // this.setState({ diseases: response.data[0],symptoms: response.data[1],isLoading: false});
          // this.setState({ markers: {point: point, name: "current position", type: "gps_fixed", start: "",end: ""}: {home: response.data.home}});
          // // return response;
          // if (type === "diseases") {
          //   return response.data.diseases} else {return response.data.symptoms
          //   // return response.data[0]} else {return response.data[1]
          //   }              
            }
          }
        } else {console.error("error"); //200
          throw new Error('Something went wrong ...');
        }
      })

  } //logged in
       // .catch(error => this.setState({ isLoading: false, 
                                      // fetchUrl: {error, isLoading: false }}));
}

  getStaticData = () => {
    console.log("getServerData");
// localStorage.setItem("user", JSON.stringify(response.data));
 // JSON.parse(localStorage.getItem('userData'));
    var loadStatic = localStorage.getItem('staticContent');
    var loadStaticDate= localStorage.getItem('staticContentDate');
    // console.log(localStorage.getItem('staticContent'))
    // console.log(localStorage.getItem("updateChecked"));
    var lastUpdate= JSON.parse(localStorage.getItem("lastUpdate"));
    
    // console.log(lastUpdate);
    // console.log(loadStaticDate);
    // console.log(this.returnTableName(lastUpdate, 'symdis'));

    // if (lastUpdate[0].TableName === 'symdis'){
    //   console.log(lastUpdate[0].updatedAt);
    //       console.log(this.sqltodate(lastUpdate[0].updatedAt))
    // };
    // console.log(lastUpdate);
    var symdis;
    if (!lastUpdate) {console.log("tmp set lastupdate");console.log(lastUpdate);symdis =Date.now();
    // localStorage.setItem("lastUpdate", JSON.stringify(symdis))
  } else {
    symdis=this.returnTableName(lastUpdate, 'symdis');   
    symdis=symdis.getTime();   
    }

    // console.log(loadStatic);
    // console.log(symdis < new Date(loadStaticDate));
  if (loadStaticDate) {loadStaticDate=new Date(loadStaticDate).getTime()}    
    console.info(symdis + " :server <-> local: "+loadStaticDate);


    if (loadStatic) {
    // console.log("update skipped!");
    loadStatic=JSON.parse(loadStatic);
    console.log(loadStatic);
    console.log(loadStatic.diseases);
    console.log(loadStatic.symptoms);
  
    if (Object.keys(loadStatic).length < 2) {
        //request new download
        console.log(loadStaticDate);
        // app.fetchUpdate(true,true);
        
                // saveStateHome({outdated:true});
        return [{diseases:{},symptoms:{}},true]
    }
    //if (type === "diseases") {

      if (loadStaticDate && symdis < loadStaticDate) {
      console.log("get from localstorage (no download)");
      console.log(loadStatic);
      return [loadStatic,false]
      } else {
        //request new download
        // saveStateHome({outdated:true});
        return [loadStatic,true]
      }
      
      // return loadStatic[0]} else {return loadStatic[1]}
      // console.log("Update:"+ loadStatic);
      // return loadStatic.diseases} else {return loadStatic.symptoms}
    //}


  // if (loadStaticDate && symdis < loadStaticDate) {
      // console.log("symdis < lastdate");
      // console.log(loadStaticDate);


      } else {console.log("error no loadstatic force update");
              return [{diseases:{},symptoms:{}},true]
            }


    } 

 returnTableName = (myArray,value) => {
    let valDate;
    console.log(myArray);
    if (myArray) {

    myArray.forEach((item,index) => {
            if (item.TableName === value) {
            valDate= new Date(item.updatedAt);}
          
          }) 
    }
    return valDate;
  }


// getTest() {
//   return true
// }


    updateServerData = () => {
      console.warn("get from server (download)");
       let query = 'getClientUpdate';
       // console.log(API_URL);       console.log(query);       console.log(Constants.fetchOptions);
       axios(Constants.API_URL + query, Constants.fetchOptions)
      .then(response => {
        // if (response) response=response.json();
        // console.log(response.status);
        if (response && response.status === 200) {
          // let dateChecked=new Date();
          localStorage.setItem('staticContentDate', new Date());
          // console.error(JSON.stringify(response.data));
          localStorage.setItem('staticContent', JSON.stringify(response.data));
          console.log(response.data);
          return response.data
          // console.log(response.data.diseases);
          // console.log(response.data.symptoms);
          // this.setState({ diseases: response.data[0],symptoms: response.data[1],isLoading: false});
          // this.setState({ markers: {point: point, name: "current position", type: "gps_fixed", start: "",end: ""}: {home: response.data.home}});
          // // return response;
          // if (type === "diseases") {
          //   return response.data.diseases} else {return response.data.symptoms
          //   // return response.data[0]} else {return response.data[1]
          //   }
        } else {console.error("error");
          throw new Error('Something went wrong ...');
        }
      })
       // .catch(error => this.setState({ isLoading: false, 
                                      // fetchUrl: {error, isLoading: false }}));
      }

  renameSymDis(type,id,name,description) {
    let query = 'mod'+type;
    let data = {
        id,
        name,
        description
      }
 return axios
      .post(Constants.API_URL + query,data,Constants.fetchOptions)
      .then(response => {
        // console.log(response);
        //store new data to localstorage & state

          // if (response.data.token) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        //}
        if (response && response.status === 200) {
          // console.log(response);
          // localStorage.setItem('staticContent', JSON.stringify(response.data));
          return response.data
          // this.setState({ diseases: response.data[0],symptoms: response.data[1],isLoading: false});
          // this.setState({ markers: {point: point, name: "current position", type: "gps_fixed", start: "",end: ""}: {home: response.data.home}});
          // return response;
        } else {console.log("error");
          throw new Error('Something went wrong ...');
        }
      });


    // console.log(JSON.parse(localStorage.getItem('user')));
    // return JSON.parse(localStorage.getItem('userData'));
  }

 sqltodate(sqlDate) {
  try {
        sqlDate=JSON.parse(sqlDate);
    } catch (e) {
        console.log(e);
        return false;
    }
        var formatedDate = new Date(sqlDate);
    return formatedDate;
  }

}

export default new DataService();
