import React, { Component } from "react";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
// import { Table, FormGroup, Label, Input, Button, Form, UncontrolledCollapse, CardBody, Card, CardHeader} from 'reactstrap';
import { Table,  Input, Button, Form, UncontrolledCollapse, CardBody, Card, CardHeader} from 'reactstrap';
import Joi from '@hapi/joi';
//subscripe to notification after signed on
// import NotificationService from "../services/notification.service";

// const currentUser =  AuthService.getCurrentUser();

const schema = Joi.object({
  // firstname: Joi.string().min(1).max(500).required(),
  firstname: Joi.string().alphanum().min(2).max(30).required(),
  middlename: Joi.string().allow('').min(0).max(500),
  lastname: Joi.string().allow('').min(0).max(500)
});


  const Switch = ({ isOn, hasId, handleToggle, onColor }) => {
    // console.log(hasId);
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={hasId}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        htmlFor={hasId}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      profileChange: false,
      // cardToggle: false,
      // newToggle: false,
      snap: DataService.getSnap(),
      notif: DataService.getNotif(),
      cardToggle: {
        new:false,
        // user:false
      },
    };    // preserve the initial state in a new object
    this.baseState = this.state 
  }

 componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
 

}

  componentDidMount() {
    // console.log(this.state);
   // if (this.state.currentUser !== prevState.currentUser) {
        // let currentUser = this.state.currentUser;
    // if (currentUser) {console.log("set subscription:");console.log(currentUser);NotificationService.subscribe()}
 
    // }      
// 
  } //did mount

  handleChange = v => {
    this.setState(prevState => ({
            profileChange: true,
            currentUser: {
            ...prevState.currentUser,           // copy all other key-value pairs of currentUser object
            person: {                     // specific object of currentUser object
              ...prevState.currentUser.person,   // copy all person key-value pairs
              [v.target.name]: v.target.value          // update value of specific key
              }
            }
          }))
  };

  formIsValid = () => {
    const userProfile = {
      firstname: this.state.currentUser.person.firstname,
    };

      // middlename: this.state.currentUser.person.middlename,
      // lastname: this.state.currentUser.person.lastname,

    // schema check
    const result = schema.validate(userProfile);
    return !result.error ? true : false;
  }

  updateLocalStorage = () => {
    console.log(this.state.currentUser);
       localStorage.setItem("user", JSON.stringify(this.state.currentUser));
       // update server
  }


  formCancel = (e) => {
    // console.log('cancel');     
 this.setState(this.baseState)
   // this.setState((prevState) => ({
   //  profileChange: false,
   //  currentUser: {
   //          ...prevState.currentUser
   //    }
   //  }));
  }

  formSubmit = (e) => {
    e.preventDefault();
        if (this.formIsValid()) {
          DataService.updateSnap(this.state.snap);
          // check if upload enabled
          // if (this.state.currentUser.uploadEnabled) {}
          console.log(this.state.currentUser);
          console.log(e);
            //update localStorage
          this.setState(prevState => ({
            profileChange: false,
            currentUser: {
            ...prevState.currentUser,           // copy all other key-value pairs of currentUser object
            person: {                     // specific object of currentUser object
              ...prevState.currentUser.person,   // copy all person key-value pairs
              //home: this.state.searchResult          // update value of specific key
              
              }
            }
          }), this.updateLocalStorage)
    } else {console.log("invalid input!")}
  }
  // toggle = (e) => {
  //   // {this.togglenew
  //     console.log(this.state.cardToggle[e]);
  //     console.log(e);
  //   // this.setState(prevState => ({cardToggle: prevState.cardToggle === true ? false : true}));

  //         this.setState(prevState => ({
  //           cardToggle: {
  //           ...prevState.cardToggle,           // copy all other key-value pairs of currentUser object
  //           [e]: !prevState[e]
  //           }
  //         }),console.log(this.state.cardToggle[e]))

  //               // console.log(this.state.cardToggle);

  //     // const { target: { name, value } } = event
  //   // this.setState({ [name]: value })


  //   // console.log(e);
  // }

  
  // togglenew = () => {
  //   this.setState(prevState => ({newToggle: prevState.newToggle === true ? false : true}));
  //   // console.log(e);
  // }

        // c.snap
        // () => c.setSnap(!c.snap)

handleSnapChange = e => {
    this.setState(prevState => ({
            snap: !e,
          }))
}
//     (e) {
//    this.setState({ [e.target.name] : e.target.value });
// }


  render() {
    const { currentUser } = this.state;
    // console.log(AuthService.getCurrentUser());
    // console.log("ok");
    // console.log(currentUser.person.sync);
    // console.log(this.state.snap);
    // console.log(currentUser.roles);
    // console.log(currentUser.person.firstname);
    let displayname=currentUser.person.firstname ? currentUser.person.firstname : currentUser.username;
    // const storedSnap =  ;
    // let snap;
    let subscription=JSON.parse(localStorage.getItem('subscription'));
    //|| {};;
    // subscription: data.data.subscription, device: data.data.device, id: data.data.person_has_subscription_id}
    // console.log(subscription.data.subscription);
    let subscriptions ="";
    if (subscription && subscription.data) {

    subscriptions = Object.keys(subscription.data).map((key, index)  => {
    // Object.keys(subscription.data).forEach((key, index)  => {
                // console.log(key, list[key]);  
                var item = subscription.data[key];
                //single
                if (item.subscription) {
                return  ('<a  href="unsubscribe">item.device - item.createdAt</a>  ')                 
                } else { console.log(item);return ("")}

      });
}
    // console.warn(subscription);
// this.setState({ [e.target.name] : e.target.value });
    // if (!snap) {snap =storedSnap }
    // const setSnap = (snapvalue) => {snap=DataService.toggleSnap(snapvalue)};


    //                <FormGroup check>
    //         <Label check>
    //           <Input type="checkbox" id="sync" name="sync" defaultChecked={currentUser.person.sync} onChange={(e) => {
    // this.handleChange({
    //   target: {
    //     name: e.target.name,
    //     value: e.target.checked,
    //   },
    // });}}/>{' '}
              
    //         </Label>
              // </FormGroup>
              // if (!currentUser) {
              //   console.log("not logged in");
              // }
              // if (currentUser && !currentUser.person) {
              //   console.log("no person settings; set default");
              //         currentUser.person = {};
              //         currentUser.person.firstname = "";
              //         currentUser.person.middlename ="";
              //         currentUser.person.lastname ="";
              //         currentUser.person.home ="";
              //         currentUser.person.sync ="";
              //       }
              //       console.log(currentUser.person.firstname);


    return (

      <div className="container">
        <header className="jumbotron"><h3> {currentUser.person.firstname} {currentUser.person.middlename} {currentUser.person.lastname}</ h3>
                <Table hover>

          <tbody>
            <tr>
              <th scope="row">Profile</th>
              <td>{currentUser.username}</td>
            </tr>
            <tr>
              <th scope="row">Token</th>
              <td>{currentUser.token.substring(0, 20)} ...{" "}
                {currentUser.token.substr(currentUser.token.length - 20)}
            </td>
            </tr>
            <tr>
              <th scope="row">Id</th>
              <td>{currentUser.id}</td>
            </tr>
            <tr>
              <th scope="row">Authorities</th>
              <td> {currentUser.roles && currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
              </td>
            </tr>
             <tr>
              <th scope="row">Offline</th>
              <td> <a href="/offline">add offline map</a>
              </td>
            </tr>
          </tbody>
        </Table>
        <div> Select a person name to change it's details. Or add another person and add points on the map to show apporpiate warnings on the overview</div><br/>
          <Button color="info" id={displayname} style={{ marginBottom: '1rem' }}>{displayname}</Button>
      <UncontrolledCollapse toggler={displayname}>
        <Card className="smallCard">
        <CardHeader>{currentUser.person.firstname} {currentUser.person.middlename} {currentUser.person.lastname}</CardHeader>
          <CardBody>

<Form onSubmit={this.formSubmit}>
        <Table hover>

          <tbody>
             <tr>
              <th scope="row">sync server <br/><small><i>enable to use on multiple devices</i></small></th>
              <td>
   

                          <Switch
                  isOn={currentUser.person.sync}
                  hasId="sync"
                  onColor="#17a2b8"
                  handleToggle={
                    (e) => {this.handleChange({
                    target: {
                      name: "sync",
                      value: !currentUser.person.sync,
                    }
                  })}
                }
                />

              </td>
            </tr>
                      

             <tr>
              <th scope="row">Snap<br/><small><i>enable snap to find closest point on click</i></small></th>
              <td>
              <Switch
                  isOn={this.state.snap}
                  hasId="snap"
                  onColor="#EF476F"
                  handleToggle={(e) => {this.handleSnapChange(this.state.snap)}}
                />
              </td>
            </tr>
          
                       <tr>
              <th scope="row">Notification<br/><small><i>enable to allow push messages to your devices</i></small></th>
              <td>
              <Switch
                  isOn={this.state.notif}
                  hasId="snap"
                  onColor="#EF476F"
                  handleToggle={(e) => {this.handleSnapChange(this.state.notif)}}
                />
              </td>
            </tr>

            <tr>
            <th scope="row">Notification Service send to</th>
              <td>
              {subscriptions}

              </td>
              </tr>

             <tr>
              <th scope="row">Firstname <small><i>Optional</i></small></th>
              <td><Input type="firstname" name="firstname" id="firstname" value={currentUser.person.firstname || ''} placeholder="" onChange={(e) => {
    this.handleChange({
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    });}}/>{' '}</td>
            </tr>
              <tr>
              <th scope="row">Middlename <small><i>Optional</i></small></th>
              <td><Input type="middlename" name="middlename" id="middlename" value={currentUser.person.middlename || ''} placeholder="" onChange={(e) => {
    this.handleChange({
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    });}}/></td>
            </tr>
            <tr>
              <th scope="row">Lastname <small><i>Optional</i></small></th>
              <td><Input type="lastname" name="lastname" id="lastname" value={currentUser.person.lastname || ''} placeholder=""  onChange={(e) => {
                this.handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                });
            }}
    /></td>
            </tr>
          </tbody>
        </Table>
        <Button color="primary" active type="submit" disabled={!this.formIsValid()}>Save</Button>{' '}
        <Button color="secondary" active type="button" onClick={this.formCancel} disabled={!this.state.profileChange}>Cancel</Button>
      </Form>
          </CardBody>
        </Card>
      </UncontrolledCollapse>
      <br />

       <Button color="primary" name="new" id="new" style={{ marginBottom: '1rem' }}>Add Person</Button>
       <UncontrolledCollapse toggler="#new">
        <Card className="smallCard">
        <CardHeader>Enter the name <small><i>(for identification of the markers)</i></small></CardHeader>
          <CardBody>

<Form onSubmit={this.newPersonSubmit}>
        <Table hover>

          <tbody>
             <tr>
              <th scope="row">Firstname</th>
              <td><Input type="firstname" name="firstname" id="firstname" placeholder="" />
          </td>
            </tr>
                          <tr>
              <th scope="row">Middlename <small><i>Optional</i></small></th>
              <td><Input type="middlename" name="middlename" id="middlename" placeholder=""/></td>
            </tr>
            <tr>
              <th scope="row">Lastname <small><i>Optional</i></small></th>
              <td><Input type="lastname" name="lastname" id="lastname" placeholder="" /></td>
            </tr>
              <tr>
              <th></th>
              <td>
                      <Button color="primary" active type="submit" disabled={!this.formIsValid()}>Save</Button>{' '}
              
              </td>
              </tr>
              </tbody>
        </Table>
      </Form>
          </CardBody>
        </Card>
      </UncontrolledCollapse>

        </header>
        
      </div>
    );
  }
}