// Add images to disease medicine.
// get medicine list with dosage

import React, { Component } from "react";
//BrowserRouter as Router,
import {Routes, Route, Link, Navigate, Outlet} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
// import DataServices from "./services/data.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home , {saveStateHome} from "./components/home.component";
import Mypoi from "./components/mypoi.component";
import Offline from "./components/offline.component";
// import Overview from "./components/overview.component";

import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin, {saveState} from "./components/board-admin.component";
import NotFound from './components/notfound.component';

// import authHeader from './services/auth-header';
// import DataService from "./services/data.service";
import NotificationService from "./services/notification.service";
import DataService from "./services/data.service";
import * as Constants from './services/constants'

import axios from "axios";

// const fetchOptions = { headers: authHeader() };
// const updateFrequency = (1*60*60*1000); //1 hour

// const updateFrequency = (0.5*60*60*1000); // 1/2 hour
const updateFrequency = (0.05*60*60*1000); // 10 min
const API_URL='https://hablarjuntos.com:5088/users/';

 export function saveStateApp(input){
// console.log(input);
this.setState(input);
// return true
  }

//savestate 

//TODO
//const notifier = require('node-notifier');

//notifications
// const notifier = require('node-notifier');
// const path = require('path');


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    // saveStateApp = saveStateApp.bind(this);
    this.state = {
    	refreshUser: false,
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      updateChecked: undefined,
      lastUpdate: {},
      allMarkers: {},
    };
  
  const user = AuthService.getCurrentUser();
  // alert(user.toJSON());
	// const location = useLocation();


  // alert(location);
    let currentDateTime = new Date();
    // console.log(this.state.lastUpdate[0].updatedAt);


    //date last check for updates
    // this.sqltodate
	// let updateChecked=this.state.updateChecked ? this.sqltodate(this.state.updateChecked) : this.sqltodate(localStorage.getItem("updateChecked"));
	let updateChecked=this.state.updateChecked ? this.sqltodate(this.state.updateChecked) : this.sqltodate(localStorage.getItem("updateChecked"));
	
	// console.log(this.state.updateChecked);
	// console.log(this.sqltodate(localStorage.getItem("updateChecked")));
	console.log(updateChecked);
	// console.log(new Date());

	//the last updated
    let locallastUpdate=this.state.lastUpdate.length >0 ? this.state.lastUpdate : this.storageTostate("lastUpdate");

    // console.log(localStorage.getItem("lastUpdate"));
    // console.log(locallastUpdate);
    // let locallastUpdate=localStorage.getItem("lastUpdate");
    // let res = this.fetchSynced(updateChecked);
    // let res = DataService.getSyncedFromServer(updateChecked);
     DataService.getSyncedFromServer(updateChecked);
    // console.log(res);
	 // if (this.state.lastUpdate)
	 //force update on incorrect data
	 let localStatic = localStorage.getItem('staticContent');
	 if (localStatic ) {
	 	localStatic = JSON.parse(localStatic);
	 	// console.warn(localStatic);
	 	if (Object.keys(localStatic).length < 2 || (localStatic.diseases && localStatic.diseases.length <= 1)) {
	 		// console.warn("fix local data");
	 	this.fetchUpdate(user,true);
	 	}
	 } else {
	 	//no stored data
	 	console.log("no static content detected: force download");
	 	if (user) {
			 	this.fetchUpdate(user,true);	 		
	 	} else {	 	this.fetchUpdate("guest",true);}

	 }
	 //already stored

    if (locallastUpdate) {
    	console.warn(updateChecked.getTime() + updateFrequency);

    	if (currentDateTime.getTime() > updateChecked.getTime() + updateFrequency) {
    	console.log("update based on updateChecked");	
    	console.log(updateFrequency);
    	console.log(currentDateTime.getTime(), ">" ,updateChecked.getTime() + updateFrequency);
    	// console.log("totaldays :");
    	// let days= (currentDateTime.getTime() - (updateChecked.getTime() + updateFrequency) / ( 24 * 3600 * 1000));
    	// console.log(Math.floor(days));
    	this.fetchUpdate(user,false);
    	}
    	else {
    		console.log("next update:");
    		console.warn(new Date(updateChecked.getTime() + updateFrequency))}
	} //if updateChecked
	 else { //!updateChecked

	 	console.log("new update storage");
		this.fetchUpdate(user,false);	
	}



  }

  componentDidUpdate(prevProps, prevState) {
	// Typical usage (don't forget to compare props):
	// if (this.state.markers !== prevState.markers) {
	//   this.markerUpdate(this.state.markers);
	// }

	if (prevState.updateChecked !== this.state.updateChecked) {
		// console.log(this.state.allMarkers);
	  const updateChecked = JSON.stringify(this.state.updateChecked);
	  localStorage.setItem("updateChecked", updateChecked);
	}

// prevState.refreshUser =
	if (this.state.refreshUser === true) {
		console.warn("updating currentUser");
		// let up=Constants.fetchOptions;
		//update subscription
		NotificationService.subscribe();


		// alert(up);
		let updatedUser=AuthService.getCurrentUser();
    this.setState({
    		refreshUser: false,
        currentUser: updatedUser,
        showModeratorBoard: updatedUser.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: updatedUser.roles.includes("ROLE_ADMIN")
      });
	}

//unused
// function itemEquals(a, b) {
//   return a.location_id === b.location_id &&
//     a.markers === b.markers
//     //a.every((val, index) => val === b[index]);
// }


// if (prevState.allMarkers) {
// 	console.log("allMarkers process");
// 		  let mymarkers = JSON.parse(localStorage.getItem('markers'));

// 	  mymarkers.forEach(function(i) {
// 	  	console.log(i)
// 	  })
// }
if (prevState.myMarkers !== this.state.myMarkers) {
	  // new markers check for my markers overlap
	  const myMarkers = this.state.myMarkers;
	  const JSONmyMarkers = JSON.stringify(myMarkers);
	  localStorage.setItem("myMarkers", JSONmyMarkers);

	  //TODO 2021


	  // let mymarkers = JSON.parse(localStorage.getItem('mymarkers'));
	  console.log(myMarkers);
	  myMarkers.forEach(function(i) {
	  	console.log(i)
	  	console.log(i.fromdate);
	  	//TODO
	  //	notifier.notify(i);
		
		 //  notifier.notify({
			//   title: 'new issue ',
			//   message: 'test',
			// });

	  })
	  // DataService.getMyMarkers()

}

	if (prevState.allMarkers !== this.state.allMarkers) {
		// console.log(this.state.allMarkers);
		// console.log("markers Updated");
		let l = window.location.pathname; 
		// let newmarkers=this.state.allMarkers;
		// console.log(l);
		// if (l === "/home" || l === "/" || l === "/mypoi") {
		if (l === ("/home" || "/" || "/mypoi")) {
			//TODO
			  // saveStateHome({ServerMarkers:newmarkers});	
		

		}
		//get new markers from server
	  const allMarkers = JSON.stringify(this.state.allMarkers);
	  	console.log("allMarkers process");

	  localStorage.setItem("markers", allMarkers);


	}

	if (prevState.lastUpdate.length !== this.state.lastUpdate.length) {
	  console.log("lastUpdate set in localStorage");
	  const lastUpdate = JSON.stringify(this.state.lastUpdate);
	  localStorage.setItem("lastUpdate", lastUpdate);
	}
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    // if (!user) {alert("no user")}
    // const notificationId=	NotificationService.getNotificationID();
    if (user) {
    	// if (notificationId) {
    	// 	console.warn("got notificationID:"+notificationId);
    	// 			user = {...user, person: {...user.person, notification_id: notificationId}}
    	// }
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN")
      });
    }
  }

 storageTostate(value) {
 	let parsed;
 	try {
		parsed=localStorage.getItem(value);
	    parsed=JSON.parse(parsed);
    } catch (e) {
        console.log(e);
        return false;
    }
    return parsed
 }

 sqltodate(sqlDate) {
 	try {
        sqlDate=JSON.parse(sqlDate);
    } catch (e) {
        console.log(e);
        return false;
    }
  	    var formatedDate = new Date(sqlDate);
		return formatedDate;
  }



	// download markers and update details
	// fetchSynced = async (date,force) => {

  // console.warn("getSyncedFromServer");
  //    let query = 'getSyncedUpdate';
  //    let data = {
  //     date: date
  //    };
  //    let storedSynced = localStorage.getItem('synced');
  //     if (storedSynced) {storedSynced = JSON.parse(storedSynced)}

  //      axios.post(API_URL + query, data, fetchOptions)     
  //     .then(response => {
  //       // if (response) response=response.json();
  //       // console.log(response.status);
  //       // console.log(response);
  //       if (response && response.status === 200) {
  //         // let dateChecked=new Date();
  //         console.log("synced");
  //         console.log(response.data.data);
  //         if (Object.keys(response.data.data).length < 1)
  //         {
  //           console.warn("sync length to short..:");
  //           console.warn(Object.keys(response.data.data).length);
  //           return localStorage.getItem('synced');
            
  //           //return saveState({synced: storedSynced})

  //         } else {

  //           if (response.data.data.synced) {
  //             console.log("already synced returning:");
  //             console.log(storedSynced);
  //             return storedSynced 
  //             // return true
  //             //return saveState({synced: storedSynced});


  //             } else {
  //               console.log("updating new sync");
  //        var serverSyncedsymptoms= {};
  //        var serverSynceddiseases= {};

  //             //add each object to synced
  //        Object.keys(response.data.data.symptoms).each((key, index)  => {           
  //                   var item = response.data.data.symptoms[key];
  //                   if (item && item !== undefined) {
  //                       serverSyncedsymptoms = {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.id},...serverSyncedsymptoms};
  //                    } //else {return ""}
  //                 });
  //        Object.keys(response.data.data.diseases).each((key, index)  => {           
  //                   var item = response.data.data.diseases[key];
  //                   if (item && item !== undefined) {
  //                       serverSynceddiseases = {[item.disease_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.id},...serverSynceddiseases};
  //                    } //else {return ""}
  //                 });

  //   // 
  //        // this.setState({synced: {}});
  //         let updatedSync = storedSynced;
  //         updatedSync = {
  //           symptoms: {...serverSyncedsymptoms},
  //           diseases: {...serverSynceddiseases},
  //         }

  //         console.warn("updatedSync:");
  //         console.log(updatedSync);


  //         localStorage.setItem('synced', JSON.stringify(updatedSync));
  //         // localStorage.setItem('synced', JSON.stringify(response.data.sync));
  //         console.log(response.data.data.sync);
  //         localStorage.setItem('syncedDate', new Date(response.data.data.sync));

  //         //update setstate on mypoi
  //         // saveState({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});  
  //         // return true
  //         return {synced: updatedSync};
  //         // return saveState({synced: updatedSync});


  //         // localStorage.setItem('syncedDate', JSON.stringify(response.data.syncDate));
  //         // console.log("returning for synced:");
  //         // console.log(response.data.data);

  //         // return response.data.data;

  //         // console.log(response.data.diseases);
  //         // console.log(response.data.symptoms);
  //         // this.setState({ diseases: response.data[0],symptoms: response.data[1],isLoading: false});
  //         // this.setState({ markers: {point: point, name: "current position", type: "gps_fixed", start: "",end: ""}: {home: response.data.home}});
  //         // // return response;
  //         // if (type === "diseases") {
  //         //   return response.data.diseases} else {return response.data.symptoms
  //         //   // return response.data[0]} else {return response.data[1]
  //         //   }              
  //           }
  //         }
  //       } else {console.error("error"); //200
  //         throw new Error('Something went wrong ...');
  //       }
  //     }).catch(error => {
  //       if (!error.response) {
  //           // network error
  //           this.errorStatus = 'Error: Network Error';
  //           // alert("offline");
  //           this.setState({ offline: true});
	// 			     localStorage.setItem('offline', true);
  //       } else {
  //           this.errorStatus = error.response.data.message;
  //       }
  //     })

	//   };





	// download markers and update details
	fetchUpdate = async (user,force) => {
		// async function asyncFunc() {

		// console.log("fetchUpdate");
		// console.log("did mount app");
	 //    console.log(this.state.updateChecked);
	    let updateChecked=this.state.updateChecked ? this.sqltodate(this.state.updateChecked) : this.sqltodate(localStorage.getItem("updateChecked"));
	    let senddata = {lastupdate:updateChecked};

	  	// 
	  	try{

	  		// let test=DataService.syncSynced();
	  		// console.log(test);
	  		//allmarkers
	  		console.warn("download getMarkers");
	  		console.log("forced:",force);
	  		console.log(senddata);
	  	    // let res = axios.post("https://hablarjuntos.com:5088/users/getMarkers", senddata);
	  	    let query = "getMarkers"
	  	    await axios.post(API_URL + query, senddata).then(res => {
	  	    console.log("processing:");
		    if (res) {
		    	// console.log(res);
		     const markers = res.data;
		     // console.log(markers);
		     this.setState({ allMarkers: markers,	updateChecked: Date.now()});
		     localStorage.setItem('Markers', JSON.stringify(markers));
		     console.log("markers downloaded and stored");
		     console.warn(markers);
		     // saveStateHome({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});	
	   			}	
      })
	  	  //   console.log("processing:");
		    // if (res) {
		    // 	console.log(res);
		    //  const markers = res.data;
		    //  console.log(markers);
		    //  this.setState({ allMarkers: markers});
		    //  console.log("markers downloaded and stored");
	   		// 	}
			}catch (err) {
				console.error(err);
				if (err.message === 'Network Error') {console.error('Check your connection or contact server admin!')} 
			}


	     	try{
	     	  if (user && user !== "guest") {
		  // update symptoms
			  // console.warn("user logged in:");
			  // alert("logged in");
			  //get my markers
			  let mymarkers = await axios.post(API_URL + "getMyMarkers", senddata, Constants.fetchOptions);
			  console.warn(mymarkers.data);
			  this.setState({ myMarkers: mymarkers.data});


		     let updateRes = await axios.get("https://hablarjuntos.com:5088/users/lastUpdate", Constants.fetchOptions);
			 let lastUpdate = updateRes.data;
			 console.warn(lastUpdate);

			 if (lastUpdate) {
		       // const delivery = post.data;
	       console.log("updating lastUpdate setState");
	       if (lastUpdate[0].TableName === 'symdis'){
	       	console.log(lastUpdate[0].updatedAt)};

	       	//load last updated version
	       	var loadStaticDate= localStorage.getItem('staticContentDate');
	       	if (loadStaticDate) {loadStaticDate=new Date(loadStaticDate)}
	       	//update static if newer
   			   let lastUpdateDate = new Date(lastUpdate[0].updatedAt);

			   console.warn("%c server: "+ lastUpdateDate, 'background: #222; color: #bada55');
   			   console.warn("%c local: "+loadStaticDate, 'background: #222; color: #bada55');

   			   console.warn(lastUpdateDate - loadStaticDate);
			     
			 if (force || (loadStaticDate && lastUpdateDate > loadStaticDate)) {
			 	if (force) {console.warn("forced updating local data");}
			 	console.warn("need new symdis version");
			 	let updateStatic = await axios("https://hablarjuntos.com:5088/users/getClientUpdate", Constants.fetchOptions);
			 	if (updateStatic) {
			 		console.warn("app.js - updated outdated static content");
				 	console.log(updateStatic.data);
				 	//make sure data is filled
				 	if (updateStatic.data.symptoms.length >1 && updateStatic.data.diseases.length > 1) {
				 		console.log("file look ok. -> setstate");

				 	localStorage.setItem('staticContentDate', new Date());
				 	localStorage.setItem('staticContent', JSON.stringify(updateStatic.data));
				 	saveStateHome({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});
				 	saveState({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});				 		
				 	} else {
				 		console.log("got empty data!!");
				 	}
			 	}


			 } else {
			 	//no force or update
			 	// saveState("symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases");
			 	console.warn("symdis version OK!")
			 } 

	         this.setState({
	         	updateChecked: Date.now(),
	         	lastUpdate: lastUpdate});
	    	 } //lastUpdate

			} //user
			else {
				console.warn("not logged in");
				if (user ==="guest") {
					let updateStatic = await axios("https://hablarjuntos.com:5088/users/getClientUpdate", Constants.fetchOptions);
			 	if (updateStatic) {
			 		console.warn("app.js - updated outdated static content");
				 	console.log(updateStatic.data);
				 	//make sure data is filled
				 	if (updateStatic.data.symptoms.length >1 && updateStatic.data.diseases.length > 1) {
				 		console.log("file look ok. -> setstate");
				 	localStorage.setItem('staticContentDate', new Date());
				 	localStorage.setItem('staticContent', JSON.stringify(updateStatic.data));
				 	saveStateHome({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});
				 	saveState({symptoms:updateStatic.data.symptoms, diseases:updateStatic.data.diseases});				 		
				 	} else {
				 		console.log("got empty data!!");
				 	}//else
				}//updateStatic
 
			}//user = guest
		}
			// return true;
			}catch (err) {
				if (err.message === 'Network Error') {console.error('Check your connection or contact server admin!')}
					// console.log(err.message);
			  // console.log(err);
		}

	  };

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard,offline } = this.state;
    let displayuser = currentUser? currentUser.username.split("@")[0]:"";


    
const ProtectedRoute = ({
  user,
  redirectPath = '/login',
  children,
}) => {
  if (!user && !AuthService.getCurrentUser()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
      // <Router>
    return (

        <div>

          <nav className="navbar navbar-expand navbar-dark bg-dark">
          <div className="container-fluid">
           <a className="navbar-brand" href="/home">IndexCase</a>
	

  				<div className="navbar-nav navbar-expand">
            <ul className="navbar-nav me-auto">
            
              {currentUser && (
                <li className="nav-item">
                  <Link to={"/mypoi"} className="nav-link">
                    My Poi
                  </Link>
                </li>
              )}
              {showModeratorBoard && (
                <li className="nav-item">
                  <Link to={"/users/mod"} className="nav-link">
                    Moderator Board
                  </Link>
                </li>
              )}

              {showAdminBoard && (
                <li className="nav-item">
                  <Link to={"/users/admin"} className="nav-link">
                    Admin Board
                  </Link>
                </li>
              )}

              {currentUser && (
                <li className="nav-item">
                  <Link to={"/users/user"} className="nav-link nav-user">
                    User
                  </Link>
                </li>
              )}
            </ul>
					{offline ? 
            <ul className="navbar-nav">
                <li className="nav-status">
                  <p className="">
                    status: {offline ? "offline" : "online"}
                  </p>
                </li>
                </ul>
                : "" }
            {currentUser ? (
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to={"/profile"} className="nav-link nav-user">
                    {displayuser.length >6 ? displayuser.substring(0,6)+"...":displayuser}

                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/login" className="nav-link" onClick={this.logOut}>
                    LogOut
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Login
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </li>
              </ul>
            )}
            </div>
            </div>
          </nav>


          <div className="container mt-3">
            <Routes>
              <Route exact path={"/"} element={<Home/>} />
	  	        <Route exact path="/home" element={<Home/>} />
              <Route element={<ProtectedRoute user={currentUser} />}>
					 			<Route exact path="/mypoi" element={<Mypoi/>} />
	              <Route exact path="/profile" element={<Profile/>} />
							  <Route exact path="/offline" element={<Offline/>} />
	              <Route path="/users/user" element={<BoardUser/>} />
	              <Route path="/users/mod" element={<BoardModerator/>} />
	              <Route path="/users/admin" element={<BoardAdmin/>} />
					 		</Route>
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/register" element={<Register/>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>

    );
  }
}


export default App;