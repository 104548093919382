import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import UserService from "../services/user.service";
import DataService from "../services/data.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Constants from '../services/constants'
import 'material-icons/iconfont/material-icons.css';

import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';



const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric"}
  return new Date(dateString).toLocaleDateString(undefined, options)
}
const formatTime = (timeString) => {
  // const options = {}
  return new Date(timeString).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit', hour12: false});
}


const roundDownTo = roundTo => x => Math.floor(x / roundTo) * roundTo;
const roundDownTo15Minutes = roundDownTo(1000 * 60 * 15);

const [staticData,outdated] = DataService.getStaticData();

function diseaseButton(synced,newData,idtarget,i,setdiseaseEndModal,setDiseaseEndName,setdiseaseEndId,setendDiseaseStartDate){
  console.log(idtarget.itemName);
  // console.log(i.index);
   let itemId=i.itemId;
      let itemName=idtarget.itemName.replace(/ /g,"_");
console.log("diseaseButton");
console.log(itemName,itemId);
  console.log(synced.synced);//.symptoms[itemId]);
diseaseActive(synced.synced,newData.newData,itemName,itemId, function(isActive){
console.log(isActive);
console.warn("diseaseButton");
if (!isActive) {
  console.log("new disease?");
} else {
  //load modal
  console.log(synced.synced.diseases[itemId].fromdate);
  setendDiseaseStartDate(synced.synced.diseases[itemId].fromdate);
    setDiseaseEndName(itemName);
    setdiseaseEndId(itemId);
    setdiseaseEndModal(true);
    console.log("set name id and date");
 } //else
});
}//diseasebutton


const DiseaseUpdatebutton  = (e,index,sync,setsynced,setDisease,diseaseendDate) => {
   const now=roundDownTo15Minutes(new Date());

  e.stopPropagation();
  // console.log(e);
  // console.log(index)
  let diseaseDate = diseaseendDate
  let query = 'updateDisease';
  let symId = index;
  // console.log(sync);
    let id= sync.diseases[symId].id;
let newsync = {...sync};
  newsync.diseases[symId].todate=diseaseDate;
// console.log(newsync);
DataService.postUpdateTodate(query, Constants.API_URL, id, diseaseDate, Constants.fetchOptions, (response) => {
if (response) {
  setsynced(newsync);
  setDisease.setDiseaseEndName(undefined);
  setDisease.setendDiseaseStartDate(undefined);
  setDisease.setdiseaseEndId(undefined);
  setDisease.setdiseaseEndModal(false);
  setDisease.setdiseaseEndDate(now);
  } //response
});


}

function symptomButton(synced,newData,idtarget,i,setsymptomEndModal,setsymptomEndName,setsymptomEndId,setendSymptomStartDate) {
  console.log(idtarget.itemName);

  // console.log(i.index);
  
   let itemId=i.itemId;
      let itemName=idtarget.itemName.replace(/ /g,"_");
console.log("symptomButton");
console.log(itemName,itemId);
  console.log(synced.synced);//.symptoms[itemId]);
// synced,newData,t,itemId, fn
// symptomActive(synced,newData,itemName,itemId, function(selected){
  // synced,newData,t,itemId, fn
  // console.log(synced);console.log(newData);console.log(itemName,itemId);
symptomActive(synced.synced,newData.newData,itemName,itemId, function(isActive){
console.log(isActive);
console.warn("symptomButton");
if (!isActive) {
  console.log("new symptom?");
  // symptomStart(idtarget,itemId);
  // symptomStart = (item,itemId) => {
    // endSymptomName
// startSymptomModal:true,
//               startSymptomName: item.itemName,
//               // startSymptomStartDate:this.state.synced.symptoms[itemId].fromdate,
//               startSymptomId: itemId});



  // return true
} else {
  // console.log("symptomEnd");
  // console.log(itemName);
  // console.log(itemId);
  //load modal
  console.log(synced.synced.symptoms[itemId].fromdate);
  setendSymptomStartDate(synced.synced.symptoms[itemId].fromdate);
    setsymptomEndName(itemName);
    setsymptomEndId(itemId);
    setsymptomEndModal(true);
    console.log("set name id and date");

  // symptomEnd(idtarget,itemId);
 }


}); //symptombutton

} 


//}




  // const symptomUpdatebutton  = () => {
const SymptomUpdatebutton  = (e,index,sync,setsynced,setSymptom,symptomendDate) => {
   const now=roundDownTo15Minutes(new Date());

  e.stopPropagation();
  // console.log(e);
  // console.log(index)
  let symptomDate = symptomendDate
  let query = 'updateSymptom';
  let symId = index;
  // console.log(sync);
    let id= sync.symptoms[symId].id;
let newsync = {...sync};
  newsync.symptoms[symId].todate=symptomDate;
// console.log(newsync);
DataService.postUpdateTodate(query, Constants.API_URL, id, symptomDate, Constants.fetchOptions, (response) => {
if (response) {
  setsynced(newsync);
  setSymptom.setendSymptomName(undefined);
  setSymptom.setendSymptomStartDate(undefined);
  setSymptom.setsymptomEndId(undefined);
  setSymptom.setsymptomEndModal(false);
  setSymptom.setsymptomEndDate(now);
  } //response
});


}


const symptomActive= (synced,newData,t,itemId, fn) => {
if (synced  && synced.symptoms && typeof synced.symptoms !== 'undefined') {
  if(typeof synced.symptoms[itemId] !== 'undefined' && synced.symptoms[itemId].todate === null) {
    // console.log("found and returning:")
    // console.log(synced.symptoms[itemId]);
    return fn(synced.symptoms[itemId]);
 }  
   else {
        if (typeof newData.symptoms[t] === 'undefined') {
          // console.log("return false");
           return fn(false)}
        else {
        // console.log("returning new:");
        return fn(newData.symptoms[t].active);
        }
   }

  }//if
}





const diseaseActive= (synced,newData,t,itemId, fn) => {
if (synced  && synced.diseases && typeof synced.diseases !== 'undefined') {
  if(typeof synced.diseases[itemId] !== 'undefined' && synced.diseases[itemId].todate === null) {
    console.log("found and returning:")
    console.log(synced.diseases[itemId]);
    return fn(synced.diseases[itemId]);
 }  
   else {
        if (typeof newData.diseases[t] === 'undefined') {
          console.log("return false");
           return fn(false)}
        else {
        // return this.state.synced.symptoms[itemId]
        // console.log("returning:");
        return fn(newData.diseases[t].active);
        }
   }
  }//if
}


function renderSymptoms(renderMySymptoms,setrenderMySymptoms,symptoms,newData,synced,setsymptomEndModal,setsymptomEndName,setsymptomEndId,setendSymptomStartDate) {
  // let active =[];
  // let inactive = [];
    if (!synced || !synced.symptoms || Object.keys(synced.symptoms).length  === 0) {console.warn("no symptoms");
  return setrenderMySymptoms([...renderMySymptoms,<span key="symptomsNone">none</span>]);
  }

  let buttons=[];
   Object.keys(symptoms).forEach((key, index)  => {
                var item = symptoms[key];
                if (!item || !item.name) {return setrenderMySymptoms("<div>something went wrong please report to crash@hablarjuntos.com</div>")}
                var itemName = item.name.replace(/ /g,"_");
                var itemId = item.symptom_id;
                symptomActive(synced,newData,itemName,itemId, function(selected){
                  if (selected && selected !== false) {
                  var isSelected = selected ? "iconsSelected": "disabledIcon";
                    buttons.push(<Button id={index + itemName} type="button" key={index} onClick={() => symptomButton({synced},{newData},{itemName},{itemId},setsymptomEndModal,setsymptomEndName,setsymptomEndId,setendSymptomStartDate)} className={"symptoms-icons "+ isSelected} color=""><span className={"symptom_"+itemName+" symptom_icons"}></span></Button>);
                  } 
                });
            }) 
                console.log(buttons.length);
                if (buttons.length === 0) {buttons.push(<span id="0symptoms" key="symptomsNone">none</span>)}
                  setrenderMySymptoms(buttons);

}

function renderDiseases(renderMyDiseases,setrenderMyDiseases,diseases,newData,synced,setdiseaseEndModal,setDiseaseEndName,setdiseaseEndId,setendDiseaseStartDate){
  console.warn("render diseases");
  // let active =[];
  // let inactive = [];
  if (!synced || !synced.diseases || Object.keys(synced.diseases).length  === 0) {console.warn("no diseases");
    return setrenderMyDiseases([...renderMyDiseases,<span key="diseaseNone">none</span>]);
  }
  let buttons=[];
  Object.keys(diseases).forEach((key, index)  => {
                var item = diseases[key];
                // let syncitem= synced.diseases[key];
                if (!item || !item.name) {return setrenderMyDiseases("<div>something went wrong please report to crash@hablarjuntos.com</div>")}
                var itemFullName=item.name;
                var itemName = item.name.replace(/ /g,"_");
                var itemId = item.disease_id;
                // let itemDes = item.description;
                  diseaseActive(synced,newData,itemName,itemId, function(selected){
                  if (selected && selected !== false) {
                  var isSelected = selected ? "iconsSelected": "disabledIcon";
                  console.log(index,itemName,itemId);
                    buttons.push(<Button id={index + itemName} type="button" key={index} onClick={() => diseaseButton({synced},{newData},{itemName},{itemId},setdiseaseEndModal,setDiseaseEndName,setdiseaseEndId,setendDiseaseStartDate)} className={"diseases-icons "+ isSelected} color=""><span className={"disease_"+itemName+" disease_icons"}>{itemFullName}</span></Button>);
                    }
                });
            })
          if (buttons.length === 0) {buttons.push(<span id="0diseases" key="diseaseNone">none</span>)}
          setrenderMyDiseases(buttons);
}


export default function App () {

// const [staticData,outdated] = DataService.getStaticData();


 // const [modal, setModal] = useState(false);
 // const toggle = () => setModal(!modal);
 const now=roundDownTo15Minutes(new Date());

 const [endSymptomName, setendSymptomName] = useState(false);

// const [content, setcontent] = useState("");
const [symptomsMenu, setsymptomsMenu] = useState("loading");

const [diseases, setdiseases] = useState(staticData.diseases);
const [symptoms, setsymptoms] = useState(staticData.symptoms);
const [synced, setsynced] = useState(DataService.getSynced());
// const [StoredMarkers,setStoredMarkers] = useState(DataService.getStored());

const [symptomEndModal,setsymptomEndModal] = useState(false)
const toggle = () => setsymptomEndModal(!symptomEndModal);


// const [symptomStartName,setsymptomStartName] = useState("");
const [symptomEndName,setsymptomEndName] = useState("");
const [symptomEndId,setsymptomEndId] = useState("");
const [symptomEndDate,setsymptomEndDate] = useState(now);
// const [symptomStartDate, setsymptomStartDate] = useState(now);

const [diseaseEndName,setDiseaseEndName] = useState("");
const [diseaseEndId,setdiseaseEndId] = useState("");
const [diseaseEndDate,setdiseaseEndDate] = useState(now);
const [diseaseEndStartDate,setdiseaseEndStartDate] = useState(now);
const [diseaseEndIndex,setdiseaseEndIndex] = useState("");
const [diseaseEndModal,setdiseaseEndModal] = useState(false);
// diseasemEndDate

const [renderMyDiseases,setrenderMyDiseases] = useState([]);
const [newData, setnewData] = useState({
                                        point: [],
                                        symptoms: [],
                                        diseases:[] });

const [renderMySymptoms,setrenderMySymptoms] = useState([]);

const [endDiseaseStartDate,setendDiseaseStartDate] = useState("");
const [endSymptomStartDate,setendSymptomStartDate] = useState("");


useEffect(() => {
  if (diseases) {
    console.log("diseases loading");
        //update localstorage
    localStorage.setItem('synced', JSON.stringify(synced));
  renderDiseases(renderMyDiseases,setrenderMyDiseases,diseases,newData,synced,setdiseaseEndModal,setDiseaseEndName,setdiseaseEndId,setendDiseaseStartDate);

}
},[synced,diseases,newData, renderMyDiseases]);

useEffect(() => {
  console.warn("changes synced");
if (symptoms ) {
    //update localstorage
    localStorage.setItem('synced', JSON.stringify(synced));
    console.log("symptoms loading");
            //  (renderMySymptoms,setrenderMySymptoms,symptoms,newData,synced,setsymptomEndModal,setsymptomEndName,setsymptomEndId)
  renderSymptoms(renderMySymptoms,setrenderMySymptoms,symptoms,newData,synced,setsymptomEndModal,setsymptomEndName,setsymptomEndId,setendSymptomStartDate);
 }
}, [synced,symptoms,newData, renderMySymptoms]);



                let started = endSymptomStartDate||endSymptomStartDate;
                let startedDate = formatDate(started);
                let startedTime = formatTime(started);

return (
      <div className="container">
        <Modal modalClassName="endSymptom" contentClassName="endModalContent" size="sm" isOpen={symptomEndModal}>
              <ModalBody>
                <div className="EndModalMsg">
                {symptomEndName ? 

// let setHours= (setMinutes(newDate(), 30),16);
<div className="pickerModal"><span><br />Symptom: <b>{symptomEndName}</b></span><br />
    Date and time you stopped having this symptom.<br />
     <div className="modifyEndDate">
started: {startedDate} - {startedTime}<br />
<div className="picker"> <span className="pickerType">ended</span> 

    <DatePicker className="softinput"
              selected={symptomEndDate}
              onChange={(date) => setsymptomEndDate(date)}
              endDate={symptomEndDate}
              minDate={new Date(endSymptomStartDate)}
              maxDate={new Date()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy h:mm aa"
              />
               
              </div>
    </div>

    <ModalFooter>
          <Button color="primary" onClick={(e)=>SymptomUpdatebutton(e,symptomEndId,synced,setsynced,{setendSymptomName,setendSymptomStartDate,setsymptomEndId,setsymptomEndModal,setsymptomEndDate},symptomEndDate)} disabled={!symptomEndDate}>remove</Button>{' '}
          <Button color="secondary" onClick={()=> {
                                              //symptom
                                              setendSymptomName(undefined);
                                              setendSymptomStartDate(undefined);
                                              setsymptomEndDate(now);
                                              setsymptomEndId(undefined);
                                              setsymptomEndModal(false);
                  }
          }>Cancel</Button>
        </ModalFooter></div>

   // symptomUpdateCloseModal
                  //removeSymptom(symptomEndName)
                  :""}
                </div>
                </ModalBody>
            </Modal>

              <Modal modalClassName="endDisease" contentClassName="endModalContent" size="sm" isOpen={diseaseEndModal}>
              <ModalBody>
                <div className="EndModalMsg">
                {diseaseEndName ? 

// let setHours= (setMinutes(newDate(), 30),16);
<div className="pickerModal"><span><br />Disease: <b>{diseaseEndName}</b></span><br />
    Date and time you stopped having this disease.<br />
     <div className="modifyEndDate">
started: {startedDate} - {startedTime}<br />
<div className="picker"> <span className="pickerType">ended</span> 

    <DatePicker className="softinput"
              selected={diseaseEndDate}
              onChange={(date) => setdiseaseEndDate(date)}
              endDate={diseaseEndDate}
              minDate={new Date(endDiseaseStartDate)}
              maxDate={new Date()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy h:mm aa"
              />
               
              </div>
    </div>

    <ModalFooter>
          <Button color="primary" onClick={(e)=>DiseaseUpdatebutton(e,diseaseEndId,synced,setsynced,{setDiseaseEndName,setendDiseaseStartDate,setdiseaseEndId,setdiseaseEndModal,setdiseaseEndDate},diseaseEndDate)} disabled={!diseaseEndDate}>remove</Button>{' '}
          <Button color="secondary" onClick={()=> {
                                              //disease
                                              setDiseaseEndName(undefined);
                                              setdiseaseEndStartDate(undefined);
                                              setdiseaseEndId(undefined);
                                              setdiseaseEndIndex(undefined);
                                              setdiseaseEndModal(false);
                  }
          }>Cancel</Button>
        </ModalFooter></div>

   // symptomUpdateCloseModal
                  //removeSymptom(symptomEndName)
                  :""}
                </div>
                </ModalBody>
            </Modal>
      Here you can manage your active symptoms.
      <div><b>My active symptoms:</b></div>
      <div>{renderMySymptoms}</div>
      <b>My active diseases:</b><br/>
      <div>{renderMyDiseases}</div>
      <div>
      If you have new symptoms or diseases you can add them on the <a href="/mypoi">My Poi</a> page by selecting a location where it first started.
      </div>
      </div>
    );

}