import React, { Component } from "react";

import UserService from "../services/user.service";
import { Card, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';

export default class BoardModerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getModeratorBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
    this.getLocalStorage();
  }

getLocalStorage() {   
    //load content from localStorage
        var loadStatic = localStorage.getItem('staticContent');
      // console.log(loadStatic);
       // loadStatic.map(function(item, i){
      // console.log(item);});

// this.getClientUpdate();
  // console.log(loadStatic);
    // let lastUpdate= localStorage.getItem(lastUpdate);
    if (!this.state.diseases) {
      if (loadStatic) {
        try {
          loadStatic = JSON.parse(loadStatic);

        //           this.setState({
        //   content: response.data
        // });
           this.setState({
            diseases: loadStatic.diseases,
            symptoms: loadStatic.symptoms,
          }, () => {
  console.log(this.state.diseases);
    });
        } catch (e) {console.error('Error:'+e)}
      }     


    }

}

 // <header className="jumbotron">
 //          <h3>{this.state.content}</h3>
 //        </header>
        
  render() {
    return (
           <div className="container">
       
          <Row>
          edit own data
          <br />
          {this.state.symptomsMenu}

      <Col sm="6">
        <Card body>
        <FormGroup>
        <Label for="exampleSelectMulti">Symptoms</Label>
        <Input type="select" name="select" id="Symptons">
          <option>cough</option>
          <option>headache</option>
          <option>fever</option>
          <option>running nose</option>
          <option>joint pain</option>
          <option>muscle pain</option>
        </Input>
      </FormGroup>   
          <Button>Edit</Button>
        </Card>

      </Col>
      <Col sm="6">
        <Card body>
           <FormGroup>
        <Label for="diseaseSelect">Disease</Label>
        <Input type="select" name="select" id="disease">
          <option>covid 19</option>
          <option>ebola</option>
          <option>tbc</option>
        </Input>
      </FormGroup>
          <Button>Edit</Button>
        </Card>
      </Col>
    </Row>
    <Row>
          <Col sm="6">
        <Card body>
            <FormGroup>
        <Label for="medicineSelect">Medicine</Label>
          <Input type="select" name="select" id="medicine">
            <option>Pain killer</option>
            <option>doctor</option>
            </Input>
          </FormGroup>
          <Button>Edit</Button>
        </Card>
      </Col>
      </Row>
      </div>
    );
  }
}