// import React, {useEffect, useState, useRef } from "react";
import React, {useState, useRef } from "react";
import {useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
// import {useLocalStorage} from "../services/store.service";
import AuthService from "../services/auth.service";
// import NotificationService from "../services/notification.service";

// import {saveStateApp} from "../App";

// const navigate = useNavigate();

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

// function SigninForm() {
//   let navigate = useNavigate();
//   navigate.push("/profile");
// }

function Login(props) {
// export default class Login extends Component {

  // constructor(props) {
  //   super(props);
  //   this.handleLogin = this.handleLogin.bind(this);
  //   this.onChangeUsername = this.onChangeUsername.bind(this);
  //   this.onChangePassword = this.onChangePassword.bind(this);
  //   this.state = {
  //     username: "",
  //     password: "",
  //     loading: false,
  //     message: "",
  //     ServerMarkers: {},
  //   };
  // }
  
  const form = useRef();
  const checkBtn = useRef();
  const navigate = useNavigate();

  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [message,setMessage] = useState("");

 // const [connected,setConnected] = useLocalStorage("connected", localStorage.getItem("connected"));

  // const [connected,setConnected] = useState(localStorage.getItem("connected"));
  // const [ServerMarkers,setServerMarkers] = useState({});

// const onClickHandler = (e) => {
//       let currentUser = AuthService.getCurrentUser();

// useEffect(() => {
//   if (currentUser && connected) {
//     alert("already logged in:");
//     console.log(connected);
//     return <Navigate to="/login" />;
//     // navigate('/');
//   } else {}
//  },[connected]);


  // if (connected) {
  //   console.log("already logged on");navigate('/profile');
  // }

    // // console.log(currentUser);
    // if (currentUser) {console.log("already logged on");navigate('/profile');}


  const onChangeUsername = (e) => {
    setUsername(e.target.value);
    // this.setState({      username: e.target.value});
  }

  const onChangePassword= (e) => {
    setPassword(e.target.value);
    // this.setState({password: e.target.value});
  }

  const handleLogin= (e) => {
    e.preventDefault();

    // this.setState({
      // message: "",
      // loading: true
    // });
    setLoading(true);
    setMessage("");


    form.current.validateAll();

// checkBtn.current.context._errors.length === 0
    if (checkBtn.current.context._errors.length === 0) {
      console.log("storing login details:");
      AuthService.login(username, password).then(
        (resolve) => {
          // console.log(resolve);

          if (resolve.success) {
            console.log("login: success");
            // // start notification subscription returns notificationId
            // NotificationService.subscribe()
            navigate('/profile');
              // navigate('/home');
            // console.log(resolve);
            // localStorage.setItem("user", JSON.stringify(resolve));
            // setConnected(true);
            //   currentUser:resolve,
            //   showModeratorBoard: resolve.roles.includes("ROLE_MODERATOR"),
            //   showAdminBoard: resolve.roles.includes("ROLE_ADMIN")});
            // console.log(resolve);
            // saveStateApp()
            // SigninForm();
            // this.props.history.push("/profile");
            //TODO
            //
            // window.location.reload();

        }else {
          console.error("login: error");
          console.error(resolve);
          setLoading(false);
          setMessage(resolve.msg);
          // this.setState({
          //   loading: false,
          //   message: resolve.msg
          // })
          }
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          // this.setState({
          //   loading: false,
          //   message: resMessage
          // });
          setLoading(true);setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
      setMessage("You have entered an invalid username or password");
      // this.setState({
      //   loading: false
      // });
    }
  }

  // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
  // render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="assets/images/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={handleLogin}
            ref={form}
          >
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
                autoComplete="username"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                autoComplete="current-password"
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={checkBtn}
            />
          </Form>
        </div>
      </div>
    );
  //}
}
export default Login

// c => {
//                 checkBtn = c;
//               }

// c => {
//               form = c;
//             }