import React, { Component } from "react";

import axios from "axios";
import authHeader from '../services/auth-header';

import UserService from "../services/user.service";
import DataService from "../services/data.service";

import {Button, Card, Col, FormGroup, Label, Input } from 'reactstrap';

const API_URL = "https://hablarjuntos.com:5088/users/";
const fetchOptions = { headers: authHeader() };

  // export function updateState(text){
  //   console.log("updating compontent setstate with:")
  //   console.log(text);
  //   // this.setState(text)
  //   }
 export function saveState(input){
console.log(input);
this.setState(input);
// return true
  }


export default class BoardAdmin extends Component {

  constructor(props) {
    super(props);
    // saveState = saveState.bind(this)
    // this.updateState = this.updateState.bind(this)
    const [staticData,outdated] = DataService.getStaticData();
    console.log(outdated);
    if (outdated) {this.forceUpdateStatic()}

    this.state = {
      content: "",
      markers: DataService.getMarkers(),
      diseases: staticData.diseases,
      symptoms: staticData.symptoms,
      outdated: outdated,
      newValue:"",
      validInput: false,
      editSelect: {
        itemId: "",
        name: "",
        itemDesc: "",
      },
      editField: "",
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data,
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
    
    if (this.state.outdated) {
        // console.log(this.state.outdated);
   
        // let newData = await DataService.updateServerData();
        // if (newData) {
          // console.log("reloading symdis")
          // this.setState({
          // diseases: newData.diseases,
          // symptoms: newData.symptoms,
          // outdated: false
        // })  
        // }
          }
        

      // this.setState({
      // diseases: DataService.getServerData("diseases"),
      // symptoms: DataService.getServerData("symptoms")
      // })

  }

    componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (prevState.outdated !== this.state.outdated && this.state.outdated === true) {
    this.forceUpdateStatic();


    }

    if (prevState.diseases !== this.state.diseases || prevState.symptoms !== this.state.symptoms ) {
      localStorage.setItem("staticContent", JSON.stringify({diseases: this.state.diseases, symptoms: this.state.symptoms}));
    }
  }
  forceUpdateStatic(){
        console.log("OUTDATED TRUE");
           console.warn("updating static content");
    let query = 'getClientUpdate';
       axios(API_URL + query, fetchOptions)
      .then(response => {
        if (response && response.status === 200) {
          localStorage.setItem('staticContentDate', new Date());
          localStorage.setItem('staticContent', JSON.stringify(response.data));
          console.log(response.data);
          this.setState({ diseases: response.data.diseases,symptoms: response.data.symptoms,outdated: false});
          // this.setState({ markers: {point: point, name: "current position", type: "gps_fixed", start: "",end: ""}: {home: response.data.home}});
        } else {console.error("error");
          throw new Error('Something went wrong ...');
        }
      })
  }

  diseaseMod(e) {
    let name = e.target.value;
    var selectedIndex = e.target.options.selectedIndex;
    var itemId= +e.target.options[selectedIndex].getAttribute('data-itemid');
    var itemDesc= e.target.options[selectedIndex].getAttribute('data-itemDesc');
    console.log(itemDesc);

    this.setState({
      symptomValue: "",
      diseaseValue: undefined,
      editNameField: name,
      editDescriptionField:itemDesc,

      editField: "disease",
      editSelect: {name:name,
                   itemId: itemId,
                   itemDesc:itemDesc}
    })
    return true
  }


  symptomMod(e) {
    // console.log(e);
    let name = e.target.value;
    var selectedIndex = e.target.options.selectedIndex;
    var itemId= +e.target.options[selectedIndex].getAttribute('data-itemid');
    var itemDesc= e.target.options[selectedIndex].getAttribute('data-itemDesc');
    console.log(itemDesc);
    this.setState({
      diseaseValue: "",
      symptomValue: undefined,
      editNameField: name,
      editDescriptionField:itemDesc,
      editField: "symptom",
      editSelect: {name:name,
                   itemId: itemId,
                   itemDesc: itemDesc
                 }
    })
    return true
  }

modifySVButton = (e, type) => {
  let id=this.state.editSelect.itemId;
  let name= this.state.editSelect.name;
  let description= this.state.editDescriptionField;
  console.log(type);
  if (window.confirm('Save the item?')) {
  console.log("saving!"+ id);
  console.log(typeof id);
  if (DataService.renameSymDis(type,id,name,description)) {
    console.log("renamed ok: storing in memory");
    // this.state.diseases.map(
    //   el => {if (el.disease_id === id) {console.log(el.name);} else {console.log("not:"+el.disease_id)}
    // })
  this.setState(prevState => ({
      diseases: prevState.diseases.map(
    el => el.disease_id === id? {disease_id: id, name: name, description:description}: el
  )   
    }));
  };
}  else {console.log("dont save");}
}



modifyRMButton = (e,index) => {
  console.log("clicked");
  console.log(index);
  console.log(e.target.value);
console.log(this.state.editSelect.name);
console.log(this.state.editSelect.itemId);
if (window.confirm('Delete the item?')) {
  console.log("delete");
}  else {
  console.log("dont delete");
}

// this.setState(prevState => ({
//       diseaseDate:false,
//       newData: {
//         ...prevState.newData,
//         diseases: {
//           ...prevState.newData.diseases,           // copy all other key-value pairs of disease object
//           [index.key]: undefined
//         }
//       }
//     }));

  return true
}

changeValue = (e) => {
  var editbox = e.currentTarget.id;
  // console.log(editbox);
  let newName=this.state.editNameField;
  let newDesc=this.state.editDescriptionField;

  if (editbox === "editNameField") {
      newName= e.target.value;
      if (newName.length < 2 && this.state.validInput) {this.setState({validInput: false})} else {this.setState({validInput: true});}
    }

  if (editbox === "editDescriptionField") {
      newDesc= e.target.value;
      if (newName.length < 1 && this.state.validInput) {this.setState({validInput: false})} else {this.setState({validInput: true});}
    }

    this.setState(prevState => ({
    editNameField: newName,
    editDescriptionField: newDesc,
    editSelect: {
    ...prevState.editSelect,
    name: newName,
    description: newDesc
    }
      }));
  }
addEnabled () {
  if (!this.state.editSelect.name || this.state.editSelect.name === "None" ) {return false} else {return true}
  
}

validDelete () {
// console.log(this.state.editSelect);
if (this.state.editSelect.itemId > 0) {
  if (this.state.validInput) {return true} else {return false}
  }
  else {return true}
}

symptomAddButton () {
console.log("add symptoms");
this.setState(prevState => ({
    editSelect: {
    ...prevState.editSelect,
    name: undefined
    }
    }));
}

diseaseAddButton () {
this.setState(prevState => ({
    editSelect: {
    ...prevState.editSelect,
    name: undefined
    }
    }));

}


        // <header className="jumbotron">
        //   <h3>{this.state.content}</h3>
        // </header>
  render() {
    return (
      <div className="container">
        <Card body inverse color="danger">
             <FormGroup row>
           <Label for="diseaseSelect" sm={5}>Disease</Label>

        <Col sm={6}>
        select to modify
          <Input type="select" name="select" value={this.state.diseaseValue} onChange={e => this.diseaseMod(e)} id="diseaseSelect">
            <option>None</option>
              {this.state.diseases && this.state.diseases.length > 1 ?

            Object.keys(this.state.diseases).map((key, index)  => {
              var itemName = this.state.diseases[index].name;
              var itemDesc = this.state.diseases[index].description;
              var itemId = this.state.diseases[index].disease_id;
              return (
              <option key={index} data-itemid={itemId} data-itemdesc={itemDesc}>{itemName}</option>
                    )
                          })
                          : ""}
          </Input>
        </Col>
      <Col sm={2}>
      <Button color="primary" size="sm" disabled={this.addEnabled()} onClick={e => this.diseaseAddButton(e)}>Add</Button>
      </Col>

      </FormGroup>
      </Card>
      <Card body inverse color="warning">
      <FormGroup row>
           <Label for="symptomsSelect" sm={5}>Symptom</Label>
        <Col sm={6}>
        select to modify
          <Input type="select" name="select" value={this.state.symptomValue} onChange={e => this.symptomMod(e)} id="symptomSelect">
            <option>None</option>
            {this.state.symptoms && this.state.symptoms.length > 1 ?
            Object.keys(this.state.symptoms).map((key, index)  => {
              var itemName = this.state.symptoms[index].name;
              var itemDesc = this.state.symptoms[index].description;
              var itemId = this.state.symptoms[index].symptom_id;
              return (
              <option key={index} data-itemid={itemId} data-itemdesc={itemDesc}>{itemName}</option>
                    )
                          })
                          :""}
          </Input>
        </Col>
      <Col sm={2}>
      <Button color="primary" size="sm" disabled={this.addEnabled()} onClick={e => this.symptomAddButton(e)}>Add</Button>
      </Col>
      </FormGroup>
      </Card>
      {this.state.editSelect.name !== "None"  && this.state.editSelect.name !== "" ?
      <Card body inverse color="info">
      <FormGroup>
        {this.state.editField}<br />
        <Label for="editNameField">Name:</Label>
        <Input type="text" id="editNameField" value={this.state.editNameField} onChange={e => this.changeValue(e)} placeholder={this.state.editSelect.name} />
    
        <Label for="editDescriptionField">Description:</Label>
        <Input type="text" id="editDescriptionField" value={this.state.editDescriptionField|| ""} onChange={e => this.changeValue(e)} placeholder={this.state.editSelect.description} />

        <Button color="primary" size="sm" disabled={!this.state.validInput} onClick={e => this.modifySVButton(e,this.state.editField)}>Save</Button>{' '}
        <Button color="danger" size="sm" disabled={this.validDelete()} onClick={e => this.modifyRMButton(e)}>Delete</Button>
      </FormGroup>
      </Card>
      : ""}


      <Card body inverse color="info">
      <FormGroup row>
           <Label for="markersSelect" sm={5}>Markers</Label>
        <Col sm={6}>
        select to modify
          <Input type="select" name="selectMarker" id="markerSelect">
            <option>None</option>
            {Object.keys(this.state.markers).map((key, index)  => {
              var itemLoc = this.state.markers[index].location_id;
              var itemDis = this.state.markers[index].disease_id;
              var itemSym = this.state.markers[index].symptom_id;
              var itemcoords = this.state.markers[index].location.coords;
              var itemdate = this.state.markers[index].reatedAt;
              return (
              <option key={index} data-itemloc={itemLoc} data-itemdis={itemDis} data-itemsym={itemSym}>{itemcoords}| {itemLoc} - {itemDis} - {itemSym} - {itemdate}</option>
                    )
                          })}
          </Input>
        </Col>
      <Col sm={2}>
      <Button color="primary" size="sm" disabled={this.addEnabled()} onClick={e => this.symptomAddButton(e)}>Add</Button>
      </Col>
      </FormGroup>
      </Card>



      </div>
    );
  }
}
