import axios from "axios";
import {saveStateApp} from "../App";
// import {saveState} from "../components/mypoi.component";
import * as serviceWorker from "../serviceWorker";

const API_URL = "https://hablarjuntos.com:5088/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "login", {
        username,
        password
      })
      .then(response => {
        if (response.data) {
        let user = response.data;
          if (user.token) {
            let user = response.data;
            console.log(user);
            console.log(user.subscription);
            localStorage.setItem("user", JSON.stringify(user));
          axios.defaults.headers.common['Authorization']=user.token;
            saveStateApp({
              refreshUser: true})
        }
        return user;
      } else {return "error"}
      });
  }

  logout() {
    serviceWorker.unregister();
    localStorage.removeItem("user");
  }

  register(username, email, password,fn,ln) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      fn,
      ln
    });
  }

  getCurrentUser() {
    // console.time("getCurrentUser");
    let user = JSON.parse(localStorage.getItem('user'));
    // console.timeEnd("getCurrentUser");
    // if (!user) {console.error("getCurrentUser = null")}
    return user;
    // console.log(JSON.parse(localStorage.getItem('user')));
    // return JSON.parse(localStorage.getItem('user'));
// return JSON.parse(localStorage.getItem('user'));
    // setTimeout(function(){
// return JSON.parse(localStorage.getItem('user'));
// }, 0,1);//wait 0 seconds
  }
}

export default new AuthService();