import React, { useEffect, useState } from "react";
import L from "leaflet";
import debounce  from 'debounce';
import { MapContainer,ZoomControl } from "react-leaflet";
import "leaflet.offline";
import "leaflet/dist/leaflet.css";
import AuthService from "../services/auth.service";

import storageLayer from './index/storageLayer';


const currentUser =  AuthService.getCurrentUser();

const center = (currentUser && currentUser.person && currentUser.person.home) ? JSON.parse(currentUser.person.home) : [51.505, -0.09]
const zoom = 13


// events while saving a tile layer

// let progress, total;
const showProgress = debounce(({progress,currentTotal}) => {
  // console.log((progress/currentTotal) * 100);
  let percentage=(progress/currentTotal) * 100;
  let perText = parseInt(percentage)+'%';
  // console.log((progress/total) * 100);
  document.getElementById('progressbar').style.width = percentage+'%';
  // document.getElementById('progressbar').style.width = `${(progress/total) * 100}%`;
  // document.getElementById('progress-label').innerHTML = '<span class=mix-blend>'+parseInt(percentage)+'%</span>';  
    document.getElementById('progressbar').innerHTML = perText;  
    document.getElementById('progress-label').innerHTML = perText;  
    document.getElementById('progress-total-label').innerHTML = progress+'/'+currentTotal;
  // document.getElementById('progressbar').innerHTML = <span class=mix-blend>100</span> parseInt(percentage)+'%';  
  if(progress === currentTotal) {
    setTimeout(() => document.getElementById('progress-wrapper').classList.remove('show'), 1000);    
  }
}, 10);


const Map = () => {
  const [map, setMap] = useState();
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  // const [size,setSize] = useState(0);

        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            // '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',


  useEffect(() => {
    if (map) {
      //baselayer
      const tileLayerOffline = L.tileLayer.offline(
        'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        {
          attribution:
     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
          subdomains: "abc",
          minZoom: 13,
          maxZoom: 14
        }
      );

      tileLayerOffline.addTo(map);

      //control
      const controlSaveTiles = L.control.savetiles(tileLayerOffline, {
        zoomlevels: [13, 14, 15, 16], // optional zoomlevels to save, default current zoomlevel
        confirm(layer, succescallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            succescallback();
          }
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm("Remove all the tiles?")) {
            successCallback();
          }
        },
        saveText:
          '<i class="material-icons fa-download" aria-hidden="true" title="Save tiles">download</i>',
        rmText:
          '<i class="material-icons fa-trash" aria-hidden="true"  title="Remove tiles">delete</i>'
      });

      // console.log('test');
      console.log(controlSaveTiles);
      //avoid multple controls
      if (!controlSaveTiles._map) {
      // if (!L.control.savetiles()._map) {

        //control
      controlSaveTiles.addTo(map);        
      }

      // layer switcher control
      const layerswitcher = L.control
      .layers({
        '<span class="itemsgroupOfflineRadio">offline</span>': tileLayerOffline,
          }, null, { position: 'topleft', collapsed: true })
      .addTo(map);

      // add storage overlay
      storageLayer(tileLayerOffline,layerswitcher);

      let progress;
      // let total;
      tileLayerOffline.on("savestart", (e) => {
        progress = 0;
        let currentTotal = e._tilesforSave.length;
        setTotal(currentTotal);
        // console.log(e._tilesforSave.length);
        document.getElementById('progress-wrapper').classList.add('show');  
        document.getElementById('progressbar').style.width = '0%';
      });
      tileLayerOffline.on("savetileend", (total) => {
        progress += 1;
        // total
        let currentTotal = total.lengthToBeSaved;
        // console.log(total);
        // console.log(currentTotal);
        setProgress(progress);
        showProgress({progress,currentTotal});
      });
    }
  }, [map]);

  return (
    <div>
    <div className="offlinecard">
      <div>Progress: {progress}</div>
      <div>Total: <span id='storage'>{total}</span></div>
      <div> click <span className="material-icons fa-download" aria-hidden="true" title="Save tiles">download</span> 
      to download current view for offline use</div>
      </div>

      <div id="progress-wrapper" className="collapse pb-2">
    <div className="progress-title">Download Progress</div>
  <div className="d-flex">
    <div className="flex-grow-1 ml-2 my-1">
      <div id="progress" className="progress progress-container">
        <div id="progress-label"></div>
        <div id="progress-total-label"></div>
        <div id="progressbar" className="progress-bar progress-bar-striped progress-bar-animated progress-bar-custom" role="progressbar" style={{width: "0%"}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</div>

      <MapContainer
        style={{ 
    position: "absolute",
    top: "56px",
    left: "0",
    width: "100%",
    height: "calc(100% - 56px)"}}
        center={center}
        zoom={zoom}
        zoomControl={false}
        minZoom={13}
        maxZoom={16}
        scrollWheelZoom={true}
        whenCreated={setMap}
      >
      <ZoomControl position="bottomleft" />
      </MapContainer>
    </div>
  );
};

export default Map;