import React, { useState,useMemo,useEffect} from "react";
import { Navigate } from 'react-router-dom';
import Joi from '@hapi/joi';
import axios from "axios";

import DataService, {getStaticData,getSynced} from "../services/data.service";
// import UserService from "../services/user.service";
// import authHeader from '../services/auth-header';

import { MapContainer, TileLayer, ZoomControl,useMap,useMapEvent} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import "leaflet.offline";
import "bootstrap/dist/css/bootstrap.css";
import AuthService from "../services/auth.service";
import MapService from "../services/map.service";
import {useLocalStorage} from "../services/store.service";
import { Alert, Card, Button, CardTitle, CardText, InputGroup, Input ,ListGroup, Spinner, Collapse, Modal, ModalBody, FormGroup, Label,Col} from 'reactstrap';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../symptoms.css";
import "../diseases.css";

// import LogoNodejs from 'react-ionicons/lib/LogoNodejs'

import "leaflet-iconmaterial/dist/leaflet.icon-material.js"
import "leaflet-iconmaterial/dist/leaflet.icon-material.css";

import { Geolocation } from '@capacitor/geolocation';
import * as Constants from '../services/constants';
//import 'material-icons/iconfont/material-icons.css';


// import 'bower_components/src/assets/less/Leaflet.extra-markers.less';

// import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.min';
// import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
// import MdHome from 'react-ionicons/lib/MdHome'
// import LogoNodejs from 'react-ionicons/lib/LogoNodejs'
// import 'semantic-ui-icon/icon.css'
// import { Icon, InlineIcon } from '@iconify/react';
// import iosAddCircleOutline from '@iconify/icons-ion/ios-add-circle-outline';
// import mdPersonAdd from '@iconify/icons-ion/md-person-add';


//address
// import "leaflet-control-geocoder/dist/Control.Geocoder.js";
// import LCG from 'leaflet-control-geocoder';
import 'leaflet-control-geocoder';



delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});






// const currentUser =  AuthService.getCurrentUser();
// const ServerMarkers= DataService.getMarkers();
// const schema = Joi.object({
//   search: Joi.string().allow('').min(0).max(500).required()
// });

// const markerSchema = Joi.object({
//   longitude: Joi.number().min(-90).max(90).required(),
//   latitude: Joi.number().min(-180).max(180).required(),
// });

// const startDateSchema = Joi.object({
//   startDate: Joi.date().required()
//   // Date : Joi.date().required()
// });

const endDateSchema = Joi.object({
  endDate: Joi.date().required()
});

//static
// let currentUser =  AuthService.getCurrentUser();


// const center = (currentUser && currentUser.person && currentUser.person.home) ? JSON.parse(currentUser.person.home) : [51.505, -0.09]
const zoom = 13

// const staticData = DataService.getStaticData();
const [staticData,outdated] = getStaticData();
// const synced = getSynced();

// const storedMarkers = getStored();


//const snap = getSnap(); //getSnap();
const snap = true;
// const test = DataService.getTest();
// console.log()
// console.log(DataService.getStaticData());
const diseases = staticData.diseases;
const symptoms = staticData.symptoms;

// const defaulttab = 1;

const roundDownTo = roundTo => x => Math.floor(x / roundTo) * roundTo;
const roundDownTo15Minutes = roundDownTo(1000 * 60 * 15);

const now = new Date();

var markers = {};



// function useStickyState(defaultValue, key) {
//   const [value, setValue] = React.useState(() => {
//     const stickyValue = window.localStorage.getItem(key);
//     return stickyValue !== null
//       ? JSON.parse(stickyValue)
//       : defaultValue;
//   });
//   React.useEffect(() => {
//     window.localStorage.setItem(key, JSON.stringify(value));
//   }, [key, value]);
//   return [value, setValue];
// }


// const position = [51.505, -0.09];



    //      <Marker position={position}>
    //   <Popup>
    //     A pretty CSS3 popup. <br /> Easily customizable.
    //   </Popup>
    // </Marker>

// return Popupsymptoms = Popupsymptoms + key+"<br />";
                    

                  // return (<React.Fragment key={index}>
                  //    <div className='listitem'>{key}</div>
                  //    </React.Fragment>)




  // <LoadSpinner />

 export function saveState(input){
console.log(input);
console.log("setstate: saveState");
this.setState(input);
// return true
  }

      // <button onClick={onClick}>reset</button>
const datetimeToSQL = (date) => {
  let jsdate = new Date(date);
  let dd = jsdate.getDate();
  let mm = jsdate.getMonth() + 1; // month start at 0, we have to add 1.
  let yyyy = jsdate.getUTCFullYear();
  let hh = jsdate.getHours();
  let mi = jsdate.getMinutes();

  let sqlString = `${yyyy}/${mm}/${dd} ${hh}:${mi}`;
  return sqlString;
}


function diseaseAdd(e,setdiseaseSelect) {
  // console.log(e.target.value);
  // console.log(e.target.options.selectedIndex);
  // this.setState({diseaseDate:true});
  let idtarget = e.target.value;
  // let idkey = e.target.options.selectedIndex

  let key = document.getElementById(idtarget).getAttribute("data-keyid");
  // console.log(key);
  if (idtarget === 'None') {
    console.log("setstate: diseaseAdd: none");
    // this.setState({
    //         diseaseDate:false,
    //       });
  } else {

    // console.log("setstate: diseaseAdd");
console.log({name: idtarget, diseaseSelectedId:key});
    setdiseaseSelect({name: idtarget, diseaseSelectedId:key})
  // console.log(this.state.diseaseSelected);
    // this.setState({
    //         diseaseDate:true,
    //         diseaseSelected: [idtarget],
    //         diseaseSelectedId: idkey
    //       }); 
    }
}

// const diseaseAddButton = (e) => {
//   e.preventDefault();
//   // let test = this.refs.key.getValue();
//   // console.log(test);
//   let selected = this.state.diseaseSelected;
//   let selectedIndex = this.state.diseaseSelectedId
//   console.log(selected);
//       //get temp vars and add them to list
//   if (this.state.newData.diseases !== undefined) {
//     // let diseaseselected = this.state.newData[selected];
//     // console.log(diseaseselected);
//     if (this.state.newData.diseases[selected] === undefined) {
//       console.log("setstate: diseaseAddButton: update");
//       this.setState(prevState => ({
//       diseaseDate:false,
//       newData: {
//         ...prevState.newData,
//         diseases: {
//           ...prevState.newData.diseases,           // copy all other key-value pairs of disease object
//           [selected]: {startDate: this.state.diseaseStartDate,index: selectedIndex}
//         }
//       }
//     }));

//     } else {console.log("already added disease")} 
//   } else {
//     //first disease
//     console.log("setstate: diseaseAddButton: new");
//       this.setState(prevState => ({
//       diseaseDate:false,
//       newData: {
//         ...prevState.newData,
//         diseases: {
//           ...prevState.newData.diseases,           // copy all other key-value pairs of disease object
//           [selected]: {startDate: this.state.diseaseStartDate,index: selectedIndex}
//         }
//       }
//     }));    
//   }
// }


  const setHome = (c) => {
    //get person_id
    // console.log("setstate: serverUpdateHome");
        // const [userDetails, setUserDetails] = useState(currentUser);

        // React.useEffect(() => {
        // localStorage.setItem(localStorageKey, value);
        //   }, [value]);
// c.api.userDetails, c.api.setuserDetails
    // this.setState({isLoading: true});
    let personId=c.api.userDetails.id;
    let query = 'setHome';
    // console.log(currentUser);
    let home=markers.click.getLatLng();
    // let hometxt = [home.lat,home.lng].toString();
    let hometxt = "["+home.lat+","+home.lng+"]";
    let data = {person_id:personId,home:[home.lat,home.lng]}
    // let data = null;
    console.log(data);
     // return 
       axios.post(Constants.API_URL + query, data, Constants.fetchOptions)
      .then(response => {
        // if (response) response=response.json();
        console.log(response);
        if (response && response.status === 200) {
          // console.log(response);
          // console.log("setstate: serverUpdateHome ok");
          // this.setState({ fetchUrl: {home: response.data.home}});
           
           // <Marker position={JSON.parse(currentUser.person.home)} key="disMark_home_0" icon={returnIcon("home")}>
           //          <Popup key="home_0"><div>
           //          Your home</div>
           //          </Popup>
           //          </Marker>

             markers['home']=L.marker(home,{id: 'home',draggable: false,riseOnHover:true, icon:MapService.returnIcon("home"), position:home}).addTo(c.api.map)
            .bindPopup("Your home").openPopup();
            // markers.home.on(eventHandlers);


           // console.log(hometxt);
           //update userdetails for refresh
           let newCurrentUser ={...c.api.userDetails, person: {...c.api.userDetails.person, home: hometxt}} 
           c.api.setuserDetails(newCurrentUser)
           //change marker to home

                    //        <Marker position={JSON.parse(currentUser.person.home)} key="disMark_home_0" icon={returnIcon("home")}>
                    // <Popup key="home_0"><div>
                    // Your home</div>
                    // </Popup>
                    // </Marker>
                    // );
                    //disable homeselection
                    c.api.sethomeSelected(hometxt);
                    c.api.setnewHome(false);
            console.log(c.api.userDetails);
          
           console.log("ok next tab");
           console.log("change tab to 2 setuserdetails");
           //change tab
           c.api.setTab(2);

          return response;
        } else {console.log(response);
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        console.log("update user")
        // set
      }
        // this.setState(prevState => ({
            // isLoading: false,
          //   currentUser: {
          //   ...prevState.currentUser,           // copy all other key-value pairs of currentUser object
          //   person: {                           // specific object of currentUser object
          //     ...prevState.currentUser.person,  // copy all person key-value pairs
          //     home: data.home                   // update value of specific key
          //     }
          //   }
          // }), this.updateLocalStorage)
        // })
      //)
        // this.setState({ fetchUrl: {home: data.home, isLoading: false }})
        )
      .catch(error => {console.error(error)}
        // this.setState({ isLoading: false, 
        //                               fetchUrl: {error, isLoading: false }})
        );
     

            // 'https://hablarjuntos.com:5088/users/setHome?person_id='+person_id+'&home='+home
  }


// const symptomEnd = (item,itemId,newData,setnewData, startDate, symptomEndDate,setsymptomEndDate,modal,toggle) => {
// console.log("ask if symptoms stopped");

// // console.log("setstate: symptomEnd");
// // toggle();
// // return true
// // return (<div>

// //     <Button color="danger" onClick={toggle}>X</Button>
// //       <Modal isOpen={modal} toggle={toggle}>
// //         <ModalHeader toggle={toggle}>Modal title</ModalHeader>
// //         <ModalBody>

// //   <span><br />Symptom: <b>{item}</b></span><br />
// //     Date and time you stopped having this symptom.<br />
// //   {newData}
// //     <DatePicker className="softinput"
// //               selected={symptomEndDate}
// //               onChange={date => setsymptomEndDate(date)}
// //               endDate={symptomEndDate}
// //               minDate={symptomStartDate}
// //               maxDate={new Date()}
// //               showTimeSelect
// //               timeFormat="HH:mm"
// //               timeIntervals={15}
// //               timeCaption="time"
// //               placeholderText="click here"
// //               dateFormat="MMMM d, yyyy h:mm aa"
// //               />
// //               </ModalBody>
// //     <ModalFooter>
// //           <Button color="primary" onClick={null} disabled={!symptomEndDate}>remove</Button>{' '}
// //           <Button color="secondary" onClick={null}>Cancel</Button>
// //         </ModalFooter>
// //         </Modal>
// //         </div>);

// // this.symptomUpdatebutton

// } 

// const Switch = ({ isOn, handleToggle, onColor }) => {
//   return (
//     <>
//       <input
//         checked={isOn}
//         onChange={handleToggle}
//         className="react-switch-checkbox"
//         id={`react-switch-new`}
//         type="checkbox"
//       />
//       <label
//         style={{ background: isOn && onColor }}
//         className="react-switch-label"
//         htmlFor={`react-switch-new`}
//       >
//         <span className={`react-switch-button`} />
//       </label>
//     </>
//   );
// };

 const symptomUpdatebutton  = (e,symId,symptomDate,sync,newData,setnewData) => {
  // e.stopPropagation();
  // console.log(this.state.endSymptomId);
  // let symptomId=  this.state.endSymptomId
  // console.log(this.state.symptomendDate);
  // let symptomDate = this.state.symptomendDate
  let query = 'updateSymptom';
  // let symId = this.state.endSymptomId;
  // console.log(symId);
  console.log(sync.synced);
console.log(symptomDate);
console.log(symId);


    let id= sync.synced.symptoms[symId].id;

    // console.log(id.id);

    // console.log(id)
// query, API_URL,id, symptomDate,fetchOptions
// let returnData = DataService.postUpdateSymptom(query, Constants.API_URL, id, symptomDate, Constants.fetchOptions);


DataService.postUpdateTodate(query, Constants.API_URL, id, symptomDate, Constants.fetchOptions, (response) => {
if (response) {
  console.log("symptomUpdatebutton:"); console.log(symId); console.log(id); console.log(symptomDate); console.log(response);

//   fromdate: "2021-03-19T15:01:00.000Z"
// id: 93
// todate: null

const newsynced = {
    ...sync.synced,
    symptoms: {
      ...sync.synced.symptoms,
      [symId] : {
        ...sync.synced.symptoms[symId],
        todate:symptomDate
      }
  }}


//update usestate
  sync.setsynced(newsynced)
console.log(newData);

setnewData({
      ...newData, 
      symptoms: {
        ...newData.symptoms,
        [symId] : {
          ...newData.symptoms[symId],          
          end: symptomDate,
          active: false,
        }
      }
     })

  //update localstorage


          let newtosynced = JSON.stringify(newsynced);
          localStorage.setItem("synced", newtosynced);
console.log("updated localStorage synced");
console.log(newtosynced);
}

});
// //done
// this.symptomUpdateCloseModal();

}





const symptomButton= (idtarget,i, newData,setnewData, startDate, symptomEndDate,setsymptomEndDate,modal,toggle,c,setsymptomSelect,sync) => {
  // console.log(idtarget.itemName);
  // console.log(i.index);
  //  console.log(i);
  //  console.log(newData);
  //  console.log(synced)
   let itemId=i.index+1;
 // const [symptomEndDate, setsymptomEndDate] = useState(new Date())
// symptomStartDate, symptomEndDate,setsymptomEndDate,
if (sync.synced && sync.synced.symptoms && sync.synced.symptoms[itemId] && sync.synced.symptoms[itemId].todate === null) {
  setsymptomSelect({id:i.index+1,name:idtarget.itemName});
 return  c.setTab(41);

  // return symptomEnd(idtarget,itemId,newData,setnewData, symptomStartDate, symptomEndDate,setsymptomEndDate,modal,toggle);
}

if (typeof newData.symptoms[idtarget.itemName] === 'undefined' || !newData.symptoms[idtarget.itemName].active) {
    //new disease
    // console.log("new:" + idtarget.itemName);
     // console.log(typeof this.state.newData.symptoms[idtarget.itemName]);
     console.log("setstate: symptomButton: new disease");
     console.log(startDate);
     console.log(idtarget.itemName);
     console.log(i.index+1);

     setnewData({
      ...newData, 
      symptoms: {
        ...newData.symptoms,
        [idtarget.itemName] : {
          start: datetimeToSQL(startDate),
          end: "",
          active: true,
          index: i.index+1
        }
      }
     })


} else {
    // console.log("old:" + idtarget.itemName);

  //end disease
  // clear disease
  console.log("setstate: symptomButton: clear disease");


       setnewData({
      ...newData, 
      symptoms: {
        ...newData.symptoms,
        [idtarget.itemName] : {
          start: datetimeToSQL(newData.symptoms[idtarget.itemName].start),
          end: "",
          active: false,
          index: i.index+1
        }
      }
     })

  // this.setState(prevState => ({
  //         newData: {
  //           ...prevState.newData,
  //           symptoms: {
  //           ...prevState.newData.symptoms,           // copy all other key-value pairs of mySymptoms object
  //           [idtarget.itemName]: {
    //                        start: this.datetimeToSQL(prevState.newData.symptoms[idtarget.itemName].start),
  //                          end: "" ,
  //                          active: false,
  //                          index: i.index+1
  //           } //idtarget
  //         } //symptoms
  //         } //newData

  // }));


}
}

function renderSymptoms(newData,setnewData,symptomSelect,setsymptomSelect, startDate, setstartDate, symptomEndDate,setsymptomEndDate,modal,toggle,sync,c) {
  console.log(symptoms);
return (
                  <div className="symptoms">
                  {Object.keys(symptoms).map((key, index)  => {
                //console.log(key, this.state.newData.symptoms[key]);               
                var item = symptoms[key];
                if (!item || !item.name) {return "<div>something went wrong please report to crash@hablarjuntos.com</div>"}
                var itemName = item.name.replace(/ /g,"_");
                var itemId = item.symptom_id;
                var isSelected = symptomActive(itemName,itemId,newData,setnewData,sync) ? "iconsSelected": "disabledIcon";
                  // console.log(isSelected);
                  return (
                  <span key={index}>
                  <Button id={index + itemName} type="button"  onClick={() => symptomButton({itemName},{index},newData,setnewData, startDate, symptomEndDate,setsymptomEndDate,modal,toggle,c,setsymptomSelect,sync)} className={"symptoms-icons "+ isSelected} color="">
                  <span className={"symptom_"+itemName+" symptom_icons"}></span></Button>
                  </span>
                    )
                    
            })}       
                </div>
)
}

// diseaseSelect, setdiseaseSelect,newData,setnewData,startDate, synced, setsynced, diseaseRMButton
function renderDiseases(diseaseSelect, setdiseaseSelect,diseaseStartDate, setdiseaseStartDate, newData,setnewData, customDate,setcustomDate, startDate, sync, diseaseRMButton) {

  // const [customDate,setcustomDate] = useState();

  // console.log(diseaseDate);
  // console.log(this.state.newData.diseases);
   // let options = {
   //            year: 'numeric', month: 'numeric', day: 'numeric',
   //            hour: 'numeric', minute: 'numeric', second: 'numeric',
   //            hour12: false,
   //          };
  return (
    <div className="disease inputRow">
       <FormGroup row>
           <Label for="diseaseSelect" className="toplabelSm">Disease</Label>
        <div className="container">
        <div className="d-table-cell w-100">
          <Input type="select" name="select" selected={diseaseSelect} onChange={e => diseaseAdd(e,setdiseaseSelect)} id="diseaseSelect">
            <option>None</option>
            {Object.keys(diseases).map((key, index)  => {
              let item=index+1;
              var itemName = diseases[index].name;
              var itemId= diseases[index].disease_id;
              console.log(itemName);
              console.log(index);
              // console.log("newData:");
              // console.log(newData.diseases[itemName]);
              if (newData.diseases[itemName]) return "";
              if (sync.synced.diseases && sync.synced.diseases[item]) {console.log("already synced:"+itemName);return "";}
              return (
              <option id={itemName} key={index} data-keyid={itemId}>{itemName}</option>
                    )
              })}
          </Input>
        </div>
       {/*    setnewData({
      ...newData, 
      symptoms: {
        ...newData.symptoms,
        [idtarget.itemName] : {
          start: datetimeToSQL(startDate),
          end: "",
          active: true,
          index: i.index+1
        }
      }
     })*/}{/*

     {customDate? <div>
            change {customDate.key} :
<DatePicker className="softinput"
              selected={customDate.date}
              onChange={(date) => setdiseaseStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy h:mm aa"
              /> 
            <Button type="button" onClick={null} >change</Button>

              </div>
              : ""}*/}

            <div className="d-table-cell align-middle">
            <Button type="button" onClick={() => {setnewData({...newData, diseases:{ ...newData.diseases, [diseaseSelect.name]: {start: datetimeToSQL(startDate), index: diseaseSelect.diseaseSelectedId}}})}}>Add</Button>
            </div>
          </div>
        
      </FormGroup>
      

        {diseaseSelect? <div>          
         {/* <Button type="button" onClick={() => setcustomDate(!customDate)} >other start date:</Button>*/}
           {/*(e) => {setcustomDate({e:e, key:key, date:itemDate})}*/}

              </div>
              : ""}


               {Object.keys(newData.diseases).map((key, index)  => {
                  if (newData.diseases[key] !== undefined) {
                    // console.log(newData.diseases[key]);
                    // new Intl.DateTimeFormat('en-GB',options).format(newData.diseases[key].start);  
                    var itemDate = newData.diseases[key].start
                    return (<div key={index} className="diseasesAdded" onClick={() => {delete newData.diseases[key];setnewData({...newData,diseases:{...newData.diseases}})}}>{key}: {itemDate}                       
                      {/*setnewData({...newData,diseases:{...newData.diseases, [index.key]:undefined}})*/}
                      {/*<Button close color="danger" onClick={e => diseaseRMButton(e,{key},newData,setnewData)}/>*/}
{/*diseaseRMButton(e,{key},newData,setnewData)*/}
                      <Button close color="danger" />
                      </div>)
                  } else {return ""}
                          })}
               <p className="note">note: if you want to remove a disease click on the disease on the 'User' page!</p>
{/*               <p className="note">note: if you want another date for a disease add it on the 'User' page!</p>*/}
                </div>
              
                 )

                    // return (<div key={index} className="diseasesAdded" onClick={() => {setnewData({...newData,diseases:{...newData.diseases, [key]:undefined}})}}>{key}: {itemDate} 


}


// e => setnewData({[diseaseSelect]: {startDate: diseaseStartDate,index: e.target.options.selectedIndex}})

function renderTabbar(getTab) {
     switch (getTab) {
      case 0: return <div className="verticalbar"><span className="material-icons inactivetab">place</span><span className="material-icons inactivetab">repeat</span><span className="material-icons inactivetab">date_range</span><span className="material-icons inactivetab">sentiment_very_dissatisfied</span><span className="material-icons inactivetab">local_hospital</span></div>;
      case 1: return <div className="verticalbar"><span className="material-icons activetab">place</span><span className="material-icons inactivetab">repeat</span><span className="material-icons inactivetab">date_range</span><span className="material-icons inactivetab">sentiment_very_dissatisfied</span><span className="material-icons inactivetab">local_hospital</span></div>;
      case 2: return <div className="verticalbar"><span className="material-icons inactivetab">place</span><span className="material-icons activetab">repeat</span><span className="material-icons inactivetab">date_range</span><span className="material-icons inactivetab">sentiment_very_dissatisfied</span><span className="material-icons inactivetab">local_hospital</span></div>;
      case 3: return <div className="verticalbar"><span className="material-icons inactivetab">place</span><span className="material-icons inactivetab">repeat</span><span className="material-icons activetab">date_range</span><span className="material-icons inactivetab">sentiment_very_dissatisfied</span><span className="material-icons inactivetab">local_hospital</span></div>;
      case 4:
      case 40:
      case 41: 
      case 42: return <div className="verticalbar"><span className="material-icons inactivetab">place</span><span className="material-icons inactivetab">repeat</span><span className="material-icons inactivetab">date_range</span><span className="material-icons activetab">sentiment_very_dissatisfied</span><span className="material-icons inactivetab">local_hospital</span></div>;
      case 5: 
      case 50: 
      case 51:
      case 52: return <div className="verticalbar"><span className="material-icons inactivetab">place</span><span className="material-icons inactivetab">repeat</span><span className="material-icons inactivetab">date_range</span><span className="material-icons inactivetab">sentiment_very_dissatisfied</span><span className="material-icons activetab">local_hospital</span></div>;
      default: return "<div></div>"
     }
}


// const handleDateChange = (date) => {
//   console.log(date);
//   // if (!this.state.userStartDate) {
//   //   console.log("setstate: handleDateChange startdate");
//   //  this.setState({
//   //     startDate: date
//   //   });
//   // } else {
//   //   console.log("setstate: handleDateChange enddate");
//   //   this.setState({
//   //     endDate: date
//   //   });
//   // }
//   };

// function LocationMarker() {
//     const [position, setPosition] = useState(null);
//     const [bbox, setBbox] = useState([]);

//     const map = useMap();

//     useEffect(() => {
//       map.locate().on("locationfound", function (e) {
//         setPosition(e.latlng);
//         map.flyTo(e.latlng, map.getZoom());
//         const radius = e.accuracy;
//         const circle = L.circle(e.latlng, radius);
//         circle.addTo(map);
//         setBbox(e.bounds.toBBoxString().split(","));
//       });
//     }, [map]);

//     return position === null ? null : (
//       <Marker position={position} id='gps' icon={Constants.leafleticon}>
//         <Popup>
//           You are here. <br />
//           Map bbox: <br />
//           <b>Southwest lng</b>: {bbox[0]} <br />
//           <b>Southwest lat</b>: {bbox[1]} <br />
//           <b>Northeast lng</b>: {bbox[2]} <br />
//           <b>Northeast lat</b>: {bbox[3]}
//         </Popup>
//       </Marker>
//     );
//   }


       // <ZoomControl position="bottomleft" />
       //  <LocationMarkers />
       //  <MarkerList />
       //  <ONMapClick />
       //    <GPSButton  />
// <LocationMarker />
// Home Address {this.renderTabbar()}

      //          <Form onSubmit={this.formSubmit}>
      //           <InputGroup>
      //   <Input onChange={this.valueChange} type="text" name="search" id="search" placeholder={this.state.searchInfo} value={this.state.searchValue}/>
      //   <InputGroupAddon addonType="append">
      //       <Button className="searchButton" type="button" onClick={this.searchButton} disabled={this.state.SearchDisabled} color="secondary">{this.state.searchWorking ? <div><Spinner color="danger" size="sm"
      // role="status" as="span"/>Search</ div>: '  Search'}</Button>
      //       </InputGroupAddon>
      //   </InputGroup>
      //       <div>
      //       <ListGroup>
      //      {this.state.searchChoices.map((chs,index) => <ListGroupItem tag="button" onClick={() => this.pickChoice(chs.center.lat, chs.center.lng)} action key={index}>{chs.properties.street} {chs.properties.housenumber}, {chs.properties.city},{chs.properties.state}, {chs.properties.country}</ListGroupItem>)}
      //       </ListGroup>
      //       </div>
          
      //   <br />
      //     <Button type="submit" disabled={!this.formIsValid()}>Confirm</Button>
      //     </Form>

//   const pickChoice = (lat,lng) => {
// // console.log(lat + " / "+ lng);
// console.log("setstate: pickChoice");
// // this.setState({searchChoices: []});
//       // this.setState({
//       //   marker: {
//       //     lat: lat,
//       //     lng: lng
//       //   },
//       //   center: {
//       //     lat: lat,
//       //     lng: lng
//       //   },
//       //   zoom:20,
//       //   SearchDisabled: true,
//       //   searchResult: {lat:lat, lng:lng}
//       // });
//   }

//   const valueChange = (searchValue,e) => {
//     // console.log("setstate: valueChange");
// console.log("value change:"+searchValue);
// // console.log("value e:"+e.target.value);
//     // this.setState({searchValue: e.target.value});
//     // if (e.target.value) {this.setState({SearchDisabled: false}) } else {
//     //   console.log("setstate: valueChange: SearchDisabled");
//     //   this.setState({SearchDisabled: true})}
//   }





  // const locationIsValid = (point) => {
  //     // console.log(point.length);
  //     // console.log(Array.isArray(point));
  // if (!Array.isArray(point) && point.length !== 2) {console.log("invalid location");return false};
  //   const validLocation = {
  //     longitude: point[0],
  //     latitude: point[1]
  //   };
  //   // schema check
  //   const result = markerSchema.validate(validLocation);
  //   // console.log(result);
  //   // if (!result.error) {}
  //   return !result.error
  //   // return !result.error && this.state.userLocation ? true : false;
  // }

  // const formIsValid = (map) => {
  //   console.log("check valid input");
  //   console.log(markers.click);
  //   if (markers.click ) {return true}
  //   // console.log(this.Position);
  //   // const userSearch = {
  //   //   search: this.state.searchValue
  //   // };
  //   // // schema check
  //   // console.log(userSearch.search);
  //   // if (!userSearch.search) {return false;}
  //   // const result = schema.validate(userSearch);
  //   // console.log(this.state.searchResult);
  //   // return !result.error && this.state.searchResult ? true : false;
  // }

  // const startDateIsValid = (dateobj) => {
  //   // const inputDate = {
  //   //   startDate: this.state.startDate
  //   // };
  //   // console.log(date);
  //   const result = startDateSchema.validate({startDate: dateobj});
  //   console.log(result);
  //   return !result.error ? true : false;
  // }

  const endDateIsValid = (dateobj) => {
    // const inputDate = {
    //   endDate: this.state.endDate
    // };
    // const result = endDateSchema.validate(inputDate);
    // // console.log(this.state.endDate);
    // // console.log(result);
    
    const result = endDateSchema.validate({endDate: dateobj});
    console.log(result);
    return !result.error ? true : false;

    // return !result.error ? true : false;
  }




 // const searchButton = (e) => {
 //    e.preventDefault();
 //    // console.log(this.state.searchValue);
 //    // const geocoder = L.Control.Geocoder.photon({suggestTimeout: 10});
 //    console.log("setstate: searchButton");
 //    // this.setState({searchWorking:true});
 //    console.log("new search / searchButton");

 //    // geocoder.geocode(
 //    //     this.state.searchValue,
 //    //     results => {
 //    //       var r = results[0];
 //    //       if (r) { 
 //    //         console.log(r);
            
 //    //         this.createClickMarker({point: [r.center.lat,r.center.lng], name: "map click", type: "not_listed_location", date: "",time: "", class: "click"});
 //    //         this.state.mapClick.marker.on('dragend', this.updatePosition);
 //    //         this.centerLocation(r.center.lat,r.center.lng);


 //    //         // let markerid = this.onAddMarker({point: point, name: "current position", type: "gps_fixed", date: "",time: ""});
 //    //         // this.centerLocation(position.coords.latitude,position.coords.longitude,markerid)


 //    //         // let point = [this.state.searchResult.lat,this.state.searchResult.lng];
 //    //         // this.onAddMarker({point: point, name: "home", type: "home", date: "",time: ""});

 //    //         // this.setState({searchChoices: results});
 //    //         console.log("setstate: searchButton results");
 //    //         this.setState({
 //    //           searchChoices: results,
 //    //           searchWorking: false,
 //    //           center: {lat: r.center.lat, lng: r.center.lng}
 //    //         });
 //    //         // console.log(results) 
 //    //       }
 //    //     });


 //  }



const symptomActive= (t,itemId,newData,setnewData,sync) => {
  // console.log(this.state.newData.symptoms[t]);
  // if (typeof this.state.newData.symptoms[t] === 'undefined') {return false}
  // console.log(this.state.symptoms);
  // console.log(itemId);
  // console.log(this.state.synced.symptoms[itemId]);
  // console.log("checking symptom:"+t);
  // console.log(itemId);
  // console.log("1st: synced symptoms ");
  // console.log(typeof this.state.synced.symptoms !== 'undefined');
  // console.log("2nd: symptom: " +itemId);
  // console.log(typeof this.state.synced.symptoms[itemId] !== 'undefined');
  // console.log("3rd: todate:");
  // console.log(this.state.synced.symptoms[itemId].todate === null);
  // console.log(this.state.synced.symptoms[itemId].todate);
// console.log(this.state.synced.symptoms);
   if (sync.synced && typeof sync.synced.symptoms !== 'undefined' && typeof sync.synced.symptoms[itemId] !== 'undefined' && sync.synced.symptoms[itemId].todate === null) {
    return sync.synced.symptoms[itemId]
   }
 
 if (!newData.symptoms || typeof newData.symptoms[t] === 'undefined') {
    return false}
  else {
    // return this.state.synced.symptoms[itemId]
    return newData.symptoms[t].active;
  }
}

const listSelectedSymptoms = (newData) => {
  let listmax = Object.keys(newData.symptoms).length;
  if (listmax > 0) {
  return (<div className="SummaryRow"><div className="SummaryType SummaryCell">symptoms:</div><div className="SummaryValue SummaryCell">
    {Object.keys(newData.symptoms).map((key, index)  => {           
                  var item = newData.symptoms[key];
                  if (item && item !== undefined) {
                    let space = listmax > index +1  ? ", " :"";
                    return (key+space)
                   } else {return ""}
                })}
  </div></div>)
  } else {return ""}
}


const listSelectedDiseases = (newData) => {

// console.log(newData);
// console.log(symptomSelect);
  let listmax = Object.keys(newData.diseases).length;
  if (listmax > 0) {
   return (<div className="SummaryRow"><div className="SummaryType SummaryCell">diseases:</div><div className="SummaryValue SummaryCell">
  {Object.keys(newData.diseases).map((key, index)  => {           
                var item = newData.diseases[key];
                if (item && item !== undefined) {
                  let space = listmax > index +1  ? ", " :"";
                  return (key+space)
                 } else {return ""}
              })}
  </div></div>)
  } else {return ""}
}

function CardNumber(c) {

// const [position, setPosition] = useState(c.api.map.getCenter())
const [searchInfo, setsearchInfo] = useState();
const [searchValue, setsearchValue] = useState();
const [SearchDisabled, setsearchDisabled] = useState(false);
// const [searchWorking, setsearchWorking] = useState(false);
const [startDate, setStartDate] = useState(roundDownTo15Minutes(new Date()));
const [endDate, setEndDate] = useState(startDate);
// const nextTab = c.setTab(c.getTab+1);

//disease
const [diseaseSelect, setdiseaseSelect] = useState()
// eslint-disable-next-line
const [diseaseDate, setdiseaseDate] = useState()
const [diseaseStartDate, setdiseaseStartDate] = useState()
const [customDate,setcustomDate] = useState(false)

//symptom
const [symptomSelect, setsymptomSelect] = useState()
// const [symptomStartDate, setsymptomStartDate] = useState()
const [symptomEndDate, setsymptomEndDate] = useState()

const [newData, setnewData] = useState({diseases:{},symptoms:{}});
const [synced, setsynced] = useState(getSynced()||{});
const [dragposition, setDragPosition] = useState(null)
const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);

const [iconSelect, seticonSelect] = useState();
const [weekday,setweekday] = useState({su:false,mo:false,tu:false,we:false,th:false,fr:false,sa:false});

const [isOpen, setIsOpen] = React.useState(true);


const eventHandlers = useMemo(
    () => ({
      dragend() {
        console.log("dragEnd");
        // const marker = markerRef.current
        if (markers.click != null) {
          let coords =markers.click.getLatLng();
          // markers.click.setIcon(greenIcon);
          console.log(coords);
          console.log(c.api.getTab);
          // console.log(dragposition);
          c.api.setSearchWorking(true);
          MapService.realpoint(coords, c.api.map, function(a){
            // markers.click.setPosition(a.center);
            markers.click.setLatLng(a.center);
            markers.click.setPopupContent(a.properties.street +" " +a.properties.housenumber +", " +a.properties.city +", " +a.properties.country || a.name);
            markers.click.openPopup();
            c.api.setSearchWorking(false);
            c.api.map.flyTo(a.center);
              if (!c.api.newHome) c.api.setTab(2);     
       
          })
          
        }
      }
    }),
    [c.api],
  )


function removeOptions(selectElement) {
   if (selectElement && selectElement.options) {
     if (selectElement.innerHTML !== "") {
     selectElement.innerHTML = "";
     }
 }
}

  const searchKeyDown = (e) => {
    if (e.key === 'Enter') {
    searchButton(e)
    }

  }

//search value changed
const onsearchChange = (myid) => e => {
  // console.log(myid);
 var x = document.getElementById(myid);
// console.log(x);
  removeOptions(document.getElementById(myid));

if (e.target.value.length >4 && x) {
  // console.log("looking up:"+e.target.value );
 const geocoder = L.Control.Geocoder.photon({suggestTimeout: 10});
  geocoder.geocode(searchValue,
    results => {
      for(var i= results.length -1; i>=0; i--) {
        // var option = new Option(results[i].name, results[i].latlng);
        var option = document.createElement('option');
         option.value = results[i].name;
      // console.log(option.value);
      x.appendChild(option);
        // c.api.layercontrol.addOverlay(L.marker(results[i].latlng))
      }   
  });
}
  setsearchValue(e.target.value);
}
//search button pressed
const searchButton = e => {
   e.preventDefault();
   const geocoder = L.Control.Geocoder.photon({suggestTimeout: 10});
   c.api.setSearchWorking(true);
  // console.log(e);
  // console.log(searchValue);
  // console.log("new search / searchButton");
  geocoder.geocode(searchValue,
    results => {
      var r = results[0];
      if (r) {
        // console.log(r.center);
        c.api.setSearchWorking(false);
        if (r.properties.type === 'house') {
        // MapService.realpoint(r.center, c.api.map, function(a){
// draggable: true,riseOnHover:true, position:setDragPosition,
//marker already on map
if (markers.click) {
  markers.click.setLatLng(r.center);
    let locationName = r.properties.street +" " +r.properties.housenumber +", " +r.properties.city +", " +r.properties.country || r.name;
            c.api.setSelectedLocation(locationName);
            markers.click.setPopupContent(locationName);
            markers.click.openPopup();
} else {
    //new marker
        markers['click']=L.marker(r.center,{id: 'new', draggable: true,riseOnHover:true, position:setDragPosition, icon:MapService.returnIcon()}).addTo(c.api.map)
            .bindPopup(r.properties.street +" " +r.properties.housenumber +", " +r.properties.city +", " +r.properties.country || r.name).openPopup();
            markers.click.on(eventHandlers);
            c.api.map.flyTo(r.center,18);

}

            if (!c.api.newHome) c.api.setTab(2);     
      } //if house
      else {
        //msg user to add house number
        c.api.setReturnMsg("No house found!");
      }
    }//if r
    })
}
// console.log("home selection:");
// console.log(c.api.homeSelected);
// console.log("CardNumber:");
// console.log(c.api.getTab);

let number = c.api.getTab;
if (c.api.newHome) {
number = 0
}
// useEffect(() => sethomeSelected(!homeSelected), [homeSelected]);

// const diseaseRMButton = (e,index,newData,setnewData) => {
//   console.log("clicked");
//   console.log(index);
//   console.log(newData);
//   // setdiseaseDate(false);
//   // const filterEvenResults = () => setItems(items.filter(x => x % 2))
//   // setnewData(newData.filter((value, i) => i !== index));
//   // setnewData(newData.filter((value, i) => i !== index.key));

//   setnewData({...newData,diseases:{...newData.diseases, [index.key]:undefined}})


//   console.log(e);
//   console.log(newData);
// console.log("setstate: diseaseRMButton");
// // this.setState(prevState => ({
// //       diseaseDate:false,
// //       newData: {
// //         ...prevState.newData,
// //         diseases: {
// //           ...prevState.newData.diseases,           // copy all other key-value pairs of disease object
// //           [index.key]: undefined
// //         }
// //       }
// //     }));
// }
//

// const [newData, setnewData] = useState()


//---


 // <Button type="button" onClick={() => setHome(c)} disabled={!formIsValid(c.map)}>Confirm</Button>
let mapcoords ="";
// console.log(c.getTab);
// const [startDate, setstartDate] = useState();
 switch (number) {
          //no home

          // {this.renderTabbar()}
          case 0:   
          return <div><div className="inputpage cardpopup">
        <Card body >
          <CardTitle>Home Address {renderTabbar(c.api.getTab)}</CardTitle>
          <CardText>Enter address and press search or<br />
          drag pin to your home and press confirm</CardText>          
              
        <InputGroup>
        <Input onChange={onsearchChange("myhomeresults")} onKeyDown={searchKeyDown} type="text" name="search" id="search" placeholder={searchInfo} list="myhomeresults" value={searchValue}/>
        <datalist id="myhomeresults">
        </datalist>
            <Button className="searchButton" type="button" onClick={searchButton} disabled={SearchDisabled} color="secondary">{c.api.searchWorking ? <div><Spinner color="danger" size="sm"
      role="status" as="span"/>Search</ div>: '  Search'}</Button>
        </InputGroup>
        <div className="returnmsg">{c.api.returnMsg}</div>
            <div>
            <ListGroup>
           {/*{this.state.searchChoices.map((chs,index) => <ListGroupItem tag="button" onClick={() => this.pickChoice(chs.center.lat, chs.center.lng)} action key={index}>{chs.properties.street} {chs.properties.housenumber}, {chs.properties.city},{chs.properties.state}, {chs.properties.country}</ListGroupItem>)}*/}


            </ListGroup>
            </div>
          
        <br />
          <Button type="button" onClick={() => setHome(c)} disabled={!c.api.homeSelected}>Confirm</Button>
        </Card>
        </div> 
        </div>;
          //poi start
          case 1: 
          return <div className="updatepoi cardpopup">
        <Card body className="poiinfo">
          <Button
    color="success"
    outline
    onClick={() => {console.log(isOpen);setIsOpen(!isOpen)}}
    style={{width: '35px'}}
  >
    {isOpen?"_":"+"}
  </Button>
          <Collapse isOpen={isOpen}
          onEntered={function noRefCheck(){}}
          onEntering={function noRefCheck(){}}
          onExited={function noRefCheck(){}}
          onExiting={function noRefCheck(){}}
          className="cardMargin">  
 
          <CardTitle>Update points visited {renderTabbar(c.api.getTab)}</CardTitle>
            <CardText>Enter an address and press search or<br />
          drag pin to a location and press confirm</CardText>
          <div>
        <InputGroup>
        <Input onChange={onsearchChange("mysearchresults")} onKeyDown={searchKeyDown} type="text" name="search" id="search" placeholder={searchInfo} list="mysearchresults" value={searchValue}/>
        <datalist id="mysearchresults">
        </datalist>
            <Button type="button" onClick={searchButton} disabled={SearchDisabled} color="secondary">{c.api.searchWorking ? <div><Spinner color="danger" size="sm" role="status" as="span"/>Search</ div>: '  Search'}</Button>
        </InputGroup>
        <div className="returnmsg">{c.api.returnMsg}</div>
            </div>
        </Collapse>
        </Card>
 
        </div>;

      //   case 2:   
      //     return <div><div className="inputpage cardpopup">
      //   <Card body >
      //     <CardTitle>Home Address {renderTabbar(c.api.getTab)}</CardTitle>
      //     <CardText>Enter address and press search or<br />
      //     drag pin to your home and press confirm</CardText>          
              
      //           <InputGroup>
      //   <Input onChange={valueChange} type="text" name="search" id="search" placeholder={searchInfo} value={searchValue}/>
      //   <InputGroupAddon addonType="append">
      //       <Button className="searchButton" type="button" onClick={searchButton} disabled={SearchDisabled} color="secondary">{searchWorking ? <div><Spinner color="danger" size="sm"
      // role="status" as="span"/>Search</ div>: '  Search'}</Button>
      //       </InputGroupAddon>
      //   </InputGroup>
      //       <div>
      //       <ListGroup>
      //      {/*{this.state.searchChoices.map((chs,index) => <ListGroupItem tag="button" onClick={() => this.pickChoice(chs.center.lat, chs.center.lng)} action key={index}>{chs.properties.street} {chs.properties.housenumber}, {chs.properties.city},{chs.properties.state}, {chs.properties.country}</ListGroupItem>)}*/}


      //       </ListGroup>
      //       </div>
          
      //   <br />
      //     <Button type="button" onClick={() => setHome(c)} disabled={!c.api.homeSelected}>Confirm</Button>
      //   </Card>
      //   </div> 
      //   </div>;

          //startdate
          case 2:  
          // console.log(this.state.startDate);
          return  <div className="updatepoi cardpopup"><Card body >
          <CardTitle>New Point</CardTitle>
          <CardText> Did you repeatetly visited this location: </CardText>
              <div className="Summary"> 
              <div className="SummaryRow"><div className="SummaryType SummaryCell">location:</div><div className="SummaryValue SummaryCell">{c.api.selectedLocation}</div></div>
              </div>
        <div>
        {renderTabbar(c.api.getTab)}
         <Alert color="info">
                             <Button type="button" color="info" className="repeatButtonsSpacing" onClick={() => {c.api.setTab(70)}}><span className="material-icons">repeat</span>Yes<br/> Multiple</Button>
                             <Button type="button" color="warning" className="repeatButtonsSpacing" onClick={() => {c.api.setTab(3)}}><span className="material-icons">pin_drop</span>No<br/> Single</Button>
          </Alert>

        <div className="CardButtons">
          <Button type="button" onClick={() => {console.log("settab +1");c.api.setTab(1)}}>Back</Button>
          </div>

{/*<Button type="button" color="primary" onClick={() => c.api.setTab(3)} disabled={!startDateIsValid(startDate)}>Next</Button>*/}

        </div>
        </Card>
        </div>;
          //enddate
          case 3:
          return  <div className="updatepoi cardpopup"><Card body >
            <CardTitle>Date left</CardTitle>
            <CardText>Input date and time you have <b>entered and left</b> this location</CardText>
              <div className="Summary"> 
              <div className="SummaryRow"><div className="SummaryType SummaryCell">location:</div><div className="SummaryValue SummaryCell">{c.api.selectedLocation}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">coordinates:</div><div className="SummaryValue SummaryCell">lat:{markers.click.getLatLng().lat},lng:{markers.click.getLatLng().lng}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">entered:</div><div className="SummaryValue SummaryCell">{new Date(startDate).toLocaleString()}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">left:</div><div className="SummaryValue SummaryCell">{new Date(endDate).toLocaleString()}</div></div>
              </div>

              <div> {renderTabbar(c.api.getTab)}
              <div className="inputRow">
              <Label for="entered" className="toplabel">entered:</Label>
              <FormGroup row>
              <Col sm={9}>
              <DatePicker className="softinput"
              selected={startDate}
              onChange={date => setStartDate(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy HH:mm"
              />
        </Col>
        </FormGroup>
        </div>
              <div className="inputRow">
              <Label for="left" className="toplabel">left:</Label>
              <FormGroup row>
              <Col sm={9}>
              <DatePicker className="softinput"
              selected={endDate}
              onChange={date => setEndDate(date)}
              minDate={startDate}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy HH:mm"
              />
              </Col>
              </FormGroup>
              </div>
              <div className="CardButtons">
              <Button type="button" onClick={() => c.api.setTab(c.api.getTab - 1)}>Back</Button>
              <Button type="button" color="primary" onClick={() => c.api.setTab(40)} disabled={!endDateIsValid(endDate) || endDate < startDate}>Next</Button>
              </div>
            </div>
          </Card></div>;
          //symptoms

          //color={this.symptomActive("sneeze") ? "info":""}
          // case 4:
          // c.api.setTab(40);
            // return true
          case 40:
          return  <div className="updatepoi cardpopup"><Card body >
          <CardTitle>Symptoms</CardTitle>
          <CardText>Did you have any <b>symptoms</b> when at this location?</CardText>
            <div className="Summary">
              <div className="SummaryRow"><div className="SummaryType SummaryCell">location:</div><div className="SummaryValue SummaryCell">{c.api.selectedLocation}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">entered:</div><div className="SummaryValue SummaryCell">{new Date(startDate).toLocaleString()}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">left:</div><div className="SummaryValue SummaryCell">{new Date(endDate).toLocaleString()}</div></div>
            </div>
              <div>
                {renderTabbar(c.api.getTab)} 
                {renderSymptoms(newData, setnewData,symptomSelect,setsymptomSelect, startDate, setStartDate, symptomEndDate,setsymptomEndDate,modal,toggle,{synced,setsynced},c)}
              
      
          </div>
            <div className="CardButtons">
              <Button type="button" onClick={() => c.api.setTab(3)}>Back</Button>
              <Button type="button" color="primary" onClick={() => c.api.setTab(50)}>Next</Button>
            </div>
          </Card>
          </div>;
          case 41: //removal of symptom
          return <div className="updatepoi cardpopup"><Card body >
          <CardTitle>Symptom End {symptomSelect.name}</CardTitle>
                    <CardText>Date and time you stopped having this symptom?</CardText>
                    <div>

    <DatePicker className="softinput"
              selected={symptomEndDate}
              onChange={date => setsymptomEndDate(date)}
              endDate={symptomEndDate}
              minDate={startDate}
              maxDate={new Date()}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              placeholderText="click here"
              dateFormat="MMMM d, yyyy h:mm aa"
              />
          <Button color="primary" onClick={(e) => symptomUpdatebutton(e,symptomSelect.id,symptomEndDate,{synced,setsynced},newData,setnewData)} disabled={!symptomEndDate}>remove</Button>{' '}
          <Button color="secondary" onClick={() => c.api.setTab(c.api.getTab - 1)}>Cancel</Button>
            </div>
          </Card>
          </div>;

          case 42:
              return c.api.setTab(50); //skip 5 (maybe confirmation)
          case 5:
            return c.api.setTab(50); //skip 5
        case 50:
          return  <div className="updatepoi cardpopup">
            <Card body >
            <CardTitle>Disease</CardTitle>
            <CardText>Did you have any <b>new confirmed disease</b> when at this location?</CardText>
                {renderTabbar(c.api.getTab)} 
            <div className="Summary">
              <div className="SummaryRow"><div className="SummaryType SummaryCell">entered:</div><div className="SummaryValue SummaryCell">{new Date(startDate).toLocaleString()}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">left:</div><div className="SummaryValue SummaryCell">{new Date(endDate).toLocaleString()}</div></div>
              {listSelectedSymptoms(newData)}
            </div>
            {/*diseaseSelect, setdiseaseSelect,diseaseStartDate, setdiseaseStartDate, newData,setnewData, customDate,setcustomDate, startDate, synced, setsynced, diseaseRMButton*/}
                            {renderDiseases(diseaseSelect, setdiseaseSelect,diseaseStartDate, setdiseaseStartDate, newData,setnewData, customDate,setcustomDate, startDate, {synced, setsynced})}

              <div>
                    {/*{renderDiseases(diseaseSelect, setdiseaseSelect,newData,setnewData,diseaseDate,setdiseaseStartDate,synced, setsynced, diseaseRMButton)}*/}
              <div className="CardButtons">
              <Button type="button" onClick={() => c.api.setTab(40)}>Back</Button>
              <Button type="button" color="primary" onClick={() => c.api.setTab(6)}>Next</Button>
              </div>
            </div>
          </Card></div>;

           case 6:
            mapcoords = markers.click.getLatLng()||c.api.map.getCenter();
          return  <div className="updatepoi cardpopup"><Card body >
          <CardTitle>Summary of input</CardTitle>
          {/*{diseaseSelect}*/}
          <div className="selectIcon">
          <FormGroup row>
              <Label for="iconSelect" sm={6}>select icon</Label>
        <Col sm={6}>
{/*          <Input type="select" name="select" selected={iconSelect} onChange={e => seticonSelect(e.target.value === "bar" ? "local_bar" : e.target.value)} id="iconSelect">*/}
          <Input type="select" name="select" selected={iconSelect} onChange={e => seticonSelect(e.target.selectedOptions[0].getAttribute('data-icon'))} id="iconSelect">
            <option data-icon="not_listed_location">None</option>
            <option data-icon="shopping_cart">Shopping</option>
            <option data-icon="local_bar">Bar</option>
            <option data-icon="hotel">Hotel</option>
            <option data-icon="home">Home</option>
            <option data-icon="movies">Movies</option>
            <option data-icon="restaurant">Restaurant</option>
            <option data-icon="school">School</option>
            <option data-icon="subway">Subway</option>
            <option data-icon="train">Train</option>
            <option data-icon="work">Work</option>
          </Input>
        </Col>
        </FormGroup>
        </div>
         <div className="Summary">
        <b>icon:</b>
        {iconSelect ? <div className='material-icons'>{iconSelect}</div> : <div className='material-icons'>not_listed_location</div>}<br/>
            <div className="listitem"><span className='strong'>location</span><span className="listitem">{c.api.selectedLocation}</span></div>
            <div className='listitem'><span className='strong'>lat:</span> <span className='listitem'>{mapcoords.lat.toFixed(4)}</span></div>
            <div className='listitem'><span className='strong'>lng:</span> <span className='listitem'>{mapcoords.lng.toFixed(4)}</span></div>                                     
            <div className="SummaryRow"><div className="SummaryType SummaryCell">entered:</div><div className="SummaryValue SummaryCell">{new Date(startDate).toLocaleString()}</div></div>
            <div className="SummaryRow"><div className="SummaryType SummaryCell">left:</div><div className="SummaryValue SummaryCell">{new Date(endDate).toLocaleString()}</div></div>
              {listSelectedSymptoms(newData)}
              {listSelectedDiseases(newData)}
              {console.log(newData)}
            </div>

          <div className="CardButtons">
              <Button type="button" onClick={() => c.api.setTab(50)}>Back</Button>
              {/*updateObject,synced,setsynced,setTab,map*/}
              <Button type="button" color="primary" onClick={() => serverUpdate({start: datetimeToSQL(startDate),end: datetimeToSQL(endDate), point: markers.click.getLatLng()||c.api.map.getCenter(), icon: iconSelect, symptoms: newData.symptoms, diseases: newData.diseases},{synced,setsynced},c.api.setTab,c.api.map,setnewData,setdiseaseSelect,setsymptomSelect)}>Save</Button>
          </div>
          </Card></div>;
          case 70:
           mapcoords = markers.click.getLatLng()||c.api.map.getCenter();
          return  <div className="updatepoi cardpopup"><Card body >
          <CardTitle>Set new repeatable location</CardTitle>
          {/*{diseaseSelect}*/}
          <div className="selectIcon">
          <FormGroup row>
              <Label for="iconSelect" sm={6}>select icon</Label>
        <Col sm={6}>
          <Input type="select" name="select" selected={iconSelect} onChange={e => seticonSelect(e.target.selectedOptions[0].getAttribute('data-icon'))} id="iconSelect">
            <option data-icon="not_listed_location">None</option>
            <option data-icon="shopping_cart">Shopping</option>
            <option data-icon="local_bar">Bar</option>
            <option data-icon="hotel">Hotel</option>
            <option data-icon="home">Home</option>
            <option data-icon="movies">Movies</option>
            <option data-icon="restaurant">Restaurant</option>
            <option data-icon="school">School</option>
            <option data-icon="subway">Subway</option>
            <option data-icon="train">Train</option>
            <option data-icon="work">Work</option>
          </Input>
        </Col>
        </FormGroup>
        </div>
              <FormGroup check>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.su} onChange={day => {console.log(day.target.checked);setweekday({...weekday,su:day.target.checked})}} type="checkbox" />{' '}
          <span className="checkmark">Su</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.mo} onChange={day => setweekday({...weekday,mo:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">Mo</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.tu} onChange={day => setweekday({...weekday,tu:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">Tu</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.we} onChange={day => setweekday({...weekday,we:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">We</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.th} onChange={day => setweekday({...weekday,th:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">Th</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.fr} onChange={day => setweekday({...weekday,fr:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">Fr</span>
        </Label>
        <Label className="weekday" check>
          <Input className="weekday-input" checked={weekday.sa} onChange={day => setweekday({...weekday,sa:day.target.checked})} type="checkbox" />{' '}
          <span className="checkmark">Sa</span>
        </Label>
      </FormGroup>
      <div className="input-datepicker">
      <FormGroup row>
        <Col sm={3}>
        From:
        </Col>
        <Col sm={4}>
            <DatePicker className="softinput"
              selected={startDate}
              onChange={date => setStartDate(date)}
              endDate={startDate}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="HH:mm"
              timeCaption="time"
              placeholderText="click here"
              dateFormat="HH:mm"
              />
          </Col>
        </FormGroup>
        <FormGroup row>
        <Col sm={3}>
        Till:
        </Col>
        <Col sm={4}>
              <DatePicker className="softinput"
              selected={endDate}
              onChange={date => setEndDate(date)}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeFormat="HH:mm"
              timeCaption="time"
              placeholderText="click here"
              dateFormat="HH:mm"
              />
        </Col>
        </FormGroup>
         </div>
         <div className="Summary">
        <b>icon:</b>
        {iconSelect ? <div className='material-icons'>{iconSelect}</div> : <div className='material-icons'>not_listed_location</div>}<br/>
            <div className="listitem"><span className='strong'>location</span><span className="listitem">{c.api.selectedLocation}</span></div>
            <div className='listitem'><span className='strong'>lat:</span> <span className='listitem'>{mapcoords.lat.toFixed(4)}</span></div>
            <div className='listitem'><span className='strong'>lng:</span> <span className='listitem'>{mapcoords.lng.toFixed(4)}</span></div>                  
              <div className="SummaryRow"><div className="SummaryType SummaryCell">entered:</div><div className="SummaryValue SummaryCell">{new Date(startDate).toLocaleString()}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">left:</div><div className="SummaryValue SummaryCell">{new Date(endDate).toLocaleString()}</div></div>
              <div className="SummaryRow"><div className="SummaryType SummaryCell">days:</div><div className="SummaryValue SummaryCell">{weekday.su?"sunday":""} {weekday.mo?"monday":""} {weekday.tu?"tuesday":""} {weekday.we?"wednesday":""} {weekday.th?"thursday":""} {weekday.fr?"friday":""} {weekday.sa?"saterday":""}</div></div>
            </div>

          <div className="CardButtons">
              <Button type="button" onClick={() => c.api.setTab(2)}>Back</Button>
              <Button type="button" color="primary" disabled={new Date(endDate).getTime() < new Date(startDate).getTime() || !Object.values(weekday).includes(true) } onClick={() => serverScheduled({start: datetimeToSQL(startDate),end: datetimeToSQL(endDate), point: mapcoords, icon: iconSelect, days:weekday},setweekday,setStartDate,setEndDate,synced,setsynced,c.api.setTab,c.api.map)}>Save</Button>
          </div>
          </Card></div>;
          default:
          return <div>Something unexpected happend</div>;
        }

}



function DisplayCard(use) {
  
  return (
    <div>
        <CardNumber api={use}/>
          </div>
        
    )

  
  
}




function App () {
 const [userDetails, setuserDetails] = useLocalStorage("user", AuthService.getCurrentUser());

useEffect(() => {
  if (!userDetails) {
    alert("not logged in:");
    return <Navigate to="/login" />;
  }
 },[userDetails]);


console.log("Start mypoi - App");
 const [modal, setModal] = useState(false);
 const toggle = () => setModal(!modal);


  if (outdated && userDetails) {
        console.log("updating staticData");
      let response= DataService.updateServerData();
      console.log(response);
    }




// console.log(synced);
 // console.log(currentUser);
 // console.log(currentUser);
 // console.log(AuthService.getCurrentUser());

 // if (!currentUser) {currentUser =  AuthService.getCurrentUser();}
 
 // console.log(currentUser);
// const [myhome, setMyhome] = useState(false)





const [newHome, setnewHome] = useState(userDetails.person.home? false : true);
const [homeSelected, sethomeSelected] = useState(userDetails.person.home);
// console.log(homeSelected);
// let inittab
// inittab= inittab ? inittab: defaulttab;
// console.log("inittab");
// console.log(inittab);
// inittab
const [getTab, setTab] = useState(newHome ?0 : 1);
    // const [position, setPosition] = useState(null)
// console.log(getTab);



// console.log(synced);



// , setgetTab, currentUser.person.home

//  const [userDetails, setUserDetails] = AuthService.getCurrentUser();
// useEffect(() => {
//   sessionStorage.setItem('user', JSON.stringify(userDetails));
// }, [userDetails]);

// const increment = () => setgetTab(getTab+1);
// useEffect(() => { setgetTab(getTab) }, [])

 const [map, setMap] = useState(null);


//TODO
 //download current markers
 // only download after x minutes.
 // eslint-disable-next-line
 const [list,setList] = useState(DataService.getMyMarkers()||{});
// eslint-disable-next-line
 const [repeatlist,setrepeatList] = useState(DataService.getRepeatMarkers()||{});
// eslint-disable-next-line
 const [layercontrol,setlayercontrol] =useState(new L.control.layers(null,null, { collapsed: true }).setPosition("topleft"))
// eslint-disable-next-line
 const [searchWorking, setSearchWorking] = useState(false);
 const [selectedLocation, setSelectedLocation] = useState(false);
 const [returnMsg, setReturnMsg] = useState("");




//check if user has home else change tab to 0
  useEffect(() => {
    if (userDetails && !userDetails.person.home) {
        setnewHome(true);
              console.log("setTab change 0 useEffect <- home");
      setTab(0)
    } 
    // else {sethomeSelected(false)}
  },[userDetails]);


// useEffect(() => {
// console.warn("got new update on location");
// console.log(selectedLocation);
// },[selectedLocation])

   useEffect(() => {
    // code to run on component mount
        // <RenderMarkers getMarkers={getMarkers}
                       // setMarkers={setMarkers}
                       // map={map}
                       // />
     if (map) {
       // console.log(map);
        //map.on('locationfound', (e) => {onLocationFound(e,map,setPosition,14,false)});
        map.locate();
       // setlayercontrol(L.control.layers(null, null,options).setPosition("topleft").addTo(map));
       //add offline map
       if (!L.tileLayer.offline) {
       const tileLayerOffline = L.tileLayer.offline("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          subdomains: "abc",
          minZoom: 13,
          maxZoom: 16
        }
      );
      tileLayerOffline.addTo(map);
 // layer switcher control
  layercontrol.addOverlay(tileLayerOffline,'<span class="maingroup"><span class="material-icons Offline"><span class="material-icons">offline_bolt</span></span>Offline</span></span>',true).addTo(map);

       }

      // const layerswitcher = L.control
      // .layers({
      //   '<span class="itemsgroupOfflineRadio">offline</span>': tileLayerOffline,
      //     }, null, { position: 'topleft',collapsed: true })
      // .addTo(map);
      // //end offline


//test
  //       map.on("baselayerchange", e => {
  //         console.log(e);
  //         console.log(e.name);
  //       });
  //       map.on("overlayadd", e => {
  //         console.log("overlayadd");
  //         console.log(e);
  //         console.log(e.name);

  // //          if (checked && this.props.leaflet.map != null) {
  // //  this.props.leaflet.map.addLayer(layer)
  // // }
  //       }); 
  //      map.on("overlayremove", e => {
  //         console.log("overlayremove");
  //         console.log(e.name);
  //         console.log(e);
  //         // let 
  //         // e.name()
  //         console.log(e);
  //       }); 



     }
  
  }, [map,layercontrol])

    // const diseaseLayer = L.layerGroup().addTo(map);
    // const symptomsLayer = L.layerGroup().addTo(map);

//repeate Markers
useEffect(() => {
  // getData(someParam).then(data => setState(data))
  // console.warn("useEFFECT map repeatlist");
  // genMarkers(list,diseaseLayer,symptomsLayer,map)
  let mapstatus = map?"ready":"waiting";
  let liststatus = repeatlist?"ready":"waiting"
    if (map && repeatlist) {
      // console.log("genRepeatMarkers: ready");
      // console.log("map:"+mapstatus);
      // console.log("markers:"+liststatus)
        genRepeatMarkers(repeatlist,userDetails,map,layercontrol,setlayercontrol)      
    // }else {
      // console.log("genMarkers: not ready");
      // console.log("map:"+mapstatus);
      // console.log("markers:"+liststatus)
    }

}, [map,repeatlist,layercontrol,userDetails]) 



//genMarkers
useEffect(() => {
  // getData(someParam).then(data => setState(data))
  // console.warn("useEFFECT map list");
  // genMarkers(list,diseaseLayer,symptomsLayer,map)
  let mapstatus = map?"ready":"waiting";
  let liststatus = list?"ready":"waiting"
  // console.log(list);
      // alert(Object.keys(list).length);
  // if (map) {console.warn("map ready")}
  console.log(map,list,Object.keys(list).length >0)
    if (map && list) {
      // console.log("genMarkers: ready");

      // console.log("map:"+mapstatus);
      // console.log("markers:"+liststatus)
        genMarkers(list,userDetails,map,layercontrol)      
    // }else {
      // console.log("genMarkers: not ready");
      // console.log("map:"+mapstatus);
      // console.log("markers:"+liststatus)
    }

}, [map,list,layercontrol,userDetails]) 


        // <MarkerList 
        //   setuserDetails={setuserDetails}
        // />
          // attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
          // url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
//original
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

       // <MarkerList 
       //https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
       //https://b.tile.openstreetmap.de/${z}/${x}/${y}.png
       //    setuserDetails={setuserDetails}
       // L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {id: 'Topo', attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'});
// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
       //  />
const center = (userDetails && userDetails.person && userDetails.person.home) ? JSON.parse(userDetails.person.home) : [51.505, -0.09]
  const displayMap = useMemo(
    () => (
      <MapContainer
        className="map"
        id="mymap"
        center={center}
        zoom={zoom}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={setMap}>
        <TileLayer
        url= 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
        maxZoom= '19'
        subdomains= "abc"
        attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        />
        <ZoomControl position="bottomleft" class="myPosition"/>

        <ONMapClick 
        getTab             ={getTab}
        setTab             ={setTab}
        newHome            ={newHome}
        setnewHome         ={setnewHome}
        homeSelected       ={homeSelected}
        sethomeSelected    ={sethomeSelected}
        setSearchWorking   ={setSearchWorking}
        setSelectedLocation={setSelectedLocation}
        selectedLocation   ={selectedLocation}
        returnMsg          ={returnMsg}
        setReturnMsg       ={setReturnMsg}
        /> 
          <GPSButton  />
      </MapContainer>
    ),
    [getTab, homeSelected, newHome, returnMsg,selectedLocation],
  )
// console.log(displayMap);
  return (
    <div>
      
      {displayMap}
      {<DisplayCard
        map                ={map}
        getTab             ={getTab}
        setTab             ={setTab}
        userDetails        ={userDetails}
        setuserDetails     ={setuserDetails}
        newHome            ={newHome}
        setnewHome         ={setnewHome}
        homeSelected       ={homeSelected}
        sethomeSelected    ={sethomeSelected}
        setSearchWorking   ={setSearchWorking}
        layercontrol       ={layercontrol}
        setSelectedLocation={setSelectedLocation}
        selectedLocation   ={selectedLocation}
        returnMsg          ={returnMsg}
        setReturnMsg       ={setReturnMsg}
        />}
        {/*{map ? <DisplayPosition map={map} /> : null}*/}
    </div>
  )



}
 


        // {this.toRenderTab()}

// <LeafletMap />
            //   <Modal modalClassName="endSymptom" contentClassName="endSymptomContent" size="sm" isOpen={this.state.endSymptomModal}>
            //   <ModalBody>
            //     <div className="EndModalMsg">
            //     {this.state.endSymptomName ? this.renderRemoveSymptom():""}
            //     </div>
            //     </ModalBody>
            // </Modal>

            // <Modal modalClassName="loadingModalPosition" contentClassName="loadingModal" size="sm" isOpen={this.state.searchWorking}>
            //   <ModalBody>
            //     <div className="LoadingModalMsg">
            //     <Spinner color="danger" role="status" style={{ width: '3rem', height: '3rem' }} as="span"/>
            //     <span><br />Searching address</span>
            //     </div>
            //     </ModalBody>
            // </Modal>

// function LeafletMap () {
// const position = [this.state.center.lat, this.state.center.lng]
//   return (
//         <MapContainer className="map" id="mymap" center={position} zoom={this.state.zoom} zoomControl={false} whenReady={(map) => {
//     setMapReady(true);}}>
//           <TileLayer
//             attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           />
 
//         </MapContainer>


//     )
// }

// const alreadyStored = (updateObject) => {

// console.log(sync.synced);
//   return false
//  }

const serverScheduled = (updateObject,setweekday,setStartDate,setEndDate,synced,setsynced,setTab,map) => {

  // console.log(start, end, symptoms, diseases);
// if (alreadyStored(updateObject)) {
  // console.log("no update needed");

  // return false

// } else {

// console.log(query);

// console.log(updateObject);
// console.log(API_URL);
// console.log(fetchOptions);

// return false
// let synced = this.state.synced;
// console.log("setstate: serverUpdate loading");
    // this.setState({isLoading: true});
    let query = 'setRepeat';
    // let data = {start:start, end:end, point:point, icon:icon, symptoms:symptoms, diseases:diseases}
    // let data = null;
       axios.post(Constants.API_URL + query, updateObject, Constants.fetchOptions)
      .then(response => {
        // if (response) response=response.json();
        // console.log(response.status);
          // console.log(response);
          // console.log(response.status);
        if (response && response.status === 200) {
          // console.log(response);
          let data = response.data;
          console.log("return data:");
          console.log(data);

      // let type = symlength >0 && dislength >0 ?  3: (symlength>0 && dislength === 0 ? 1:2) 
      // let loc=JSON.parse(data.location.coords);
                    console.log("location:");
                    // console.log(loc);
                    console.log("start:");
                    console.log(updateObject.start);
                    console.log("entered:", updateObject.start,"left:", updateObject.end,);

      // markers['syncedMark'+data.pal.id]=L.marker(loc,{id: 'syncedMark'+data.pal.id,draggable: false,riseOnHover:true, icon:returnIcon(data.pal.icon), position:loc}).addTo(map)
      // .bindPopup('<div>entered: '+ updateObject.start+ '<br />left: '+updateObject.end+ '<br />type:'+type+'</div>').openPopup();
      // getMyMarkers


      //update localStorage
      // setsynced=newsynced;
      // localStorage.setItem('synced', JSON.stringify(newsynced));

      // localStorage.setItem('synced', JSON.stringify(synced));
      //)
        
        //update marker list
        // markers['home']=L.marker(home,{id: 'home',draggable: false,riseOnHover:true, icon:returnIcon("home"), position:home}).addTo(c.api.map)
           // markers['home']=L.marker(home,{id: 'home',draggable: false,riseOnHover:true, icon:returnIcon("home"), position:home}).addTo(c.api.map)
            // .bindPopup("Your home").openPopup();

          return response;
        } else {console.log(response);
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        // console.log("2nd then");
        // console.log(data);
        //reset
        // console.log(updateObject);
        setweekday({su:false,mo:false,tu:false,we:false,th:false,fr:false,sa:false});
 // JSON.parse(updateObject.point)
         // console.log(data);
         let ret = data.data.data.message
         // console.log(data.data.data.message);
        let loc=updateObject.point;
        let weekArray=updateObject.days
        console.log(weekArray);

        //.split(",");
        let week = "<span>"+ (weekArray.su ?"<span class='dayblock'>Su</span>":"") + (weekArray.mo ?"<span class='dayblock'>Mo</span>":"")+(weekArray.tu ?"<span class='dayblock'>Tu</span>":"")+(weekArray.we ?"<span class='dayblock'>We</span>":"")+(weekArray.th ?"<span class='dayblock'>Th</span>":"")+(weekArray.fr ?"<span class='dayblock'>Fr</span>":"")+(weekArray.sa ?"<span class='dayblock'>Sa</span>":"")+"</span>";
        markers['syncedMark'+ret.id]=L.marker(loc,{id: 'syncedMark'+ret.id,draggable: false,riseOnHover:true, icon:MapService.returnIcon(ret.icon,"gray"), position:loc}).addTo(map)
        .bindPopup('<div>entered: '+ updateObject.start+ '<br />left: '+updateObject.end+ '<br />days:'+week+'</div>').openPopup();

        // console.log(markers);
        // setStartDate();
        // setEndDate(new Date);
        // console.log("setTab change 1 set marker");
          setTab(1);
      }
        // this.setState({ fetchUrl: {home: data.home, isLoading: false }})
        )
      .catch(error => {console.warn("catch: "+ error)
        //unload modal
      });
//}; //store on server


}


// ({start: datetimeToSQL(startDate),end: datetimeToSQL(endDate), point: c.api.map.getCenter(), icon: iconSelect, symptoms: newData.symptoms, diseases: newData.diseases},synced,setsynced,c.api.setTab,c.api.map)}>Save</Button>

const serverUpdate = (updateObject,sync,setTab,map,setnewData,setdiseaseSelect,setsymptomSelect) => {
  // console.log(start, end, symptoms, diseases);
// if (alreadyStored(updateObject)) {
  // console.log("no update needed");

  // return false

// } else {

// console.log(updateObject);
// console.log(API_URL);
// // console.log(query);
// console.log(fetchOptions);
// console.log(sync.synced);
// return false
// let synced = this.state.synced;
// console.log("setstate: serverUpdate loading");
    // this.setState({isLoading: true});
    let query = 'setMarker';
    // let data = {start:start, end:end, point:point, icon:icon, symptoms:symptoms, diseases:diseases}
    // let data = null;
       axios.post(Constants.API_URL + query, updateObject, Constants.fetchOptions)
      .then(response => {
        // if (response) response=response.json();
        // console.log(response.status);
          // console.warn(response);
        // alert(JSON.parse(response));
          // console.log(response.status);
        if (response && response.status === 200) {
          // alert(response);

          // console.log(response);
          let data = response.data;
          // alert(JSON.parse(response));
          // console.log("return data:");
           console.warn(data);
        var serverSyncedsymptoms= {};
        var serverSynceddiseases= {};

         // //create if empty
         // if (!synced.diseases) {synced.diseases={}};
         // if (!synced.symptoms) {synced.symptoms={}};


          //add each object to synced
          // .data.message
        // console.log(JSON.parse(data));
//data from server


// serverSyncedsymptoms = Object.keys(data.symptoms).map((key, index)  => {           
//                 var item = data.symptoms[key];
//                 if (item && item !== undefined) {
//                     return {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_symptom_id}};
//                     // return {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_symptom_id}};
//                     // return {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_symptom_id},...serverSyncedsymptoms};
//                  } //else {return ""}
//               });
//      //.data.message
// serverSynceddiseases = Object.keys(data.diseases).map((key, index)  => {           
//                 var item = data.diseases[key];
//                 if (item && item !== undefined) {
//                     return {[item.disease_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_disease_id}};
//                     // return {[item.disease_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_disease_id},...serverSynceddiseases};
//                  } //else {return ""}
//               });



     Object.keys(data.symptoms).forEach((key, index)  => {           
                var item = data.symptoms[key];
                if (item && item !== undefined) {
                    serverSyncedsymptoms = {[item.symptom_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_symptom_id},...serverSyncedsymptoms};
                 } //else {return ""}
              });
     //.data.message
     Object.keys(data.diseases).forEach((key, index)  => {           
                var item = data.diseases[key];
                if (item && item !== undefined) {
                    serverSynceddiseases = {[item.disease_id] : {'fromdate': item.fromdate ,'todate': item.todate , 'id': item.person_has_disease_id},...serverSynceddiseases};
                 } //else {return ""}
              });

     // console.log("synced:");
     // console.log(sync.synced);

     //clear cache for next update
      setnewData({diseases:{},symptoms:{}});
      setdiseaseSelect(null);
      setsymptomSelect(null);

     // this.setState({synced: {}});
     // console.log("server DISEASES:");
     // console.log(serverSynceddiseases);
     // console.log("local pre synced:");
     // console.log(sync.synced.diseases);
     // console.log("server SYMPTOMS:");
     // console.log(serverSyncedsymptoms);
     // console.log("local pre synced:");
     // console.log(sync.synced.symptoms);

     // console.log("update serverUpdate: synced");
      // setsynced({                 // object that we want to update
      //   ...synced,    // keep all other key-value pairs
      //   symptoms: {...serverSyncedsymptoms,...synced.symptoms},
      //   diseases: {...serverSynceddiseases,...synced.diseases},      // update the value of specific key
    
      //   })

    //   let employee = {
    // ...person,
    // ...job
    //   };

      let newsynced = {...sync.synced,    // keep all other key-value pairs
                symptoms: {...serverSyncedsymptoms,...sync.synced.symptoms},
                diseases: {...serverSynceddiseases,...sync.synced.diseases},      // update the value of specific key
                }
      // console.log(updateObject.symptoms);
      // console.log(updateObject.diseases);

      // setsynced({                 // object that we want to update
      //   ...synced,    // keep all other key-value pairs
      //   symptoms: {...serverSyncedsymptoms,...updateObject.symptoms},
      //   diseases: {...serverSynceddiseases,...updateObject.diseases},      // update the value of specific key
    
      //   })

     //  console.log("updated synced:");
     // console.log(sync.synced);


     // console.log("check length:")
      let symlength = data.symptoms 
                    ? Object.keys(data.symptoms).length
                    :0;

      let dislength = data.diseases 
                    ? Object.keys(data.diseases).length
                    :0;

      // console.log(newsynced);
      // console.log("set type");
      // let type = symlength >0 && dislength >0 ?  3: (symlength>0 && dislength === 0 ? 1:2);
      let type = symlength >0 && dislength >0 ?  3: (symlength>0 && dislength === 0 ? 1:2);
      // let type = Object.keys(updateObject.diseases).length ? 1 : Object.keys(updateObject.diseases).length ? 2:0;
      // setstoredMarkers({
      //   ...storedMarkers,
      // })
                    //         <Marker position={coords} key={"disMark" +"_"+index} icon={returnIcon(item.type)}>
                    // <Popup key={key +"_"+index}><div>
                    // entered: {itemstart}<br />left: {itemend}<br />{type}: {item.disease ? diseases[itemtype-1].name : symptoms[itemtype-1].name}<br/>description: {item.description}</div>
                    // </Popup>
                    // </Marker>
                    // );
            // alert(data);
                    // console.log(data)
                    // alert(data.location);

                    let loc=JSON.parse(data.location.coords);

                    // console.log("location:");
                    // console.log(loc);
                    // console.log("start:");
                    // console.log(updateObject.start);
                    // console.log("entered:", updateObject.start,"left:", updateObject.end,"type: ",type,"id:",data.pal.id);

                    let typename =   type === 1 ? Object.keys(updateObject.symptoms):
                        type === 2 ? Object.keys(updateObject.diseases):
                        type === 3 ? "symptoms: "+Object.keys(updateObject.symptoms)+ " diseases: "+Object.keys(updateObject.diseases): null; 

      markers['syncedMark'+data.pal.id]=L.marker(loc,{id: 'syncedMark'+data.pal.id,draggable: false,riseOnHover:true, icon:MapService.returnIcon(data.pal.icon), position:loc}).addTo(map)
// {item.disease ? diseases[itemtype-1].name : symptoms[itemtype-1].name}<br/>description: {item.description}
//incorrect type
      // .bindPopup('<div>entered: '+ updateObject.start+ '<br />left: '+updateObject.end+ '<br />type:'+type+'</div>').openPopup();

      //change name 9999
       // let desname = item.disease ? diseases[itemtype-1].name : symptoms[itemtype-1].name;
       // let type = item.disease ? "disease" : "symptom";


      .bindPopup('<div>entered: '+ updateObject.start+ '<br />left: '+updateObject.end+ '<br />type:'+typename+'</div>').openPopup();
      // .bindPopup('<div>entered: '+ updateObject.start+ '<br />left: '+updateObject.end+ '<br />type:'+type+'</div>').openPopup();
      // getMyMarkers

      // console.log("newsynced");
      // console.log(newsynced);

      //update localStorage
      // setsynced=newsynced;
      sync.setsynced(newsynced);

      localStorage.setItem('synced', JSON.stringify(newsynced));

      // localStorage.setItem('synced', JSON.stringify(synced));
      //)
        
        //update marker list
        // markers['home']=L.marker(home,{id: 'home',draggable: false,riseOnHover:true, icon:returnIcon("home"), position:home}).addTo(c.api.map)
           // markers['home']=L.marker(home,{id: 'home',draggable: false,riseOnHover:true, icon:returnIcon("home"), position:home}).addTo(c.api.map)
            // .bindPopup("Your home").openPopup();

          return response;
        } else {
          // alert(response);
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        console.log("2nd then");
        console.log(data);
console.log("setTab change 1 set marker");
          setTab(1);
        
        // console.log("setstate: serverUpdate: unloadmodal");
        //unload modal
        // this.setState(prevState => ({
        //     isLoading: false,
        // })
        //)
      }
        // this.setState({ fetchUrl: {home: data.home, isLoading: false }})
        )
      .catch(error => {console.warn("catch: "+ error)
        //unload modal
        // this.setState({ isLoading: false, 
        //                               fetchUrl: {error, isLoading: false }})
      });
//}; //store on server

}


 function LoadSpinner () {
// if (searchWorking) {

 const [modal, setModal] = useState(false);
 const toggle = () => setModal(!modal);

return (
   <Modal modalClassName="loadingModalPosition" contentClassName="loadingModal" size="sm" isOpen={modal} toggle={toggle}>
              <ModalBody>
                <div className="LoadingModalMsg">
                <Spinner color="danger" role="status" style={{ width: '3rem', height: '3rem' }} as="span"/>
                <span><br />Searching address</span>
                </div>
                </ModalBody>
            </Modal>
)
};


  function ONMapClick(fn) {
    
// eslint-disable-next-line
   const [position, setPosition] = useState(null)
// eslint-disable-next-line
   const [dragposition, setDragPosition] = useState(null)
   // const [selectedLocation,setSelectedLocation] = useState(fn.selectedLocation);

   // useEffect(()=>{
   //  console.log("fire update location");
   //  console.log(selectedLocation);
   //  fn.setSelectedLocation(selectedLocation);
   //  console.log(fn.selectedLocation);
   // },[selectedLocation])
   // // const [getTab, setgetTab] = useState()

const eventHandlers = useMemo(
    () => ({
      dragend() {
        console.log("dragEnd");
        // const marker = markerRef.current
        if (markers.click != null) {
          let coords =markers.click.getLatLng();
          // markers.click.setIcon(greenIcon);
          console.log(coords);
          console.log(fn.getTab);
          // console.log(dragposition);
          fn.setSearchWorking(true);
          MapService.realpoint(coords, map, function(a){
             if (a && a.properties.type === 'house') {
            // markers.click.setPosition(a.center);
            markers.click.setLatLng(a.center);
            let locationName = a.properties.street +" " +a.properties.housenumber +", " +a.properties.city +", " +a.properties.country || a.name;
            fn.setSelectedLocation(locationName);
            markers.click.setPopupContent(locationName);
            markers.click.openPopup();
            fn.setSearchWorking(false);
            map.flyTo(a.center);
            // toggle();
             // if (fn.getTab >= 1){
              // console.log("nexttap dragend");
              if (!fn.newHome) fn.setTab(2);     
              } else {fn.setReturnMsg("house not found!")}

          })
          // setPosition(coords);
          
        }
      } //,
      //dragstart() {
        // console.log("eventHandlers-drag start");
        // console.log(markers.click);
       // // markers.click.setIcon(greenIcon);
       // // if (markers.click.icon.color
      //}
    }),
    [fn,map],
  )

// console.log(e);
const map = useMapEvent('click', (e) => {
    // map.setView(e.latlng, map.getZoom(), {
      // console.log(e);
      // console.log(e.source)
      // console.log(fn.getTab);

      //only act on map actions (gpsbutton fix)
    if (e.originalEvent.target.id === 'mymap') { 
      // console.log(e.latlng.lat, e.latlng.lng);
      // console.log("map clicked");
            // console.warn(fn.selectedLocation);
            fn.setReturnMsg("")
            // console.log(e.latlng,map);

      if (snap) {
        if (markers.click) {
          // console.log(markers.click.getLatLng());
        // console.log("update click");
          // draggable: true,riseOnHover:true, position: this.state.mapClick.marker, ref:this.refmarker}
        MapService.realpoint(e.latlng,map, function(n){
          if (n && n.properties.type === 'house') {
            markers.click.setLatLng(n.center);
            // markers.click.eventHandlers(eventHandlers);
            // let locationName = (n.properties.street?n.properties.street + " ": "") +(n.properties.housenumber?n.properties.housenumber+", " ||"")  +(n.properties.city||"") +", " +(n.properties.country||"") || n.name;
            console.log(n);


            let locationName = (n.properties.street+" " || "") +(n.properties.housenumber+", "||"")  +(n.properties.city||"") +", " +(n.properties.country||"") || n.name;

                    // console.log(n.properties.housenumber);
            // let locationName = n.properties.street +" " +n.properties.housenumber +", " +n.properties.city +", " +n.properties.country || n.name;
            console.log("setting location to use:");
           console.log("updateclick");
            console.log(locationName);
            //setSelectedLocation(locationName);
            fn.setSelectedLocation(locationName);
            console.error(fn.selectedLocation);
            markers.click.setPopupContent(locationName);
            markers.click.openPopup();
            // check tab
            // console.log(fn.getTab);
            // if (fn.getTab >= 1){
                            // console.log("nexttap mapclick (marker.click");
              if (!fn.newHome) fn.setTab(2); 
              else {fn.sethomeSelected(n.center)}    
            // }
          } else {fn.setReturnMsg("No house found!")}
        
                //   else {
                //     // console.log("new click: point not found");
                //   // fn.sethomeSelected(true);
                // }
          });//realpoint

        // var radius = e.accuracy / 2;
      } else {
    //new click
    // console.log("new click");
      MapService.realpoint(e.latlng,map, function(n){ 
        // realpoint(
        //   )
        // console.log(n);
        if (n && n.properties.type === 'house') {
        // let locationName =n.properties.street || "" + " " +n.properties.housenumber || "" +", " +n.properties.city|| ""  +", " +n.properties.country || n.name;
        console.log(n.properties.housenumber);
        // if (!n.properties.housenumber) {console.warn("oops")}
        // let locationName =n.properties.street +" " +n.properties.housenumber +", " +n.properties.city +", " +n.properties.country || n.name;
        let locationName = (n.properties.street+" " || "") +(n.properties.housenumber+", "||"")  +(n.properties.city||"") +", " +(n.properties.country||"") || n.name;
       console.log("newclick");
       console.log(locationName);
        fn.setSelectedLocation(locationName);
        // console.warn(fn.selectedLocation);
        markers['click']=L.marker(n.center,{id: 'new',draggable: true,riseOnHover:true, position:setDragPosition, icon:MapService.returnIcon()}).addTo(map)
            .bindPopup(locationName).openPopup();
            markers.click.on(eventHandlers);
            // console.log(L.marker(e.latlng,{id: 'gps'}));
        setPosition(n.center);
        // setZoom(12);
        // map.getZoom()
        // console.log(n);
        map.flyTo(n.center,18);
        // console.log(fn.getTab);
        // fn.sethomeSelected(true);
        // console.log(fn.getTab);
        // console.log(fn.homeSelected)
        // if (fn.getTab >= 1){
                        // console.log("nexttap mapclick no marker.click");
        // console.log("change tab to 2 new click");
          if (!fn.newHome) {fn.setTab(2);}else {fn.sethomeSelected(n.center)}       
          // }
        } else {
          fn.setReturnMsg("No house found!")
        }    
        // else {
          // console.log("new click: point not found");
        // fn.sethomeSelected(true);}
                    // setgetTab(2);
                    // this.setState({getTab:2});

        // map.once('moveend', function() {
        //   L.circle(newpoint.center, radius).addTo(map);
        // });

    })//realpoint

  }
         // animate: animateRef.current || false,
    } else {//nosnap
      console.log("no snap");
      if (markers.click) {
            markers.click.setLatLng(e.latlng);
        // markers.click.eventHandlers(eventHandlers);
        // markers.click.setPopupContent(n.properties.street +" " +n.properties.housenumber +", " +n.properties.city +", " +n.properties.country || n.name);
//         markers.click.openPopup();
          } else {
        markers['click']=L.marker(e.latlng,{id: 'new',draggable: true,riseOnHover:true, position:setDragPosition,icon:MapService.returnIcon()}).addTo(map)
        // .bindPopup(n.properties.street +" " +n.properties.housenumber +", " +n.properties.city +", " +n.properties.country || n.name).openPopup();
        markers.click.on(eventHandlers);

          }
    setPosition(e.latlng)
    map.flyTo(e.latlng,18);
    // fn.sethomeSelected(true);
    // if (fn.getTab  >= 1){
      // console.log("change tab to 2 - no snap");
      if (!fn.newHome) {fn.setTab(2);} else {fn.sethomeSelected(e.latlng)}       
      // }
    }//no snap
    }
  })

  return null


    // console.log("click")
  }

function GPSButton() {

 const [position, setPosition] = useState(null)
 // const [zoom, setZoom] = useState(null)

 const map = useMap();

 const onClick = () => {
  // console.log(map.getZoom());
  // map.setPosition(map.locate());
map.on('locationfound', (e) => {MapService.onLocationFound(e,map,setPosition,markers,null,true)});
  // console.log(map.locate());
// const geoLocation = () {
  // body...
  // console.log("CLICK");
 // const [position, setPosition] = useState(null)





   let t = map.locate();
   if (!t) {
    // const printCurrentPosition = async () => {
    //   const coordinates = await Geolocation.getCurrentPosition();

    //   console.log('Current position:', coordinates);
    // };


    // alert("no gps");
    // alert(position);
    console.log("no map point returned!");console.log(position)}

  // return position === null ? null : (
  //   <Marker position={position}>
  //     <Popup>You are here</Popup>
  //   </Marker>
  // )

}
  //button
  return <div className="leaflet-bottom leaflet-right"><button className="myPosition leaflet-control leaflet-bar material-icons" onClick={onClick} >gps_fixed</button></div>
}

// genRepeatMarkers
function genRepeatMarkers(mylist,map,layercontrol,setlayercontrol) {
  // getList.then(list => {
    console.log("updating markers:");
    if ( Object.keys(mylist).length >0 ) {
mylist.then( list =>{
let repeatS = [];
  // let RepeatMarkers = 
  Object.keys(list).forEach((key, index)  => {
                // console.log(key, list[key]);  
                var item = list[key];
                  let coords = JSON.parse(item.location.coords);
                    let itemstart =  item.start;
                    let itemend = item.end;
                    // let itemtype = item.disease ? item.disease.disease_id : item.symptom.symptom_id;
                    // let type = item.disease ? "disease" : "symptom";


                    if (item.fromdate && item.todate) {
                    itemstart =  new Date(item.fromdate).toLocaleString();
                    itemend = new Date(item.todate).toLocaleString();;
                    }

                    // let dateperc = 1-1/(60 / ((now.getTime() - new Date(item.todate).getTime()) / (1000*60*60*24))).toFixed(4) ;
                    //  if (dateperc > 0 ) {
                     // let desname = item.disease ? diseases[itemtype-1].name : symptoms[itemtype-1].name;

                     if (item) {
                      let weekArray = item.days.split(",");
                      console.log(weekArray);
                      let week = "<span>"+ (weekArray[0] === '1' ?"<span class='dayblock'>Su</span>":"") + (weekArray[1] === '1' ?"<span class='dayblock'>Mo</span>":"")+(weekArray[2] === '1' ?"<span class='dayblock'>Tu</span>":"")+(weekArray[3] === '1'?"<span class='dayblock'>We</span>":"")+(weekArray[4] === '1'?"<span class='dayblock'>Th</span>":"")+(weekArray[5] === '1'?"<span class='dayblock'>Fr</span>":"")+(weekArray[6] === '1'?"<span class='dayblock'>Sa</span>":"")+"</span>";
                     
                      repeatS.push(L.marker(coords, {key:"repMark_"+index, icon:MapService.returnIcon(item.icon,"gray")})
                      .bindPopup('<div>entered: '+ item.start+ '<br />left: '+item.end+ '<br />days:'+week+'</div>').openPopup()
                     );
                     } //item
                     //  diseaseS.push(L.marker(coords, {key:"disMark_"+index, icon:returnIcon('coronavirus',"red")})
                     //    .setOpacity(dateperc)
                     //    .bindPopup("<div>entered: "+ itemstart+"<br />left: "+itemend+"<br />"+type+": "+desname +"</div>")
                     //    );

                     // } else {
                     //  //add symptom
                     //  let icon ="";
                     //  if (item.icon) {
                     //    icon=item.icon;
                     //  } else {icon="not_listed_location"}
                     //  symptomS.push(L.marker(coords, {key:"symMark_"+index, icon:returnIcon(icon)})
                     //    .setOpacity(dateperc)
                     //    .bindPopup("<div>entered: "+ itemstart+"<br />left: "+itemend+"<br />"+type+": "+desname +"</div>")
                     //    );
                     // }

                  //} //within x days                      
             } //object keys
      ); //markers

  console.log(repeatS);
  if (repeatS.length > 0) {
  var repeatLayer = L.layerGroup(repeatS).addTo(map);    

      // diseasesLayer = L.layerGroup(diseaseS).addTo(map), 
      // homeLayer = L.layerGroup(home).addTo(map); 

  // var MapNik =        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {id: 'Mapnik', attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}),
  //   BlackAndWhite = L.tileLayer("https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", {id: 'BlackAndWhite',  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}),
  //   Topo =          L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {id: 'Topo', attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'});



    // "Diseases": null,
// var baseLayers = {
//    "MapNik": MapNik,
//    "BlackAndWhite": BlackAndWhite,
//    "Topo" : Topo
//  //   "OpenStreetMap": osm
// };
//<div className="material-icons">Home</div>


  // var overlayMaps = {
  //   // '<span class="layer"><span class="material-icons homeIcon">home</span>Home</span>' : homeLayer,
  //   // '<span class="material-icons symptomIcon">sick</span>Symptoms': symptomsLayer,
  //    '<span class="material-icons repeatIcon">repeat</span>Repeatable Markers': repeatLayer,
  // };    
    //     var options = {
    //   // Make the "Landmarks" group exclusive (use radio inputs)
    //   exclusiveGroups: ["Reprensitives"],
    //   // Show a checkbox next to non-exclusive group labels for toggling all
    //   groupCheckboxes: false
    // };




    // layercontrol.addOverlay({"test":repeatLayer}).setPosition("topleft").addTo(map);
    // console.log(layercontrol);
    // layercontrol.addTo(overlayMaps)
    // overlayMaps.addTo(layercontrol);
      layercontrol.addOverlay(repeatLayer,'<span class="material-icons repeatIcon">repeat</span>Repeatable Markers',true,99).addTo(map);
      // setlayercontrol(layercontrol.addOverlay(repeatLayer,"name",true));
    // setlayercontrol(layercontrol.addOverlay(overlayMaps));
  }

    // console.log(layercontrol);
    // setlayercontrol(layercontrol.addOverlay({"test":repeatLayer}));
   
   // setlayercontrol(layer{"test":repeatLayer})
    // layercontrol.layers(null, overlayMaps,options);
    // console.log(layercontrol);
    // layercontrol.addControl({'<span class="material-icons repeatIcon">repeat</span>Repeatable Markers':repeatLayer})
        // layercontrol.addOverlay(repeatS)
        // setlayercontrol({...layercontrol,
            // 'Main': repeatLayer
            // here i need to add new layer control
// })
            // },  { collapsed: true }).setPosition("topleft").addTo(map);

          // L.control.AddLayer(overlayMaps);
  // map.addLayer(repeatLayer);
  // map.addControl({'<span class="material-icons repeatIcon">repeat</span>Repeatable Markers':repeatLayer});
            // L.control.layers(null, overlayMaps,options)
            // .setPosition("topleft")
            // .addTo(map);

        //    L.Control.Layers
        // var imageOverlayNew = new L.imageOverlay(imageOverlayUrlNew, bounds);
        // var layerControl = new L.control.layers(null,{
            // 'Main': repeatLayer,
            //here i need to add new layer control
            // },  { collapsed: true }).setPosition("topleft").addTo(map);
        // layerControl.addOverlay(repeatS);

})
}

  // });
}


//genMarkers
function genMarkers(mylist,userDetails,map,layercontrol) {
  // getList.then(list => {
    // console.warn("updating markers:");
// console.log(list);

  let home = [];

if (userDetails && userDetails.person.home) {
    // console.log("getMarkers list:");

  if (userDetails) {
    let userHome = userDetails.person.home 
    // console.log(userHome);
    // console.log(currentUser)

    if (userHome) {
      // console.log("adding user home");
      home.push(L.marker(JSON.parse(userDetails.person.home), {key:"homeMark_0", icon:MapService.returnIcon("home","green")})
          .bindPopup("<div>Your Home</div>")
          );
                  
  }   
    }
}
  let diseaseS = [];
  let symptomS = [];

//markers exist
  if (Object.keys(mylist).length >0 ) {
mylist.then( list =>{

//   let markerS = Object.keys(list).map((key, index)  => {
  Object.keys(list).forEach((key, index)  => {
                // console.log(key, list[key]);  
                var item = list[key];
                  let coords = JSON.parse(item.location.coords);
                    let itemstart =  item.disease ? item.disease.fromdate : item.symptom.fromdate;
                    let itemend = item.disease ? item.disease.todate : item.symptom.todate;
                    let itemtype = item.disease ? item.disease.disease_id : item.symptom.symptom_id;
                    let type = item.disease ? "disease" : "symptom";

                    if (item.fromdate && item.todate) {
                    itemstart =  new Date(item.fromdate).toLocaleString();
                    itemend = new Date(item.todate).toLocaleString();;
                    }

                    let dateperc = 1-1/(60 / ((now.getTime() - new Date(item.todate).getTime()) / (1000*60*60*24))).toFixed(4) ;

                      if (dateperc > 0 ) {
                     let desname = item.disease ? diseases[itemtype-1].name : symptoms[itemtype-1].name;
                     if (item.disease) {
                      diseaseS.push(L.marker(coords, {key:"disMark_"+index, icon:MapService.returnIcon('coronavirus',"red")})
                        .setOpacity(dateperc)
                        .bindPopup("<div>entered: "+ itemstart+"<br />left: "+itemend+"<br />"+type+": "+desname +"</div>")
                        );

                     } else {
                      //add symptom
                      let icon ="";
                      if (item.icon) {
                        icon=item.icon;
                      } else {icon="not_listed_location"}
                      symptomS.push(L.marker(coords, {key:"symMark_"+index, icon:MapService.returnIcon(icon)})
                        .setOpacity(dateperc)
                        .bindPopup("<div>entered: "+ itemstart+"<br />left: "+itemend+"<br />"+type+": "+desname +"</div>")
                        );
                     }

                  } //within x days                      
             } //object keys
      ); //markers


})//mylist then
}//if list

//   console.warn(homeLayer);
//   console.warn(symptomS);
// console.warn(diseaseS);
//    console.warn(home);
   // alert(home);

  var symptomsLayer = L.layerGroup(symptomS).addTo(map),
      diseasesLayer = L.layerGroup(diseaseS).addTo(map), 
      homeLayer = L.layerGroup(home).addTo(map); 

  // var MapNik =        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {id: 'Mapnik', attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}),
  //   BlackAndWhite = L.tileLayer("https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", {id: 'BlackAndWhite',  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}),
  //   Topo =          L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {id: 'Topo', attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'});



    // "Diseases": null,
// var baseLayers = {
//    "MapNik": MapNik,
//    "BlackAndWhite": BlackAndWhite,
//    "Topo" : Topo
//  //   "OpenStreetMap": osm
// };
//<div className="material-icons">Home</div>
  // var overlayMaps = {
  //   '<span class="layer"><span class="material-icons homeIcon">home</span>Home</span>' : homeLayer,
  //   '<span class="material-icons symptomIcon">sick</span>Symptoms': symptomsLayer,
  //    '<span class="material-icons diseaseIcon">coronavirus</span>Diseases': diseasesLayer,
  // };
    

    //     var options = {
    //   // Make the "Landmarks" group exclusive (use radio inputs)
    //   exclusiveGroups: ["Reprensitives"],
    //   // Show a checkbox next to non-exclusive group labels for toggling all
    //   groupCheckboxes: false
    // };
          // console.warn("adding layers");
            //test
            layercontrol.addOverlay(homeLayer,'<span class="layer"><span class="material-icons homeIcon">home</span>Home</span>',true).addTo(map);
            layercontrol.addOverlay(symptomsLayer,'<span class="material-icons symptomIcon">sick</span>Symptoms',true).addTo(map);
            layercontrol.addOverlay(diseasesLayer,'<span class="material-icons diseaseIcon">coronavirus</span>Diseases',true).addTo(map);
            // L.LayersControl.Overlay(true,layer,"symptoms");
}

export default App;