import L from 'leaflet';

class MapService {

returnIcon(type,color) {
        type =type ? type : 'not_listed_location';

        return L.IconMaterial.icon({
                icon: type,            // Name of Material icon
                iconColor: '#FFFFFF',              // Material icon color (could be rgba, hex, html name...)
                markerColor: color|| 'orange',  // Marker fill color
                outlineColor: 'black',            // Marker outline color
                outlineWidth: 0.25,                   // Marker outline width 
                popupAnchor: [0, -40], 

                });
        }


realpoint(latlng,map,cb) {
        // console.log("MapService realpoint");
  // console.log(latlng);
    const geocoder = L.Control.Geocoder.photon();
      geocoder.reverse(
        latlng,
        map.options.crs.scale(map.getZoom()),    
        results => {
          // console.log(results);
          var r = results[0];
          if (r && r.properties) {
                      if (r.properties.type !== 'house') {

                        // console.log(results)
                      }
          // console.log(r.properties.type);
          return cb(r);
          } else {
            // console.log(results);
            return cb(false);
          }

          });            
    }

onLocationFound(e,map,setPosition,markers,customZoom,mark) {
      // if position found, then remove the existing position marker and accuracy circle from the map
          customZoom= customZoom ? customZoom : 18;
              var radius = Math.round(e.accuracy / 2);
          if (markers.gps) {
                this.realpoint(e.latlng,map, function(newpoint) {
                if (newpoint) {
                  if (JSON.stringify(newpoint.center) !== JSON.stringify(markers.gps._latlng)) {
                   // console.log("user moved"); 
                   map.flyTo(newpoint.center, 18);
                // console.log("already stored"); 
                  } else {
                    //do nothing?
                    // console.log("do nothing!");
                    map.flyTo(newpoint.center, 18);

                  }  
                } 
                //else {console.log("warning: location not found")}

                }) 
} else { //markers.gps

      this.realpoint(e.latlng,map, function(newpoint){
        // console.log(newpoint);
        if (newpoint) {
          // console.log(mark);
        if (mark) {
        markers['gps']=L.marker(newpoint.center,{icon: L.IconMaterial.icon({icon: 'location_on',iconColor: '#FFFFFF',markerColor: 'blue',outlineColor: 'black',outlineWidth: 0.25,popupAnchor: [0, -40], }), id: 'gps'}).addTo(map)
            .bindPopup("you are within the approximate range of "+radius+" meters from this point").openPopup();

            } 
        setPosition(newpoint.center);
        map.flyTo(newpoint.center,18);
        map.once('moveend', function() {
         if (mark) {
          L.circle(newpoint.center, radius).addTo(map);
                  }
                });
         } 
        });
               
         }//else
     }


}

export default new MapService()