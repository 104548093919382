export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  // alert(user.token);
  // // if (user && user.accessToken) {
  	 if (user && user.token) {
      // alert(user.token);
  	// console.log(user.accessToken);
    // return { Authorization: 'Bearer ' + user.accessToken };
    // console.log(user.token);
    return { Authorization: user.token };
  } else {
    // console.error("not logged in!");
    // alert("not logged on yet");
    return {};
  }
}


// export default function authHeader() {
//   const user = JSON.parse(localStorage.getItem('user'));

//   if (user && user.token) {
//     // for Node.js Express back-end
//     return { 'x-access-token': user.token };
//   } else {
//     return {};
//   }
// }
