// import AuthService from "../services/auth.service";
import {setSubscription} from "../services/data.service";
// import {notificationEnabled} from "../services/notificationEnabled.js";
// import axios from "axios";
// import StoreService from "../services/store.service";
// import React, { Component,useState,useMemo,useEffect,useCallback} from "react";

// import { PushNotifications } from '@capacitor/push-notifications';
// ActionPerformed,
//   PushNotificationSchema,
  // Token,
import { PushNotifications} from '@capacitor/push-notifications';

import {Capacitor} from '@capacitor/core';

//static
// const currentUser =  AuthService.getCurrentUser();
// const sub = null;

// import { LocalNotifications } from '@capacitor/local-notifications';


// const notificationEnabled = async (): Promise<PermissionState> => (
//   LocalNotifications.checkPermissions()
//     .then((response) => {
//       // 'prompt-with-rationale' is not a PermissionState.
//       if (response.display === 'prompt-with-rationale') {
//         return ('prompt');
//       }
//       return (response.display);
//     }));


class NotificationService {

  subscribe() {
console.log("trying to subscribe!");
let isMobile = 0;


        if (Capacitor.getPlatform() !== 'web') {
          isMobile=1;
          console.log("mobile subscription:");

         PushNotifications.requestPermissions().then(permission => {
            if (permission.receive === 'granted') {
                PushNotifications.register();
                // regWorker().catch((err) => { console.log("error:");console.error(err); } );
            }
            else {
                // If permission is not granted
              console.log("Please allow notifications."); 
            }
        });
        
        PushNotifications.addListener('registration', (token) => {
            // console.log(token);
            // console.log(token.value);
            setSubscriptionToDB(token.value,isMobile);
        });
        
        PushNotifications.addListener('registrationError', (err)=> {
            console.log(err);
        });

        PushNotifications.addListener('pushNotificationReceived', (notifications) => {
            // console.log(notifications);
            // showPush(notifications);
          pushShow(notifications);
        });

        PushNotifications.addListener('pushNotificationActionPerformed', (notifications) => {
            // console.log(notifications);
          pushAction(notifications);
        });


    
    } else {
      console.log("web subscription:");
  // permission=Notification.permission;
  // Ask for permission
  if (Notification.permission === "default") {
    Notification.requestPermission().then((perm) => {
      if (Notification.permission === "granted") {
        regWorker().catch((err) => { console.log("error:");console.error(err); } );
      }
      else { alert("Please allow notifications."); }
    });
  }
   
  // granted
  else if (Notification.permission === "granted") {
      regWorker().catch((err) => { console.error(err); } );
  }

  // denied
  else { alert("Please allow notifications."); }
// }
  // service worker
  async function regWorker () {
    // public key
    const publicKey = "BKdzjdEM47DeRWSlVcQYgJGui2QAJLEiaNmREsrqGJonMvyfjplLbS7PVYU1QW4pxnTxPS5js5og_2AAF4zrxY0";
   
    // register service worker
    const reg = await navigator.serviceWorker.register("./notification-sw.js", { scope: "/" });
   console.log("Service Worker Registered...");
   await navigator.serviceWorker.ready;

    // Subscribe to push server
    const sub = await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey
    }).then(function(subscription) {
      // save || update db
                if (subscription) {
                  // console.log("subscription:");console.log(subscription);
                  setSubscriptionToDB(subscription,isMobile);

          } //subscription
        })
      .catch(function(e) {
        console.error('push manager subscription fail: ', e);
      });
      console.log(sub);
    }
 } //web

function pushShow(notifications) {
  //notifications
  console.log('push receive' + JSON.stringify(notifications));
}


function pushAction(notifications) {
  //notifications
  console.log(notifications);
  const data = notifications.data;
  console.log('Action performed:' + JSON.stringify(notifications));
  if (data) {
    console.log(data);
  }
}


  function setSubscriptionToDB(subscription,isMobile) {
    let serversub = JSON.parse(localStorage.getItem('user'));
    let fulloldsub = JSON.parse(localStorage.getItem('subscription'));
    console.log(fulloldsub);
    // console.log(fulloldsub.data.subscription);
    let oldsub ="";
    
    if (fulloldsub && fulloldsub.data && fulloldsub.data.subscription) {
        oldsub= JSON.parse(fulloldsub.data.subscription);
              // oldsub= fulloldsub.data.subscription;
    }
    //update subscription

//debug
              console.log("isMobile:");console.log(isMobile);
              console.log("fullold:");console.log(fulloldsub);
              console.log("oldsub:");console.log(oldsub);
              console.log("subscription:");
              console.log(subscription.endpoint);


    if (isMobile) {
      //mobile subscript update
      console.log("isMobile");
      let stringSub=JSON.stringify(subscription);
      // stringSub
       if (fulloldsub && fulloldsub.data.subscription !== stringSub) { 
        // console.log("newsub:");
        // console.log(fulloldsub.data.subscription);
        // console.log("!==");
        // console.log(stringSub);
          //       //new subscription
         setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
       } else {
        //check db
        console.log("check subscriptions list");
        subDB(serversub.subscription, fulloldsub,subscription,isMobile);
       }
      } else {
        //web subscription update
        console.log("isWeb");
        // console.log(oldsub, subscription.endpoint);
        // console.log("enpoints:");
        // console.log(oldsub.endpoint, subscription.endpoint);
            if (oldsub && subscription.endpoint) {
              if (subscription.endpoint !== oldsub.endpoint) {
          //store on db
                console.log("store in db");
          setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
              } else {
                //check db
                subDB(serversub.subscription, fulloldsub,subscription,isMobile);
              } 
            } else {
              // console.log("new subscription");
              // console.log("server:");console.log(serversub);
              if (Object.keys(serversub.subscription).length > 0) {
                console.log("checking stored server subscriptions");
                // console.log(serversub.subscription, fulloldsub,subscription,isMobile);

                // console.log(fulloldsub);
                subDB(serversub.subscription, fulloldsub,subscription,isMobile);
              } else {
                console.log("store new subscription");
                setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });}
              // if (subscription.endpoint) {
              //    setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
              // }
            }




      } //else


function subDB(subscriptions,fulloldsub, subscription, isMobile) {
        // none subscription stored
  if (Object.keys(subscriptions).length < 1) { 
    console.log("renewed outdated subscription")
    setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
    } else {
      //multiple subscriptions stored
      let sub = JSON.stringify(subscription);

      console.log("checking for subscription:");console.log(sub);
      console.log("in:");console.log(subscriptions);
  if  (!subscriptions.some(o => o.subscription === sub)) {
  // if  (!subscriptions.some(o => o.subscription == fulloldsub.data.subscription)) {
        console.log("adding new key to db");
        setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { console.log(callback);localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
        } else {
          console.log("subscription found");
          if (!fulloldsub && sub) {

            let modsub= {"data":{"subscription":JSON.stringify(subscription)}};
            // console.log(modsub.data.subscription);
            // let newsub = ;

            console.log("updating localStorage with:");
            // console.log(newsub);
            // setSubscription(subscription, isMobile, ( err, callback )  => { if (!err) { console.log(callback);localStorage.setItem("subscription", JSON.stringify(callback)); } else {console.error(err)} });
            localStorage.setItem("subscription", JSON.stringify(modsub));
          }
        }
        //else {
        //   if (fulloldsub && fulloldsub !== subscription) {
        //   localStorage.setItem("subscription", JSON.stringify(subscription));  
        // } else {
        //   if (!fulloldsub) {                    
        //     console.log("subscription already found, updating localStorage");
        //     localStorage.setItem("subscription", JSON.stringify(subscription));}
        // }

         //}

      }//else
    } //subDB

} //setSubscriptionToDB

} //subscribe

  getSubscription() {
       return JSON.parse(localStorage.getItem('subscription'));
    }


}



export default new NotificationService()