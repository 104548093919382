import authHeader from '../services/auth-header';
import L from "leaflet";

export const API_URL='https://hablarjuntos.com:5088/users/';
export let fetchOptions = { headers: authHeader()};




export const logginglevels = ['none','info', 'warning', 'error'];

export const leafleticon= L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});