import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 6 and 40 characters.
      </div>
    );
  }
};


const vrepeatpassword = value => {

    // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // const medium = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // let pwcolor="#ffcccb";

    // if (strongRegex.test(value)) {pwcolor="lightgreen"}

    // document.getElementById("repeatpassword").style.backgroundColor=pwcolor;

};

const vpassword = value => {

    
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  //   // const strong = new RegExp(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,});
  //   // const medium = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,})")
  //   const medium = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    let pwcolor="#ffcccb";

    if (strongRegex.test(value)) {pwcolor="lightgreen";}
  //   document.getElementById("strength").innerHTML="Strong";
  // } else 
  //   if (medium.test(value)) {pwcolor="lightorange";
  //   document.getElementById("strength").innerHTML="Medium";}
  //   else {
  //     document.getElementById("strength").innerHTML="Weak";
  //   }

    // else {pwcolor="red"};

    document.getElementById("password").style.backgroundColor=pwcolor;

    // this.setState({
    //   passwordColor:pwcolor,
    //   password: value
    // });


  // }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangefn = this.onChangefn.bind(this);
    this.onChangeln = this.onChangeln.bind(this);
    
    this.onChangerepeatPassword = this.onChangerepeatPassword.bind(this);

    // this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      passwordready: false,
      repeatpassword: "",
      fn:"",
      ln:"",
      successful: false,
      message: "",
      passwordColor: "white",
      repeatpasswordColor: "#EBEBE4"
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

onChangerepeatPassword(e) {
  let value=e.target.value
  let pw=this.state.password;

  if (value === pw) {
    // console.log("pw match");
this.setState({repeatpassword: value,
      repeatpasswordColor: "lightgreen"
    })
} else { 
// console.log("pw not match") 
  this.setState({repeatpassword: value,
      repeatpasswordColor: "#ffcccb"})
  }
}

  onChangePassword(e) {

    let value=e.target.value
    // console.log(value);

    //regex
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    let length = new RegExp("(?=.{8,})");
    // let aZ = new RegExp("(?=.*[a-z])(?=.*[A-Z])");
    // let aZ = new RegExp("?=.*[a-z])(?=.*[A-Z]");
// (?=.*?[0-9]).{6,}$
    let aZ = new RegExp('^(?=.*?[A-Z])(?=.*[a-z])')
    let num = new RegExp("(?=.*[0-9])");
    let mix = new RegExp("(?=.*[!@#$%^&*])");

//caps
  if (aZ.test(value)) {
    document.getElementById('caps').classList.add('pwcheck');
  } else {
    document.getElementById('caps').classList.remove('pwcheck');
  }

//length
  if (length.test(value)) {
    document.getElementById('length').classList.add('pwcheck');
  } else {
    document.getElementById('length').classList.remove('pwcheck');
  }

//number

 if (num.test(value)) {
  document.getElementById('numbers').classList.add('pwcheck');
} else {
  document.getElementById('numbers').classList.remove('pwcheck');
}

 if (mix.test(value)) {
  document.getElementById('symbols').classList.add('pwcheck');
} else {
  document.getElementById('symbols').classList.remove('pwcheck');
}

//repeat password
if (strongRegex.test(value)) {
  this.setState({passwordready:true,
  repeatpasswordColor: "#ffcccb"
}) } else {
  this.setState({passwordready:false,
    repeatpasswordColor: "#EBEBE4"
  });

}
  // switch (value) {
  //   // case value.match(medium)
  //   //all
  //   // case value.match(strongRegex):
  //   //     document.getElementById('length').classlist.add('pwcheck');
  //   //     document.getElementById('case').classlist.add('pwcheck');
  //   //     document.getElementById('mix').classlist.add('pwcheck');
  //   //     document.getElementById('special').classlist.add('pwcheck');
  //   //     break;
  //   case aZ.test(value):
  //     console.log("case match");
  //       document.getElementById('case').classlist.add('pwcheck');
  //       break;


  //   case length.test(value):
  //     console.log("length match");
  //       document.getElementById('length').classlist.add('pwcheck');
  //       break;

  //   // case value.match(aZ):
  //     // document.getElementById('case').classlist.add('checked');
  //     // break
  //   // case value.match(aZ):
  // }

      // passwordColor:pwcolor,
  
    this.setState({
      password: value
    });


  // if (value.length < 8 || value.length > 100) {
    // return (
    //   <div className="alert alert-danger" role="alert">
    //   <ul>
    //   <li id="length" className="pwcondition">The password must be between 8 and 100 characters.</li>
    //   <li id="case" className="pwcondition">A mixture of both uppercase and lowercase letters.</li>
    //   <li id="mix" className="pwcondition">A mixture of letters and numbers.</li>
    //   <li id="special" className="pwcondition">Inclusion of at least one special character, e.g., ! @ # ? ]</li>
    //   </ul>
    //   </div>
    // );

  }

onChangefn(e) {
    this.setState({
      fn: e.target.value
    });
  }

onChangeln(e) {
    this.setState({
      ln: e.target.value
    });
  }


  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password,
        this.state.fn,
        this.state.ln,
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          // console.log(error.response);
          console.log(error.response.data);
          console.log(error.message);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

 // <div className="req"> <div className="name">strength:</div><div id="strength"></div> </div>


  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username *</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email *</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  
                  <div id="passwordreq"> 
                  <div className="req" id ="length"><div className="name"> &gt; 8 &lt; 50 </div></div>
                  <div className="req" id ="caps"><div className="name">a-Z</div></div>
                  <div className="req" id="numbers"><div className="name">123</div></div>
                  <div className="req" id="symbols"><div className="name">!@#</div></div>

                  </div>

                  <label htmlFor="password">Password *</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                    style={ { "backgroundColor": `${ this.state.passwordColor }` } }
                  />
                  <div className="req">Use 8 or more characters with a mix of letters, numbers & symbols with a maximum of 50 characters.</div>               
                  <label htmlFor="repeatpassword">Repeat Password *</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="repeatpassword"
                    id="repeatpassword"
                    disabled={!this.state.passwordready}
                    value={this.state.repeatpassword}
                    onChange={this.onChangerepeatPassword}
                    validations={[required, vrepeatpassword]}
                    style={ { "backgroundColor": `${ this.state.repeatpasswordColor }` } }
                  />
                </div>


                 <div className="form-group">
                  <label htmlFor="fn">Firstname</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="fn"
                    value={this.state.fn}
                    onChange={this.onChangefn}
                  />
                </div>

                 <div className="form-group">
                  <label htmlFor="ln">Lastname</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="ln"
                    value={this.state.ln}
                    onChange={this.onChangeln}
                  />
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-block">Sign Up</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}