import React, { useState,useMemo, useEffect} from "react";
// import { Navigate } from 'react-router-dom';
// import Joi from '@hapi/joi';
// import axios from "axios";
import DataService, {getStaticData,getSynced, getStored} from "../services/data.service";
// import UserService from "../services/user.service";
// import authHeader from '../services/auth-header';
import { MapContainer, TileLayer, ZoomControl,useMap, LayersControl} from 'react-leaflet';
// import { MapContainer, TileLayer, ZoomControl, useMap, useMapEvent} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import "leaflet.offline";
import "bootstrap/dist/css/bootstrap.css";
import AuthService from "../services/auth.service";
import MapService from "../services/map.service";

import { Button, Card, CardTitle, CardText, Spinner, Collapse, Modal, ModalBody} from 'reactstrap';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../symptoms.css";
import "../diseases.css";
import "leaflet-iconmaterial/dist/leaflet.icon-material.js"
import "leaflet-iconmaterial/dist/leaflet.icon-material.css";
import 'material-icons/iconfont/material-icons.css';
import 'leaflet-control-geocoder';
// import * as Constants from '../services/constants'

import "leaflet.featuregroup.subgroup";

 export function saveStateHome(input){
console.log(input);
console.log("setstate: saveStateHome");
this.setState(input);
// return true
  }

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


// const schema = Joi.object({
//   search: Joi.string().allow('').min(0).max(500).required()
// });

// const markerSchema = Joi.object({
//   longitude: Joi.number().min(-90).max(90).required(),
//   latitude: Joi.number().min(-180).max(180).required(),
// });

const currentUser =  AuthService.getCurrentUser();
const ServerMarkers= DataService.getMarkers();

const center = (currentUser && currentUser.person && currentUser.person.home) ? JSON.parse(currentUser.person.home) : [51.505, -0.09]
const zoom = 13


const [staticData,outdated] = getStaticData();

getSynced();
getStored();
// const snap = getSnap()||false; //getSnap();
// const snap = true;
// const test = DataService.getTest();
// console.log(snap)
// console.log()
// console.log(DataService.getStaticData());
const diseases = staticData.diseases;
const symptoms = staticData.symptoms;


// const startDateSchema = Joi.object({
//   startDate: Joi.date().required()
// });

// const endDateSchema = Joi.object({
//   endDate: Joi.date().required()
// });


// const API_URL='https://hablarjuntos.com:5088/users/';
// const fetchOptions = { headers: authHeader() };
// const logginglevels = ['none','info', 'warning', 'error'];

// type Position = { lat: number, lng: number };

// type State = {
//   center: Position,
//   marker: Position,
//   zoom: number,
//   draggable: boolean,
// };
var markers = {};

const markerPush = (item, type, markerDetails) => {
  // console.log('%c markerPush: ', 'background: black; color:red'); console.log(type); console.log(item);
// console.log(item);
                                // let duplItem = ServerMarkers[otherItem];
let isSymptom=false;
let isDisease=false;
let typeid="disease_id";
// console.log(type);
if (type === "symptom") {
  isSymptom=true;
  isDisease=false;
  typeid="symptom_id";
}

if (type === "disease") {
  isDisease=true;
  typeid="disease_id";
}



if (Array.isArray(item) && item.length) {
// console.log("IS ARRAY");  


                      // let dupisSymptom = ServerMarkers[otherItem].person_has_symptom_id ? true : false;




let arrayItems= [];
    item.forEach(function(i) {


    //double check

let checktype=i.person_has_symptom_id ? "symptom" : "disease";
if (checktype !== type) {
    // console.warn("oops type diff");
    if (checktype === "symptom") {
      isSymptom=true;
      isDisease=false;
      typeid="symptom_id";
      type ="symptom";
    }else {
     isDisease=true;
      isSymptom=false;
      type ="disease";
     typeid="disease_id";

    }

            };

          // this.pPrint(otherItem,"otherItem","yellow","lightgray");
            // console.log(type);
            // console.log(i);
            // let typechk=i[type]>i[type]:
            // console.log(i[type].from);
            // if (!i[type].fromdate) {return null}
            let arrayfromDate = new Date(i[type].fromdate);
            let arraytoDate="";
            let arraytoLocaleDate="";
            let arraytoLocaleTime="";
           if (i[type].todate) {
                        // itemtoDate= new Date(duplItem.disease.todate);
                        arraytoDate= new Date(i[type].todate);
                        arraytoLocaleDate= arraytoDate.toLocaleDateString('en-GB');
                        arraytoLocaleTime = arraytoDate.toLocaleTimeString('en-GB');
                      };
                            // console.log('%c **************************** Check **********************','  color: blue') console.log('id:'+ (i[type][typeid]-1) +', symptom:'+ isSymptom+', fromDate: '+arrayfromDate.toLocaleDateString('en-GB')+', fromTime:'+ arrayfromDate.toLocaleTimeString('en-GB')+', toDate:'+ arraytoLocaleDate+', toTime: '+arraytoLocaleTime);
                        arrayItems.push({id: i[type][typeid]-1, symptom: isSymptom, fromDate: arrayfromDate.toLocaleDateString('en-GB'), fromTime: arrayfromDate.toLocaleTimeString('en-GB'), toDate: arraytoLocaleDate, toTime: arraytoLocaleTime});  
                }); //each
    // console.log(arrayItems);
       return arrayItems;
         

} else {

                        // let itemfromDate = new Date(duplItem.disease.fromdate);
                      let itemfromDate = new Date(item[type].fromdate);
                      let itemtoDate="";
                      let itemtoLocaleDate="";
                      let itemtoLocaleTime="";
                      // if (duplItem.disease.todate) {
                        if (item[type].todate) {
                        // itemtoDate= new Date(duplItem.disease.todate);
                        itemtoDate= new Date(item[type].todate);
                        itemtoLocaleDate= itemtoDate.toLocaleDateString('en-GB');
                        itemtoLocaleTime = itemtoDate.toLocaleTimeString('en-GB');
                      };
                            // console.log('id:'+ (item[type][typeid]-1) +', symptom:'+ isSymptom+', fromDate: '+itemfromDate.toLocaleDateString('en-GB')+', fromTime:'+ itemfromDate.toLocaleTimeString('en-GB')+', toDate:'+ itemtoLocaleDate+', toTime: '+itemtoLocaleTime);
                            return {id: item[type][typeid]-1, symptom: isSymptom, fromDate: itemfromDate.toLocaleDateString('en-GB'), fromTime: itemfromDate.toLocaleTimeString('en-GB'), toDate: itemtoLocaleDate, toTime: itemtoLocaleTime};
                            // markerDetails.push({id: item[type][typeid]-1, symptom: isSymptom, fromDate: itemfromDate.toLocaleDateString('en-GB'), fromTime: itemfromDate.toLocaleTimeString('en-GB'), toDate: itemtoLocaleDate, toTime: itemtoLocaleTime});
                            // console.log("MarkerDetails:");
                            // console.log(markerDetails);
  }                          // return markerDetails;

}




const indexOfDups = (array, searchItem, startIndex) =>{
  // console.log("getting index of:");
  // console.log("array:",array, "search:",searchItem, "startIndex:",startIndex);
            var i = array.indexOf(searchItem,startIndex),
                indexes = [];
            while (i !== -1) {
              indexes.push(i);
              i = array.indexOf(searchItem, ++i);
            }
            // if (!Array.isArray(indexes) || !indexes.length) {
            //   return undefined
              if (Array.isArray(indexes) && indexes.length) {
                     // console.log('%c'+indexes+' ', 'background: gray; color: red');
          
            }
            // } else {
            // console.log("found:");
            // console.log(indexes);          
                         return indexes;              
            // }

}

const removeNull = (array) => {
return array.filter(x => x !== null)
};

 // const updatePoint = (point,map) => {


 //    //item.point item.name item.type
 //    // if (!point) {return console.log("something went wrong")}
 //      // console.log(point);
 //    // const map = useMapEvent(); //this.leafletMap.leafletElement;
 //    var markerIcon = L.IconMaterial.icon({
 //    icon: point.type,            // Name of Material icon
 //    iconColor: '#FFFFFF',              // Material icon color (could be rgba, hex, html name...)
 //    markerColor: '#055FBF',  // Marker fill color
 //    outlineColor: 'blue',            // Marker outline color
 //    outlineWidth: 1,                   // Marker outline width 
 //    popupAnchor: [0, -40], 

 //    })

 //    // console.log(map);
 //    // console.log(point);

 //    const markerpoint = Object.assign(point , {marker: L.marker(point.point, {icon: markerIcon})
 //              .bindPopup(point.name)
 //              .addTo(map)});
 //             // markers[point.point]=L.marker(point ,{id: 'home',draggable: false,riseOnHover:true, icon:markerIcon, position:point.point}).addTo(map)
 //            // .bindPopup("point.name").openPopup();


 //    // return markers[point.point]

 //   return markerpoint 
 //  }




// const diseaseRender = (point,color,popup,map,layercontrol) => {
//   // console.log("circle:"); console.log(color);
//   //const map = useMap();//this.leafletMap.leafletElement;

//   let count=color.length;
//   // console.log(count);

//         color.forEach((c,index) =>{
//           let perc=(count/(index+1))*100;
//           var circle = L.circle(point, {
//               color: c,
//               fillColor: c,
//               fillOpacity: 0.7,
//               radius: 0.2*perc
//           // }).bindPopup(popup).addTo(map);
//           }).bindPopup(popup).addTo(map);
//           circle.bringToFront();
//           // }
//         })
// }

          // return onAddMarker({point: point, name: description , type: "pin_drop", start: "",end: ""},p.map,p.setMarkers,p.getMarkers);  
// const onAddMarker = (point,map,getMarkers,setMarkers,layercontrol) => {
//       //update marker list
//       // console.log(point);
//       const markerpoint = [updatePoint(point,map)];

//       //orignal
//       // this.setState({ markers: this.state.markers.concat(markerpoint) });
//      setMarkers({...getMarkers,markerpoint});


//       // console.log(this.state.markers);
//       // this.setState({ markers: this.state.markers.concat(point) },this.updateMarkers(markersize));
//       //draw new marker
//       // console.log(this.state.markers);
//       // this.updateMarkers(this.state.markers.length);
//       return getMarkers.length; //return marker id number

// //      return this.state.markers.length; //return marker id number
//       // console.log(this.state.markers)
//       // }    
//   }


const RenderMarkers = (getMarkers,setMarkers, map, layercontrol) => {

  //createdAt: "2021-02-09T10:05:50.000Z", id: 1,location: {location_id: 82, coords: "[52.3521667,5.1495927]"},location_id: 82,person_has_disease_id: null,person_has_symptom_id: 85,updatedAt: "2021-02-09T10:05:50.000Z"
  // let ServerMarkers = this.state.ServerMarkers;

  // console.log("ServerMarkers:"); console.log(ServerMarkers);
  // let diseases = this.state.diseases;
  // let symptoms = this.state.symptoms;
  // console.log(symptoms);
  // console.log(diseases);
// if (p.map === null) {console.log("oops something did not load");return <></>}
  let symptom_id;
  let disease_id;
  // let timeslot;
  // let diseaseCounter= {};
  // let symptomCounter= {};
  let sortMarker = {};
  let skipdups= [];

  // console.log(ServerMarkers);

  let LocationList = Object.keys(ServerMarkers).map((key)  => { return ServerMarkers[key].location.coords });

     let mapMarkers = Object.keys(ServerMarkers).map((key, index)  => {  
     let diseasesList = [];

      let diseasesCount = {};
      
      let symptomsList = [];
      let symptomsCount = {};
        // console.log('%c **************************** MARKERS **********************','  color: yellow')

      var item = ServerMarkers[key];
      // console.log(item);
      // console.log('%c item ', 'background: blue; color: yellow');console.log(item);
      if (item && item !== undefined) {
        // console.log("new symptom start");
        let isSymptom =false;
        let isDisease = false;
        let markerDetails=[];
        let markerCoords=JSON.parse(item.location.coords);
        if (!sortMarker[markerCoords]) {
              sortMarker[markerCoords]={};
              sortMarker[markerCoords].diseases=[];
              sortMarker[markerCoords].symptoms=[];
              sortMarker[markerCoords].total=0;
        }
        // sortMarker[markerCoords].


        sortMarker[markerCoords].total++;

        let symptomName;
        let diseaseName;

        if (item.person_has_symptom_id) {
          // console.log("symptom:");
          // console.warn(item);
          isSymptom = true;
          isDisease = false;


        symptom_id=item.symptom.symptom_id -1;
        // console.log(symptom_id);
        symptomName = symptoms[symptom_id].name;
        // console.log(item.symptom.symptom_id); console.log(symptoms[symptom_id].name);

        //count
        if (sortMarker[markerCoords].symptoms[symptomName] && sortMarker[markerCoords].symptoms[symptomName].count) {
              sortMarker[markerCoords].symptoms[symptomName] = {count: sortMarker[markerCoords].symptoms[symptomName].count+1}                        
              } else {
                sortMarker[markerCoords].symptoms[symptomName] = {count: 1,id:symptom_id}
              }
              // console.log("point:", JSON.parse(item.location.coords), "name:", symptoms[symptom_id].name, "type:", "not_listed_location start: ,end: , class: click");
              } else {
                // console.log(diseases);
              // if (item.person_has_disease_id) {
                console.log("disease:");
                // console.warn(item);
                isDisease = true;
                isSymptom = false;
              // }

            //disease
            disease_id=item.disease.disease_id -1;
            // console.log(disease_id);
            diseaseName = diseases[disease_id].name;
            // console.log(diseaseName);
            // console.log("disease_id:"+disease_id);console.log("diseaseName:"+diseaseName);
            
            if (sortMarker[markerCoords].diseases[diseaseName] && sortMarker[markerCoords].diseases[diseaseName].count) {
            sortMarker[markerCoords].diseases[diseaseName] = {count: sortMarker[markerCoords].diseases[diseaseName].count+1}                        
            } else {
              sortMarker[markerCoords].diseases[diseaseName] = {count: 1,id:disease_id}
            }
            // softMarker[markerCoords].diseases.push({name: symptoms[symptom_id].name, count: softMarker[markerCoords].diseases.})
// 
            // console.log("item- disease_id: "+item.disease.disease_id);console.log("item- diseases.color: "+diseases[disease_id].color);console.log("sortMarker:");console.log(sortMarker);console.log("point:", JSON.parse(item.location.coords), "name:", diseases[disease_id].name, "type:", "not_listed_location start: ,end: , class: click");
            } //end count

      


                // remove dups
                if (skipdups && Array.isArray(skipdups) && skipdups.includes(index)) {
                  // console.log('%c SKIPPING DUP ', 'background: red; color: '); 

                  return null
                }
                  // console.log("skipping duplicate already added");
                  // console.log(item);
                  // if (item.symptom) {console.log(symptoms[item.symptom.symptom_id -1].name);} else {
                  //   console.log(diseases[item.disease.disease_id -1].name);
                  // }


              // ** duplicates
              //check dups locations for merger
              let nextItem=index+1;
              // console.log("checking dups for:"+ index)
              let dup = indexOfDups(LocationList,item.location.coords,nextItem);
              let tmpdup = [...dup];
              // console.log(tmpdup);
              // console.log(indexOfDups(LocationList,item.location.coords,nextItem));


              if (dup.length) { //Array.isArray(dup) && 
                // console.log("dup found"); console.log("dup:"+dup); console.log("index:"+index);
                // console.log()
                // tmpdup.shift();
                // console.log("found dups");
                //dup.slice(1);
                //dup.shift(); 
                // console.log(tmpdup);
                if (tmpdup) {
                  // skipdups=skipdups.concat(tmpdup);
                  // console.log(tmpdup);
                  // skipdups.push(tmpdup);
                  // skipdups.push(dup);
                  skipdups=skipdups.concat(dup);
                  // console.warn(skipdups);

                }
                // this.pPrint(skipdups,"skipdups","white","green");
                



                if (dup && skipdups && Array.isArray(skipdups)  && !skipdups.includes(index) ){ 
                  // console.log('%c ADDING DUPS ', ' color: RED');
                
//push dups
                //DUPLICATES Disease
                 // this.pPrint(dup,"dup","red","white");
                 // console.log(dup.length);
              // if (Array.isArray(dup) && dup.length) {
              
                // console.log("duplicates added to single item"); console.log(dup);
                let dupItems = [];
                // console.log(dup);
                // dupItems.push(item);
                // console.log(ServerMarkers[dup[0]]);
                  dup.forEach(function(otherItem) {
                    // this.pPrint(otherItem,"otherItem","yellow","lightgray");
                      // console.log(otherItem);
                      // console.log(ServerMarkers[otherItem]);
                      let Items = ServerMarkers[otherItem];
                      dupItems.push(Items);
                      // console.log(dupItems);
                      // console.log(Items);
                      let dupisSymptom = ServerMarkers[otherItem].person_has_symptom_id ? true : false;
                      // console.log(otherItem);
                       // {...object1, ...object2 }

                       // console.log(dupisSymptom);
                       // console.log(isSymptom);
                       //
                      // if (dupisSymptom && !isSymptom) {return false}
                     // if (!isSymptom) {
                      if (!dupisSymptom) {
                  // console.log(isSymptom);
                  // console.log("isDisease:");
                  // console.log(isDisease);
                  // console.log(Items.disease);
                  // console.log(Items.symptom);
                  // console.log(Items.disease.disease_id);
                  // console.log(diseases[]);
                  // console.log(Items.disease.disease_id -1);
                  console.log(Items.disease);
                  if (!Items.disease) {console.error(Items)}
                  let dupDiseaseName= diseases[Items.disease.disease_id -1].name;
                  if (diseasesList.indexOf(dupDiseaseName) === -1) {
                    
                    diseasesList.push(dupDiseaseName);
                    // console.log('%c ***************************diseasesList: ', ' color: RED'); console.log(diseasesList);
}

                  } else {
                    // console.log(Items.symptom.symptom_id -1);
                      let dupSymptomName= symptoms[Items.symptom.symptom_id -1].name;
                   if (symptomsList.indexOf(dupSymptomName) === -1) {
                      symptomsList.push(dupSymptomName);
                      // console.log("symptomsList:"); console.log(symptomsList);
                      // console.log('%c ***************************symptomsList: ', ' color: RED');
                       // console.log(Items);

              } 

                  }// !isSympom

                      // this.pPrint("HERE","","RED","BLACK");

                  }); //each
                  //push new object
                  // console.log(dupItems);
                  let type = isSymptom ? "symptom" : "disease";

              // 


                    let duplicatedItems =markerPush(dupItems, type,markerDetails); 
                      if (Array.isArray(duplicatedItems)) {
                      // console.log('%c ########################## concat: ', ' color: RED'); console.log(duplicatedItems); console.log(markerDetails);
                        markerDetails = markerDetails.concat(duplicatedItems);
                      } else {
                      markerDetails.push(duplicatedItems);                                
                      }

                }// if dups && skipdups


              } //if dup

                  // this.idToICON(isSymptom,item.person_has_symptom_id);
                  // console.log("creating marker:");console.log(sortMarker);
                  // let description="";
                  // console.log("dup:"+dup); console.log(item);


                  //show
                  if (!isSymptom) {
                    //layout



                   if (diseasesList.indexOf(diseaseName) === -1) {
                    diseasesList.push(diseaseName);
                    // console.log(diseasesList);
                    }

              //    //push dups
              //   //DUPLICATES Disease
              //    // this.pPrint(dup,"dup","red","white");
              //    // console.log(dup.length);
              // // if (Array.isArray(dup) && dup.length) {
              // if (dup.length) {
              //   console.log("duplicates added to single item");
              //   let dupItems = [];
              //   dupItems.push(item);
              //   // console.log(ServerMarkers[dup[0]]);
              //     dup.forEach(function(otherItem) {
              //       // this.pPrint(otherItem,"otherItem","yellow","lightgray");
              //         console.log(otherItem);
              //         console.log(ServerMarkers[otherItem]);
              //         let Items = ServerMarkers[otherItem];
              //         dupItems.push(Items);
              //          // {...object1, ...object2 }
              //         // this.pPrint("HERE","","RED","BLACK");


              //     });
              //     //push new object
              //     console.log(dupItems);
              //         markerDetails.push(this.markerPush(dupItems, "disease",markerDetails));


              // } else {
                    //single marker push
                    markerDetails.push(markerPush(item, "disease",markerDetails));
                    //plaintxt
                    // description= diseases[disease_id].name+" - "+ sortMarker[markerCoords].diseases[diseaseName].count+"<br>"+"test";

              // }

                    return {list: diseasesList, [JSON.parse(item.location.coords)]: markerDetails};
                    // return {sym:[JSON.parse(item.location.coords),diseases[disease_id].color,description]}
                  } else {

              //add to list (without dups)

              // diseasesList.push(diseaseName);

                 if (symptomsList.indexOf(symptomName) === -1) {
                  symptomsList.push(symptomName);
                  // console.log("symptomsList:"); console.log(symptomsList);
              }
            
              //push dups
              //DUPLICATES Symptoms
                // console.log("dup:"+dup); console.log(item);
              // if (dup.length) {
              //   this.pPrint(dup,"dup","red","blue");
              //   let dupItems = [];
              //   dupItems.push(item);
              //   // console.log(ServerMarkers[dup[0]]);
              //     dup.forEach((otherItem) =>{
              //         console.log(otherItem);
              //         console.log(ServerMarkers[otherItem]);
              //         let Items = ServerMarkers[otherItem];
              //         dupItems.push(Items);

              //     });
              //     console.log(dupItems);
              //     markerDetails.push(this.markerPush(dupItems, "symptom",markerDetails));

              // } else {


              // console.log("Checkpoint");
              // console.log(markerDetails);
              markerDetails.push(markerPush(item, "symptom",markerDetails));
              
              // console.log(markerDetails);


              // }

        return {list: symptomsList, [JSON.parse(item.location.coords)]: markerDetails}
              } //Disease
              // }//if dup
             } else {return null}
          }); //map
// console.log(mapMarkers);
// console.log(LocationList); console.log("mapMarkers items:");
mapMarkers = removeNull(mapMarkers);
    // console.log('%c **************************** mapMarkers **********************','  color: red')

// console.log(mapMarkers);


// console.log(diseases);
var diseaseMarkers = [],
    symptomMarkers = [];

    var allMarkers = {disease:[],symptom:[]};
        // diseasesMarkers = [],
        // symptomsMarkers = [];


// Object.keys(diseases).map((key, index)  => {
//   console.log(diseases[key].name);
//   let name = diseases[key].name
//   allMarkers.disease.push([name]);
// });




  var markerIcon  = (type) => L.IconMaterial.icon({
    icon: type,            // Name of Material icon
    iconColor: '#FFFFFF',              // Material icon color (could be rgba, hex, html name...)
    markerColor: '#055FBF',  // Marker fill color
    outlineColor: 'blue',            // Marker outline color
    outlineWidth: 1,                   // Marker outline width 
    popupAnchor: [0, -40], 
    })

console.log(allMarkers);

 Object.keys(mapMarkers).forEach((key, index)  => {
        var locitem = mapMarkers[key];
        // console.log(locitem);
        let markerSymDis=locitem.list;
        // console.log(markerSymDis);
        let loc=Object.keys(locitem)[1];
        let point=Object.keys(locitem)[1].split(",");

        // console.log(loc); console.log(point);
        // let () = loc.split(",");
        // console.log(lon);
        let multiItem = locitem[loc];
        // console.log(loc);
        // console.log('%c multiItem ', 'background: #222; color: #bada55'); console.log(multiItem);
        let rowtype;
        let description="";
        let desheader='<table class="tablePopup"><thead><tr class="';
        let desallradio='<input type="radio" name="tri" id="all" checked=/><ul>';
        let desalllabel ='<li><label for="all">All</label></li>';
        let descustomlabel="";
          markerSymDis.forEach((item,index) => {
            // console.log(item); console.log(index);
            description+='<input type="radio" name="tri" id="dis'+index+'"/>'
            descustomlabel +='<li><label for="dis'+index+'">'+item+'</label></li>'
          desheader += 'dis'+index+' ';
          });
          // desheader += 'dis'+index+' '
          // desheader += '';
          // console.log(desheader);
          // description =+ '';
            description += desallradio+descustomlabel+ desalllabel+'</ul>'+desheader+'">';
            //header
          description += '<th id="Type">Type</th><th id="Amount">Amount</th><th id="Date">Date</th><th id="Time">Time</th></tr></thead>';

        let descriptionDisease=[];
        // let descriptionColor="linear-gradient(0deg,";
        // multiItem.forEach(function(item) {
          let isDisease=false;
          // console.log(multiItem);
            if (multiItem) {
              // console.log(multiItem);

                  multiItem.forEach((item,index) =>{
                              //let perc=(count/(index+1))*100;
                              let perc = 100;
                              // console.log(item);
                              //set disease flag if detected
                              let iId=item.id;
                              let descriptionName = item.symptom ? symptoms[iId].name :diseases[iId].name;
                              let descriptionCount = item.symptom ? sortMarker[loc].symptoms[descriptionName].count : sortMarker[loc].diseases[descriptionName].count;
                              

                              let itemIndex = markerSymDis.indexOf(descriptionName);
                              console.log(itemIndex);
                            description += ' <tr class="'+ rowtype+' dis'+ itemIndex+ '">'+
                                              '<td>'+descriptionName+'</td>'+
                                              '<td>'+descriptionCount+'</td>'+
                                              '<td>'+item.fromDate+"<br>"+item.toDate+'</td>'+
                                              '<td>'+item.fromTime+"<br>"+item.toTime+'</td>'+
                                            '</tr>';


                                // console.log('%c iId isDisease', 'background: #222; color: #bada55'); console.log('%c '+ isDisease + ', background: #222; color: #bada55'); console.log(iId);
                                //disease - symptomp
                                if (!item.symptom) {
                                isDisease=true;
                                if (!allMarkers.disease[descriptionName]) {allMarkers.disease[descriptionName] = []}

                                  // console.warn(diseases[iId].color);
                                allMarkers.disease[descriptionName].push(L.circle(point, {color: diseases[iId].color,fillColor: diseases[iId].color,fillOpacity: 0.7,radius: 0.2*perc}).bindPopup(description))
                                // allMarkers.disease[descriptionName].push(L.marker(point, {icon: markerIcon("pin_drop")}).bindPopup(description))
                                // console.log("disease:");
                                // console.log(descriptionName);
                                // console.log(allMarkers.disease[descriptionName]);

                                // console.log("id:"+diseases[iId]); console.log("name:"+diseases[iId].name); console.log("count:"+sortMarker[loc].diseases[diseases[iId].name].count);
                                descriptionDisease.push(diseases[iId].color); 
                                } else {
                                if (!allMarkers.symptom[descriptionName]) {allMarkers.symptom[descriptionName] = []}
                                allMarkers.symptom[descriptionName].push(L.marker(point, {icon: markerIcon("pin_drop")}).bindPopup(description))
                                // console.log("symptom:");
                                // console.log(descriptionName);
                                // console.log(allMarkers.symptom[descriptionName]);

                                }
                              rowtype = rowtype === 'even' ? 'odd' : 'even';
                              // console.log(symptoms[iId]);
                              
                              // let duplItem = ServerMarkers[otherItem];

                            })
                }


                  description+='</table>';
        if (!isDisease) {
          // console.log("symptom:");
        // console.log(item)
        // console.log(item.id);
       // this.createClickMarker({point: loc, name: item.createdAt, type: "not_listed_location", start: "",end: "", class: "click"});
       // console.log(loc);
       // console.log(map);
          // diseaseMarkers[locitem].push({point: point, name: description , type: "pin_drop", start: "",end: ""});

// diseaseS.push(L.marker(coords, {key:"disMark_"+index, icon:returnIcon('coronavirus',"red")})
//                         .setOpacity(dateperc)
//                         .bindPopup("<div>entered: "+ itemstart+"<br />left: "+itemend+"<br />"+type+": "+desname +"</div>")
//                         );

          // const markerpoint = [updatePoint(point,map)];
          // setMarkers({...getMarkers,markerpoint});
          // return getMarkers.length; //return marker id number



    // const markerpoint = Object.assign(point , {marker: 
      // L.marker(point, {icon: markerIcon("pin_drop")}).bindPopup(description)
              // .addTo(map)
              //}
              //);


          symptomMarkers.push(L.marker(point, {icon: markerIcon("pin_drop")}).bindPopup(description));
          //return onAddMarker({point: point, name: description , type: "pin_drop", start: "",end: ""},map,getMarkers,setMarkers,layercontrol);  
     }
        else {
          isDisease=false;
          // console.log("diseases:");console.log(item);console.log(item.id);
        // console.log(loc);
        // descriptionDisease.forEach((c,index) =>{
        //   console.log(c);
        //   descriptionColor += c+ " 10%";
        //   if (index !== descriptionDisease.length -1) {descriptionColor += ", "}

        // })
        //closing tag
        // descriptionColor += ");";
        // console.log(descriptionColor);
      // this.diseaseRender(point,descriptionColor,description);

  // let count=color.length;
  // // console.log(count);

  //       color.forEach((c,index) =>{
  //         let perc=(count/(index+1))*100;
  //         var circle = L.circle(point, {
  //             color: c,
  //             fillColor: c,
  //             fillOpacity: 0.7,
  //             radius: 0.2*perc
  //         // }).bindPopup(popup).addTo(map);
  //         }).bindPopup(popup).addTo(map);
  //         circle.bringToFront();
  //         // }
  //       })

// const diseaseRender = (point,color,popup,map,layercontrol) => {
      
      let count=descriptionDisease.length;
      // console.log(count);

  descriptionDisease.forEach((c,index) =>{
          let perc=(count/(index+1))*100;

          // var circle = L.circle(point, {
          //     color: c,
          //     fillColor: c,
          //     fillOpacity: 0.7,
          //     radius: 0.2*perc
          // // }).bindPopup(popup).addTo(map);
          // }).bindPopup(description).addTo(map);
          // circle.bringToFront();
          // allMarkers.disease
      diseaseMarkers.push(L.circle(point, {color: c,fillColor: c,fillOpacity: 0.7,radius: 0.2*perc}).bindPopup(description))

          // }
        })



      // return diseaseRender(point,descriptionDisease,description,map,layercontrol);
      // console.log(item[loc].symptom);
    }
    //last item processed
    // alert(Object.keys(mapMarkers).length);
    if (index +1 === Object.keys(mapMarkers).length) {
      console.log("last item");
      addLayers(allMarkers,map,layercontrol)
    }

 });


//check
console.log(diseaseMarkers);
// console.warn(allMarkers);

//add to layer

// allMarkers.disease.forEach((item) =>{
          // console.warn(item);
        //   descriptionColor += c+ " 10%";
        //   if (index !== descriptionDisease.length -1) {descriptionColor += ", "}
        // })
// allMarkers.symptom.forEach((item) =>{
          // console.warn(item);
        //   descriptionColor += c+ " 10%";
        //   if (index !== descriptionDisease.length -1) {descriptionColor += ", "}

        // })

// addLayers(allMarkers)


  // var symptomsLayer = L.layerGroup(symptomMarkers).addTo(map),
      // diseasesLayer = L.layerGroup(diseaseMarkers).addTo(map) 


  // //     homeLayer = L.layerGroup(home).addTo(map); 

            // layercontrol.addOverlay(homeLayer,'<span class="layer"><span class="material-icons homeIcon">home</span>Home</span>',true).addTo(map);


            // layercontrol.addOverlay(symptomsLayer,'<span class="material-icons symptomIcon">sick</span>Symptoms',true).addTo(map);
            // layercontrol.addOverlay(diseasesLayer,'<span class="material-icons diseaseIcon">coronavirus</span>Diseases',true).addTo(map);


}


const addLayers = (allMarkers,map,layercontrol) => {
console.warn("add layers");
console.log(allMarkers.disease);


//   parentGroup = L.markerClusterGroup(options), // Could be any other Layer Group type.
//   // This is where the magic happens!
//   mySubGroup = L.featureGroup.subGroup(parentGroup, arrayOfMarkers);

// parentGroup.addTo(map);
// mySubGroup.addTo(map);


          // layercontrol.addGroup('<span class="material-icons diseaseIcon">coronavirus</span>Disease').addTo(map);
 let parentlayer = L.layerGroup();
    // parentlayer.setStyle({className: 'mainDiseases'});
    parentlayer.addTo(map);


// alert(Hey i am calling);

// document.getElementsByClassName(\'itemsgroup\').classList.toggle(\'noshow\')
// ;var el=document.getElementsByClassName(\'itemsgroup\');el.classList.toggle(\'noshow\')
      // document.getElementsByClassName("itemsgroup").classList.toggle("noshow") 
      // for (var i = 0; i < overlay.length; i++) {overlay[i].classList.toggle(...)
//}

// <span class="dropdownbox"><input type="button" onclick="\
  //    for (var i = 0; i < document.getElementsByClassName("itemsgroup").length; i++) document.getElementsByClassName("itemsgroup")[i].classList.toggle("noshow")" \
   //   class="diseasegroup"><span class="arrow down"></span>
// , {class: "mainGroup" ,groupName : "headermaingroup"}
  //header
    layercontrol.addOverlay(parentlayer,'<span class="maingroup"><span class="material-icons diseaseIcon">coronavirus</span>Diseases</span></span>',true).addTo(map);




Object.keys(allMarkers.disease).forEach((key, index)  => {
// allMarkers.disease.forEach((item) =>{
          let name = key;
          let itemmarkers = allMarkers.disease[key];
          // console.info(itemmarkers);

  // var parentGroup = L.markerClusterGroup(), // Could be any other Layer Group type.
  // This is where the magic happens!

          // var mySubGroup = L.featureGroup.subGroup(itemmarkers);
        // parentGroup.addTo(map);
          // console.log(L.layerGroup().getLayers());
          // let parentlayer = L.layerGroup(itemmarkers);
          // parentlayer.addTo(map);
          let mySubGroup = L.featureGroup.subGroup(parentlayer, itemmarkers);
 
          // if (index == 0) {
            // console.log("adding map");
            // console.log(parentlayer.getPosition();
            // parentlayer.appendChild()
             // parentlayer.setZIndex(7);
          // layercontrol.addOverlay(parentlayer,'<span class="material-icons diseaseIcon">coronavirus</span>Disease',true).addTo(map);            
          // } 
          
          // layercontrol.addOverlay(layer,'<span class="material-icons diseaseIcon">coronavirus</span>'+name,true).addTo(map);
          mySubGroup.addTo(map);
//todo
  // console.log(returnColor(name));
          layercontrol.addOverlay(mySubGroup, '<span class="itemsgroupDiseases" style="color: '+returnColor(name)+'" ><span class="material-icons">coronavirus</span>'+name+'</span>',true).addTo(map);
          //mySubGroup.addTo(map);

        //   descriptionColor += c+ " 10%";
        //   if (index !== descriptionDisease.length -1) {descriptionColor += ", "}
        })

 let symptomlayer = L.layerGroup();
    symptomlayer.addTo(map);
    //header
    layercontrol.addOverlay(symptomlayer,'<span class="maingroup"><span class="material-icons symptomIcon">sick</span>Symptoms</span>',true).addTo(map);
          // layercontrol.addGroup('<span class="material-icons symptomIcon">sick</span>Symptoms').addTo(map);
Object.keys(allMarkers.symptom).forEach((key, index)  => {
          let name = key;
          let itemmarkers = allMarkers.symptom[key];
          // console.info(itemmarkers);
          
          // let layer = L.layerGroup(itemmarkers).addTo(map);

           let mySubGroup = L.featureGroup.subGroup(symptomlayer, itemmarkers);
           mySubGroup.addTo(map);
          layercontrol.addOverlay(mySubGroup,'<span class="itemsgroupSymptoms"><span class="material-icons symptomIcon">sick</span>'+name+'</span>',true).addTo(map);
        //   descriptionColor += c+ " 10%";
        //   if (index !== descriptionDisease.length -1) {descriptionColor += ", "}

        })



}

const returnColor = (value) => {
    // console.log(value);
    let itemcolor;
      diseases.forEach((item,index)  => {
        if (item.name === value) {itemcolor=item.color} 
      });
    return itemcolor;
}

const renderCoding = () => {
    // let ServerMarkers = this.state.ServerMarkers;
    // let diseases = diseases;
    // let symptoms = this.state.symptoms;
    // let coding='Color coding:';
          //coding+= <div style={{color: [item.color]}}> {item.name}</div>;
// var renderedOutput = arr.map(item => <div> {item} </div>)
 

    // let dis= Object.keys(diseases).map((key, index)  => {
      // let sym= Object.keys(symptoms).map((key, index)  => {
//       var item = symptoms[key];
  // let sym= symptoms.map((item, index)  => <div key={index}>{item.name} </div>);
      if (diseases.length >0) {
      let dis= diseases.map((item,index)  => <div className='legendSelect' key={index} style={{color: [item.color]}}>{item.name} </div>);
        return <div className="legendColor"><div className="legendHeader">Color coding: </div>{dis}</div>;
      }
      else {
       return <div className="legendColor"><div className="legendHeader">Color coding: </div></div>; 
      }
// 
// console.log(coding);
// coding++dis
}


function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

//   function DisplayPosition({ map }) {
//     const [position, setPosition] = useState(map.getCenter())

//   const onClick = useCallback(() => {
//     map.setView(center, zoom)
//   }, [map])

//   const onMove = useCallback(() => {
//     setPosition(map.getCenter())
//   }, [map])

//   const onZoom = useCallback(() => {
//     // setPosition(map.getCenter())
//     console.log("disable layer for animation");
//     map.removeLayer(diseases)
//   }, [map])

//   React.useEffect(() => {
//     map.on('zoomstart', onZoom)
//     return () => {
//       map.off('zoomstart', onZoom)
//     }
//   }, [map, onZoom])

//   React.useEffect(() => {
//     map.on('move', onMove)
//     return () => {
//       map.off('move', onMove)
//     }
//   }, [map, onMove])

//   return (
//     <p>
//       latitude: {position.lat.toFixed(4)}, longitude: {position.lng.toFixed(4)}{' '}
//       <button onClick={onClick}>reset</button>
//     </p>
//   )
// }


function DisplayCard(use) {
  // if (!currentUser) {return <Navigate to="/login" />}
  return (
    <div>
        <CardNumber api={use}/>
          </div>
        
    ) 
}


function CardNumber(c) {

// const [position, setPosition] = useState(c.api.map.getCenter())
// const [searchInfo, setsearchInfo] = useState();
// const [searchValue, setsearchValue] = useState();
// const [SearchDisabled, setsearchDisabled] = useState(false);
// const [searchWorking, setsearchWorking] = useState(false);
// const [startDate, setStartDate] = useState(new Date());
// const [endDate, setEndDate] = useState(new Date());
// const nextTab = c.setTab(c.getTab+1);

//disease
// const [diseaseSelect, setdiseaseSelect] = useState()
// const [diseaseDate, setdiseaseDate] = useState()
// const [diseaseStartDate, setdiseaseStartDate] = useState()
// const [customDate,setcustomDate] = useState(false)

//symptom
// const [symptomSelect, setsymptomSelect] = useState()
// const [symptomStartDate, setsymptomStartDate] = useState()
// const [symptomEndDate, setsymptomEndDate] = useState()

// const [newData, setnewData] = useState({diseases:{},symptoms:{}});
// const [synced, setsynced] = useState({});

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);

// const [iconSelect, seticonSelect] = useState();
// const [weekday,setweekday] = useState({su:false,mo:false,th:false,we:false,tu:false,fr:false,sa:false});
let number = c.api.getTab;
const [isOpen, setIsOpen] = React.useState(true);
// if (c.api.newHome) {
// number = 0
// }
// console.log("card Number")
// console.log(number);

if (number === 0 && currentUser && currentUser.person.home) {number = 1}


 switch (number) {

 //no home
          case 0:  if(!currentUser){
             return <div className="updatepoi cardpopup">
  
        <Card body >
          <CardTitle tag="h5">Overview</CardTitle>
            <CardText>register/login to update points visited to get notifications!<br />These are the markers reported in the last two weeks<br />
          Click on a marker to get more infomation
          </CardText>
          {renderCoding()}

        </Card>
        </div>;

          } else {

          return <div><div className="inputpage cardpopup">
        <Card body >
                  <Button color="success" outline onClick={() => {console.log(isOpen);setIsOpen(!isOpen)}} style={{width: '35px'}} >{isOpen?"_":"+"}</Button>
            <Collapse isOpen={isOpen} onEntered={function noRefCheck(){}} onEntering={function noRefCheck(){}} onExited={function noRefCheck(){}} onExiting={function noRefCheck(){}} >  

          <CardTitle tag="h5">Home Address </CardTitle>
          <CardText>Enter address and press search or<br />
          drag pin to your home and press confirm</CardText>
        </Collapse>          
        </Card>
        </div> 
        </div>;
  
          }         //poi start
          case 1: 
          return <div className="updatepoi cardpopup">
  
        <Card body >
            <Button color="success" outline onClick={() => {console.log(isOpen);setIsOpen(!isOpen)}} style={{width: '35px'}} >{isOpen?"_":"+"}</Button>
            <Collapse isOpen={isOpen} onEntered={function noRefCheck(){}} onEntering={function noRefCheck(){}} onExited={function noRefCheck(){}} onExiting={function noRefCheck(){}} className="cardMargin">
          <CardTitle tag="h5">Overview</CardTitle>
            <CardText>These are the markers reported in the last two weeks<br />
          Click on a marker to get more infomation.
          </CardText>
          </Collapse>
        </Card>
        </div>;

          default:
          return <div className="updatepoi cardpopup">
        <Card body >
          <CardTitle>OOPS</CardTitle>
            <CardText>Something unexpected happend
          </CardText>
        </Card>
        </div>;
        }
}

function GPSButton() {

 const [position, setPosition] = useState(null);
 // const [zoom, setZoom] = useState(null)

 const map = useMap();

 const onClick = () => {
  // console.log(map.getZoom());
  // map.setPosition(map.locate());
  // console.log(markers);
map.on('locationfound', (e) => {MapService.onLocationFound(e,map,setPosition,markers,null,true)});
  // console.log(map.locate());
// const geoLocation = () {
  console.log(position);
  // body...
  // console.log("CLICK");
 // const [position, setPosition] = useState(null)

   // let t = map.locate();
   map.locate();

  // return position === null ? null : (
  //   <Marker position={position}>
  //     <Popup>You are here</Popup>
  //   </Marker>
  // )

}
  //button
  return <div className="leaflet-bottom leaflet-right"><button className="myPosition leaflet-control leaflet-bar material-icons" onClick={onClick} >gps_fixed</button></div>
}



function App () {
console.warn("Start Home: App");

//update static data
 if (outdated) {
        console.log("updating staticData");
      let response= DataService.updateServerData();
      console.log(response);
    }

 const [modal, setModal] = useState(false);
 const toggle = () => setModal(!modal);
// console.log(synced);
let needNewHome=currentUser? currentUser.person? currentUser.person.home :true : false;
let myHomeSelected=currentUser? currentUser.person? currentUser.person.home :currentUser.person.home : false;

const [newHome, setnewHome] = useState(needNewHome);
const [homeSelected, sethomeSelected] = useState(myHomeSelected);


// const [newHome, setnewHome] = useState(currentUser.person.home? false : true);
// const [homeSelected, sethomeSelected] = useState(currentUser.person.home);
// const ServerMarkers= DataService.getMarkers();
const [getMarkers,setMarkers] = useState(ServerMarkers);
// const [layercontrol,setlayercontrol] =useState(new L.control.layers(null,null, { collapsed: true }).setPosition("topleft"))
const layercontrol =new L.control.layers(null,null, { collapsed: true }).setPosition("topleft");

// console.log(homeSelected);
// let inittab
// inittab= inittab ? inittab: defaulttab;
// console.log("inittab");
// console.log(inittab);
// inittab
let number = 0;
// if (currentUser && !currentUser.person.home) {
// number = 0
// }
const [getTab, setTab] = useState(number);
// console.log(getTab);
// const [myhome, setMyhome] = useState(false)


 const [userDetails, setuserDetails] = useLocalStorage("user", AuthService.getCurrentUser());

//check if user has home else change tab to 0
  // useEffect(() => {
  //   if (currentUser && !currentUser.person.home) {
  //       // setnewHome(true);
  //             console.log("setTab change 0 useEffect <- home");
  //     setTab(0)
  //   } 
  //   // else {sethomeSelected(false)}
  // },[]);
// , setgetTab, currentUser.person.home

//  const [userDetails, setUserDetails] = AuthService.getCurrentUser();
// useEffect(() => {
//   sessionStorage.setItem('user', JSON.stringify(userDetails));
// }, [userDetails]);

// const increment = () => setgetTab(getTab+1);
// useEffect(() => { setgetTab(getTab) }, [])

 const [map, setMap] = useState(null)
    // const [position, setPosition] = useState(null)
// map.on('locationfound', (e) => {onLocationFound(e,map,setPosition)});

   useEffect(() => {
    // code to run on component mount
        // <RenderMarkers getMarkers={getMarkers}
                       // setMarkers={setMarkers}
                       // map={map}
                       // />
       if (map) {

        //sync
    // DataService.syncSynced();
       // console.log(map);
     // map.on('locationfound', (e) => {onLocationFound(e,map,setPosition,false)});
      // map.locate();

       RenderMarkers(getMarkers,setMarkers,map,layercontrol)                        

       if (!L.tileLayer.offline) {
       //add offline map
       const tileLayerOffline = L.tileLayer.offline("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          subdomains: "abc",
          minZoom: 13,
          maxZoom: 16
        }
      );
      tileLayerOffline.addTo(map);
 // layer switcher control
 layercontrol.addOverlay(tileLayerOffline,'<span class="maingroup"><span class="material-icons Offline"><span class="material-icons">offline_bolt</span></span>Offline</span></span>',true).addTo(map);
}
      // const layerswitcher = L.control
      // .layers({
      //   '<span class="itemsgroupOfflineRadio">offline</span>': tileLayerOffline,
      //     }, null, { position: 'topleft',collapsed: true })
      // .addTo(map);
      //end offline

       }
  
  }, [map,getMarkers,layercontrol])



          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
 
  const displayMap = useMemo(
    () => (
      <MapContainer
        className="map"
        id="mymap"
        center={center}
        zoom={zoom}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={setMap}>
        <TileLayer
          url= 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
        maxZoom= '19'
        subdomains= "abc"
        attribution= '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        />
         <LayersControl.Overlay name="Symptoms" id="symtoms"></LayersControl.Overlay>
        <LayersControl.Overlay name="Diseases" id="diseases"></LayersControl.Overlay>
        <ZoomControl position="bottomleft" />
        <GPSButton  />
      </MapContainer>
    ),
    [],
  )
// console.log(displayMap);
  return (
    <div>

      {displayMap}
      {<DisplayCard
        map            ={map}
        getTab         ={getTab}
        setTab         ={setTab}
        userDetails    ={userDetails}
        setuserDetails ={setuserDetails}
        newHome        ={newHome}
        setnewHome     ={setnewHome}
        homeSelected   ={homeSelected}
        sethomeSelected={sethomeSelected}
        />}
    </div>
  )

}


      // {map ? <DisplayPosition map={map} /> : null}
export default App;

// export default class Home extends Component <{}, State> {
//   constructor(props) {
//     super(props);
//     saveStateHome = saveStateHome.bind(this);
//     // const [staticData,outdated] = DataService.getStaticData();
//     // const syncedStorage= DataService.getSynced();
//     // console.log(syncedStorage);

//     // update if outdated
//       if (outdated) {
//         console.log("updating staticData");
//       let response= DataService.updateServerData();
//       console.log(response);
//     }

// //     this.state = {
// //       // currentUser: AuthService.getCurrentUser(),
// //       // center: {
// //         // lat: 51.505,
// //         // lng: -0.09,
// //       // },
// //       // marker: {
// //         // lat: 51.505,
// //         // lng: -0.09,
// //       // },
// //       // location: {
// //       //   lat: 51.505,
// //       //   lng: -0.09
// //       // },
// //       // mapClick: {},
// //       // markerPopup:"",
// //       //debug info error warning 
// //       // loglevel: "debug",
// //       // markers: [],
// //       // diseases: staticData.diseases,
// //       // symptoms: staticData.symptoms,
// //       // diseases: {},
// //       // symptoms: {},
// //       // mySymptoms: [],
// //       // myDiseases: [],
// //       // mapClickPosition: {},
// //       // userLocation: false, // selected location
// //       // userNewSymptoms: [], // new user symptoms
// //       // currentTab:0, //start tab
// //       // locatioTime: false, //time for selected location
// // //      SearchDisabled: true, // disable search button
// //       // zoom: 2,
// //       // home: "",
// //       // ServerMarkers: DataService.getMarkers(),
// //       // outdated: outdated,
// //       // outdated: false,
// //       // searchValue: "", // input value
// //       // searchResult: "", // result that will be submitted
// //       // searchInfo: "", //paceholder
// //       // searchChoices: [], //search list
// //       // searchWorking: true, // search lookup
// //       // content: "",
// //       // draggable: true,
// //       // diseaseDate: false,
// //       // diseaseAdded: [],
// //       // diseaseStartDate:  new Date(),
// //       // synced: syncedStorage,
// //       // newData: {
// //       //   point: [],
// //         // symptoms: [],
// //       //   diseases:[]
// //       // },
// //       // validHome:false,
// //       // fetchUrl: {
// //       //   home: "",
// //       //   markers: [],
// //       //   isLoading: false,
// //       //   error: null,
// //       // }
// //     };
//   }

// //setState that should be stored locally
//   // componentDidUpdate(prevProps, prevState) {
//     // Typical usage (don't forget to compare props):
//     // if (this.state.markers !== prevState.markers) {
//     //   this.markerUpdate(this.state.markers);
//     // }
//     // if (prevState.ServerMarkers !== this.state.ServerMarkers) {
//     //   //rerender markers
//     //   console.log("rerender markers");
//     //   this.renderMarkers();
//     // }

//     // if (prevState.outdated !== this.state.outdated) {
//     //   console.log("outdated Changed");
//     //     if (this.state.outdated) {
//     //     console.log("updating staticData");
//     //   let response= DataService.updateServerData();
//     // }
//     // }

//     // if (prevState.diseases !== this.state.diseases || prevState.symptoms !== this.state.symptoms || prevState.ServerMarkers !== this.state.ServerMarkers) {
//       // console.log("symdis marker - changed");
//       // console.log(this.state.symptoms);
//       // console.log(this.state.diseases);
//       // if (this.state.diseases.length > 0 && this.state.symptoms.length > 0  && this.state.ServerMarkers.length > 0) {
//         // console.log("rerender markers all available");
//         // this.renderMarkers();
//       // }

//     // }

//     // if (prevState.currentUser && prevState.currentUser.length !== this.state.currentUser.length) {
//       // const currentUser = JSON.stringify(this.state.currentUser);
//       // localStorage.setItem("user", currentUser);
//     // }

//     // localStorage.setItem('staticContent', JSON.stringify(response.data));
//     // his.setState({ diseases: response.data[0],symptoms: response.data[1],isLoading: false});
//     // if (prevState.diseases !== this.state.diseases || prevState.symptoms !== this.state.symptoms ) {
//       // const currentUser = JSON.stringify(this.state.currentUser);
//       // localStorage.setItem("staticContent", JSON.stringify({diseases: this.state.diseases, symptoms: this.state.symptoms}));
//     // }
//     // if (this.state.synced !== prevState.synced) {
//       // console.log("state synced!!")
//       // if (prevState.synced) {
//         // if (prevState.synced.length !== this.state.synced.length) {
//           // console.log("synced Modified");
//           // const synced = JSON.stringify(this.state.synced);
//           // localStorage.setItem("synced", synced);
//         // }// length
//       // } //prevstate exists
//       // else {
//         // console.log("new synced");
//          // const synced = JSON.stringify(this.state.synced);
//           // localStorage.setItem("synced", synced);
//       // }
//     // }//state modified
//     // } && prevState.synced.length !== this.state.synced.length) {
//       // }

//   // componentDidMount() {
//   //   // console.log(this.state);
//   //   UserService.getPublicContent().then(
//   //     response => {
//   //       this.setState({
//   //         content: response.data
//   //       });
//   //     },
//   //     error => {
//   //       this.setState({
//   //         content:
//   //           (error.response && error.response.data) ||
//   //           error.message ||
//   //           error.toString()
//   //       });
//   //     }
//   //   );
      



//   // } //did mount
// logcheck = (n) => {

//         //debug info error warning 
// let level=this.state.loglevel;
// if (n && level) {
//               if (Constants.logginglevels.indexOf(level) >= n) {return true}
//             }
// else return false
// }


//   isOnline = () => {

//        var condition = navigator.onLine ? 'online' : 'offline';
//     if (condition === 'online') {
//       console.log('ONLINE');
//         fetch(Constants.API_URL, { // Check for internet connectivity
//             mode: 'no-cors',
//             })
//         .then(() => {
//             console.log('CONNECTED TO INTERNET');
//             return true;
//         }).catch(() => {
//            console.log('INTERNET CONNECTIVITY ISSUE');
//            return false;
//         }  )

//     }else{
//        console.log('OFFLINE')
//        return false;
//     }


//   }




//   render() {

//     if (!currentUser) {return <Navigate to="/login" />;} else {
//       // console.log(this.state.currentUser);
//     // console.count('mypoi')
//     // console.log(this.state.getTab);
//     // console.log()

//     // const position = [this.state.center.lat, this.state.center.lng]
//     return (
//       <div>
//       {<App />}
              



//       </div>
//     );  
//     }
    
//   }
    
  
// }
